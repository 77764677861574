<template>
  <div class="transfer-pop">
    <div class="pop-header">
      <span class="pop-title">提交转账凭证</span>
      <lt-icon type="close" size="18" class="close-icon" @click="$emit('close')"></lt-icon>
    </div>
    <p class="title">转账后提交凭证
      <lt-icon type="ios-arrow-forward" class="next-icon"></lt-icon>
      5个工作日进行审核
      <lt-icon type="ios-arrow-forward" class="next-icon"></lt-icon>
      审核通过
      <lt-icon type="ios-arrow-forward" class="next-icon"></lt-icon>
      系统自动开通会员
    </p>
    <div class="container">
      <div class="form-group">
        <label>转账金额</label>
        <span><b>{{setTransferAmount}}</b>元</span>
      </div>
      <div class="form-group">
        <label>收款公司名</label>
        <span>北京艺源酷科技有限公司</span>
      </div>
      <div class="form-group">
        <label>收款账户</label>
        <span>1109 3889 4010 101</span>
      </div>
      <div class="form-group last">
        <label>开户行名称</label>
        <span>招商银行股份有限公司北京青年路支行</span>
      </div>

      <div class="form-group file">
        <span class="error-message" :class="{'show-message': isError[0]}">请上传付款凭证</span>
        <div class="form-content">
          <label class="rquired-label">付款凭证</label>
          <div class="image-list">
            <input
              type="file"
              @change="onFileChange"
              ref="input"
              style="display: none"
              accept="image/jpg, image/jpeg, image/png"
            >
            <span class="upload-file" @click="uploadFile" v-if="uploadImgUrl === ''">上传</span>
            <div class="upload-file image" @click="uploadFile" :style="{'background-image': `url(${uploadImgUrl})`}" v-else>
              <span class="image-warp"></span>
            </div>

            <span class="image-warp"></span>
            <span class="example-image">
              <img
                src="./img/public_transfer_example_img.png"
                alt="示例"
                onclick="javascript:window.open(this.src)"
              >
              <span class="example-icon"></span>
            </span>
          </div>
        </div>
      </div>
      <div class="form-group input">
        <span class="error-message" :class="{'show-message': isError[1]}">请输入正确的付款人户名</span>
        <div class="form-content">
          <label>付款人户名</label>
          <input
            type="text"
            placeholder="请输入户名"
            v-model="accountName"
            class="input-item"
            :class="{'error': isError[1]}"
          >
        </div>
      </div>
      <div class="form-group input">
        <span class="error-message" :class="{'show-message': isError[2]}">请输入正确的联系手机</span>
        <div class="form-content">
          <label>联系手机</label>
          <input
            type="text"
            placeholder="请输入联系手机"
            v-model="phoneInput"
            class="input-item"
            :class="{'error': isError[2]}"
          >
        </div>
      </div>
      <div class="form-group textarea">
        <label>备注说明</label>
        <textarea class="textarea" placeholder="请输入备注信息" v-model="remarksInput" maxlength="100"></textarea>
      </div>
    </div>
    <div class="submit">
      <button class="submit-button" @click="verifyInputInfo">提交</button>
    </div>
  </div>
</template>

<script>
  // import { mapGetters, mapActions } from 'vuex'
  export default {
    data() {
      return {
        accountName: '', //  户名
        phoneInput: '',
        remarksInput: '',
        uploadImgUrl: '', //  付款凭证前端显示图片
        uploadfiles: '',
        isError: [false, false, false],
        returnData: {}, //  预上传的返回值
        isLock: false,  //  防止提交按钮重复点击
      }
    },
    props: {
      transferAmount: {
        type: Number
      },
      openType: {
        type: Number,
        default: 0
      },
      tariffPackageId: {
        type: Number
      },
      requestPrefix: {
        type: String,
        default: 'ul://'
      },
      seatInputCount: {
        type: Number,
        default: 1
      },
      level: {
        type: Number | String,
        default: 4
      }
    },
    watch: {
      uploadfiles(v) {
        if (v !== '') {
          this.$set(this.isError, 0, false)
        }
      },
      accountName(v) {
        if (v !== '') {
          this.$set(this.isError, 1, false)
        }
      },
      phoneInput(v) {
        let reg = /^1[0-9]{10}$/
        if (v !== '' && reg.test(this.phoneInput)) {
          this.$set(this.isError, 2, false)
        }
      }
    },
    computed: {
      setTransferAmount() {
        return this.openType === 1 ? (this.transferAmount / 100).toFixed(2) : this.transferAmount.toFixed(2)
      }
    },
    methods: {
      uploadFile() {
        this.$refs.input.click()
      },
      // 选择本地文件
      onFileChange(e) {
        let files = e.target.files || e.dataTransfer.files
        this.uploadfiles = files[0]
        if (!files.length) return
        if (files[0].size > 1048576 * 5) {
          this.$message('最大上传大小为5M')
          event.target.value = ''
          return
        } else {
          this.createImage(files)
          event.target.value = ''
        }
      },

      // 加载图片
      createImage(files) {
        let self = this
        for (let i = 0; i < files.length; i++) {
          let fr = new FileReader(),
            file = files[i]
          fr.readAsDataURL(file)
          fr.onload = function(e) {
            self.uploadImgUrl = e.target.result
          }
        }
      },
      // 对公转账预上传支付凭证
      async preUploadPayEvidence() {
        let res = await this.$http.post('/paycenter/publicTransfer/preUploadPayEvidence', { gw: true, GW:true })
        // 200,成功 -101.用户未登录
        switch (res.body.code) {
          case 200:
            await this.uploadFiles(res.body.data)
            this.returnData = res.body.data
            break
          case -1:
            this.$message('未登录')
            break
        }
      },
      // 上传凭证文件
      async uploadFiles(uploadPms) {
        let fd = new FormData()
        const { paramMap, fileKey, host,method,url} = uploadPms
        // fd.append('OSSAccessKeyId', uploadPms.accessid)
        // fd.append('policy', uploadPms.policy)
        // fd.append('Signature', uploadPms.signature)
        // fd.append('key', uploadPms.fileKey)
        // fd.append('Content-MD5', uploadPms.md5)
        Object.keys(paramMap).forEach((key) => {
          fd.append(key, paramMap[key])
        })
        fd.append('file', this.uploadfiles)
        let transUrl = url.substr(6)
        await this.$http.post(`${this.requestPrefix}${transUrl}`, fd)
      },
      // 验证输入信息
      verifyInputInfo() {
        if (this.isLock) return
        if (this.uploadfiles === '') {
          this.$set(this.isError, 0, true)
          return
        }
        if (this.accountName === '') {
          this.$set(this.isError, 1, true)
          return
        }
        let reg = /^1[0-9]{10}$/
        if (this.phoneInput === '' || !reg.test(this.phoneInput)) {
          this.$set(this.isError, 2, true)
          return
        }
        this.isLock = true
        this.submitInputInfo()
      },
      // 提交
      async submitInputInfo() {
        await this.preUploadPayEvidence()
        let data = {
          account_name: this.accountName,
          phone: this.phoneInput,
          file_key: this.returnData.fileKey,
          amount: this.transferAmount,
          apidomainv2: true
        }
        // 对公转账席位参数
        data.seat_number = this.level === 201 ? this.seatInputCount - 1 : this.seatInputCount-2
        if (this.remarksInput !== '') {
          data.user_remark = this.remarksInput
        }
        if (this.openType === 0) {
          //余额充值
          data.balance_id = this.tariffPackageId
        } else {
          // 会员充值
          data.member_id = this.tariffPackageId
        }
        this.$http.post('/pay/publicTransfer/apply', data).then(res => {
          let code = res.body.code
          // 200：获取成功；-101：未登录，-105：参数错误，-115：手机号格式不正确，-120：未上传凭证图片，-130：添加记录失败
          switch (code) {
            case 200:
              this.$message('提交成功')
              this.$emit('close')
              location.href = '/list/transfer'
              break
            case -115:
              this.isLock = true
              this.$message('手机号格式不正确')
              break
            case -120:
              this.isLock = true
              this.$message('未上传凭证图片')
              break
            default:
              this.isLock = true
              this.$message('提交失败')
              break
          }
        }, res => {
          this.isLock = true
          console.log('网络错误', res);
        })
      }
    }
  }
</script>

<style lang="less" scoped>
@import './publicTransferPop.less';
</style>
