<template>
  <LtModal
    v-model="showRiskLogin"
    :mask-closable="false"
    :closable="false"
    :z-index="100001">
    <div class="login-risk">
      <div class="risk-title"><LtIcon type="remind" color="#FF4D4D" size="18" class="help-icon" /> 风险警告</div>
      <div class="risk-content" v-if="riskLoginLimit === 10055">
        <p>系统检测到当前账号今日已在多地被登录，为保护账号安全，当前账号今日已被锁定，无法继续登录，明日0点后恢复。</p>
        <p>为保护账号安全，防止信息泄露，创客贴帐号仅限单人使用，不允许转借或租赁他人，如继续异常操作将会被系统永久限制登录。</p>
      </div>
      <div class="risk-content" v-else-if="riskLoginLimit === 10054">
        <p>当前账号因多次触发多地登录限制，存在极高泄露风险，为保护账号安全，当前账号已被系统永久限制登录。</p>
        <p>为保护账号安全，防止信息泄露，创客贴帐号仅限单人使用，不允许转借或租赁他人，频繁出现异常操作将会被系统永久封禁。</p>
      </div>
      <div class="risk-content" v-else>
        <p>系统检测到当前账号存在违规异常，为保障创客贴合法权益，当前账号已被永久限制登录。</p>
        <p>为保护账号安全，防止信息泄露，创客贴账号仅限会员个人使用，不允许赠与、转借、转售或转租他人使用等违规行为。如有疑问，<a href="https://support.qq.com/products/55374/faqs/158259">可点此进行申诉></a></p>
      </div>
      <div class="risk-button" @click="close()">关闭</div>
    </div>
  </LtModal>
</template>

<script>
  export default {
    name: 'risklogin',
    props: {
      showRiskLogin: {
        type: Boolean,
        default: true
      },
      riskLoginLimit: {
        type: Number,
      },
    },
    data() {
      return {
      };
    },
    methods: {
      close () {
        this.$emit('closeRisk')
      }
    }
  };
</script>

<style lang="less" scoped>
  .login-risk {
    position: relative;
    width: 448px;
    height: 280px;
    padding: 24px;
    border-radius: 6px;
    background: #FFFFFF;
    .risk-title{
      font-size: 16px;
      font-weight: 600;
      line-height: 16px;
      color: #1B2337;
    }
    .risk-content{
      margin-top: 30px;
      p{
        font-size: 14px;
        line-height: 22px;
        color: #505A71;
        text-indent: 2em;
        margin-bottom: 16px;
      }
    }
    .risk-button{
      position: absolute;
      right: 24px;
      bottom: 24px;
      width: 92px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #0773FC;
      border-radius: 4px;
      font-size: 14px;
      color: #FFFFFF;
      cursor: pointer;
    }
  }
</style>
