/**
 *
 * 时间格式化
 * @param {number} time
 */
export const getTime = (time) => {
  const date = new Date();
  date.setTime(time);
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
};

/**
 *
 * 套餐匹配
 * @param {*} packages
 * @param {*} level
 * @param {*} info
 */
export const matchVip = (packages, level) => {
  if (packages.find((e) => e.level === level) === undefined){
    return []
  }
  let vip = JSON.parse(JSON.stringify(packages.find((e) => e.level === level)));
  let arr = vip.details;
  var combinedArray = arr.reduce(function (accumulator, currentValue) {
    return accumulator.concat(currentValue.packages);
  }, []);
  
  // 将需要排序的 key, 按照 "从小到大" 进行排列
  // let sortKeys = Object.keys(arr).sort((a, b) => {
  //   return arr[a].discountPrice - arr[b].discountPrice
  // })
  // let arr2 = []
  // // 循环排列好的 key, 重新组成一个新的数组
  // for (var sortIndex in sortKeys) {
  //   arr[sortKeys[sortIndex]].level = level
  //   arr2.push(arr[sortKeys[sortIndex]])
  // }
  combinedArray.forEach((item) => {
    Object.assign(item, {level: level})
  })
  return combinedArray;
};
