<template>
  <!-- 企业 -->
  <div class="enterprise-box">
    <!-- 营销内容中台 -->
    <div class="content-page">
      <div class="left">
        <img v-lazy="require('../img/content-left.png')" />
      </div>
      <div class="right">
        <div class="sub-title">营销内容中台</div>
        <div class="small-des">一站式内容营销解决方案</div>
        <div class="symbol-box">
          <div v-for="item in contentInfo.slice(0, 4)" class="symbol-item">
            <span class="symbol"></span>
            <span class="symbol-des">{{ item }}</span>
          </div>
        </div>
        <SmallButton @onButtonClick="handleLocation('/contech')" />
      </div>
    </div>
    <!-- 全品类定制设计 -->
    <div class="custom-page">
      <div class="left">
        <div class="sub-title">全品类定制设计</div>
        <div class="small-des">DesignOps设计协作平台</div>
        <div class="progress"></div>
        <div class="symbol-box">
          <div v-for="item in customInfo.slice(0, 4)" class="symbol-item">
            <span class="symbol"></span>
            <span class="symbol-des">{{ item }}</span>
          </div>
        </div>
        <SmallButton
          @onButtonClick="handleLocation('/design-service?from=ckt')"
        />
      </div>
      <div class="right">
        <img
          v-lazy="require(`../img/custom-${this.currentCustomNav + 1}.png`)"
          width="615"
          height="441"
        />
        <div class="nav-button">
          <div
            class="button-item"
            v-for="(item, index) in customNavList"
            :class="{ active: index === currentCustomNav }"
            @click="currentCustomNav = index"
          >
            {{ item }}
          </div>
        </div>
      </div>
    </div>
    <!-- 印刷服务 -->
    <div class="print-page">
      <div class="left">
        <img v-lazy="require('../img/print.png')" />
      </div>
      <div class="right">
        <div class="sub-title">印刷服务</div>
        <div class="small-des">一站式印刷采购</div>
        <div class="symbol-box">
          <div v-for="item in printInfo.slice(0, 4)" class="symbol-item">
            <span class="symbol"></span>
            <span class="symbol-des">{{ item }}</span>
          </div>
        </div>
        <SmallButton @onButtonClick="handleLocation('/print/printcenter')" />
      </div>
    </div>
    <!-- 开放平台 -->
    <div class="open-platform-page">
      <div class="left">
        <div class="sub-title">开放平台</div>
        <div class="small-des">生态合作，创意互联</div>
        <div class="symbol-box">
          <div v-for="item in apiInfo.slice(0, 4)" class="symbol-item">
            <span class="symbol"></span>
            <span class="symbol-des">{{ item }}</span>
          </div>
        </div>
        <SmallButton @onButtonClick="handleLocation('/terms/introduce')" />
      </div>
      <div class="right">
        <img v-lazy="require('../img/apiInfo.svg')" />
      </div>
    </div>
  </div>
</template>

<script>
  import SmallButton from './SmallButton';
  export default {
    data() {
      return {
        contentInfo: [
          '高效生产，版权无忧',
          '内容素材，云端管理',
          '多维协同，创意加速',
          'AI合规检测，放心商用',
        ],
        customInfo: [
          '交付效率提升10%',
          '成本投入削减50%',
          '100+设计品类',
          '5x12小时响应',
        ],
        printInfo: [
          '24小时极速达',
          '品牌营销物料',
          '企业定制物料',
          '包装组合定制',
        ],
        apiInfo: [
          '极简在线设计工具',
          '丰富的自定义接口',
          '品牌VI一键配置',
          'SDK对接轻松写意',
        ],
        customNavList: ['平面海报', '电商', '印刷品', '手绘', '品牌设计'],
        currentCustomNav: 0,
        timer: null,
      };
    },
    components: {
        SmallButton,
    },
    methods: {
      handleLocation(location) {
        this.$emit('openLocation', location);
      },
    },
  };
</script>
<style lang="less">
  .enterprise-box {
    width: 100%;
    height: auto;
    .content-page {
      width: 100%;
      height: 840px;
      padding: 0 136px;
      background-color: #ffff;
      display: flex;
      justify-content: center;
      align-items: center;
      .left {
        width: 552px;
        height: 611px;
        margin-right: 121px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .right {
        .small-des {
          margin-bottom: 72px;
        }
      }
    }

    .custom-page {
      width: 100%;
      height: 840px;
      padding: 0 136px;
      background: #fafafa;
      background-image: url('../img/dot-img.svg');
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;
      .left {
        .small-des {
          margin-bottom: 72px;
        }
        .progress {
          width: 505px;
          height: 72px;
          margin-bottom: 72px;
          background-image: url('../img/progress.svg');
          background-size: cover;
          background-repeat: no-repeat;
        }
      }
      .right {
        width: 551px;
        height: 611px;
        background-image: url('../img/custom-bg.svg');
        background-size: cover;
        background-repeat: no-repeat;
        margin-left: 32px;
        img {
          margin-top: 66px;
          margin-left: 30px;
        }
        .nav-button {
          width: 490px;
          height: 48px;
          display: flex;

          margin-left: 100px;
          .button-item {
            width: 104px;
            height: 48px;
            text-align: center;
            line-height: 48px;
            color: var(--1, #1b2337);
            font-size: 14px;
            font-weight: 500;
            border-radius: 8px 8px 0px 0px;
            border: 2px solid #fff;
            background: rgba(255, 255, 255, 0.35);
            box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
            cursor: pointer;
            &:not(:first-child) {
              margin-left: 10px;
            }
          }
          .active {
            color: #3461ff;
          }
        }
      }
    }

    .print-page {
      width: 100%;
      height: 840px;
      padding: 0 136px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      .left {
        width: 552px;
        height: 611px;
        img {
          width: 100%;
          height: 100%;
        }
        margin-right: 121px;
      }
      .right {
        .small-des {
          margin-bottom: 72px;
        }
      }
    }

    .open-platform-page {
      width: 100%;
      height: 840px;
      padding: 0 136px;
      background: #fafafa;
      background-image: url('../img/dot-img.svg');
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      align-items: center;
      justify-content: center;
      .left {
        .small-des {
          margin-bottom: 72px;
        }
      }
      .right {
        width: 552px;
        height: 611px;
        margin-left: 121px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
</style>
