<template>
  <div
    class="vip-pop-pay-sider"
  >
    <!-- 用户信息 -->
    <!-- <div class="user-info">
      <img :src="app.userInfo.userHeadImgUrl" class="avatar" />
      <div class="info">
        <span class="user-name">
          {{ decodeURIComponent(app.userInfo.nickname) }}
        </span>
        <span class="user-version" :class="vipVersionClass">
          {{app.userVersion}}
        </span>
      </div>
    </div>
    <div class="vip-package-info">
      <p class="title">{{payActionStatus}}</p>
      <p class="info-data" :class="{
        'company-color':this.app.selectedPackage.level === 202
      }">{{app.afterBuyExpireDate}}</p>
      <p class="title num-title">使用人数：</p>
      <p class="info-data"
      :class="{
        'company-color':this.app.selectedPackage.level === 202
      }">{{app.personNum === 3 && this.app.selectedPackage.level === 202 ? "1~3" : app.personNum }}人</p>
    </div>
    <hr class="horizontal-line paysection" /> -->
    <!-- 会员权益 -->
    <div class="">
      <div class="right-title">
        <p 
          class="version-name"
          :class="{
              'personal-icon' : app.packageType === 0,
              'company-icon' : app.packageType === 1,
              'flagship-icon': app.packageType === 2
            }"
        >{{vipVersionName.versionName}}</p>
        <p class="version-desc" v-html="vipVersionName.desc"></p>
      </div>
      <ul
        class="buy-feature-group"
      >
          <template v-for="(item, index) in featureList">
          <li :key= "index" class="buy-feature-item"
          :class="{
            'company-color': app.packageType === 1,
            'flagship-color': app.packageType === 2,
            'ai-right-list': item === 'AI创作100贴贴/席位/月'|| item === 'AI创作100贴贴/月',
          }">
            <span v-if="typeof item === 'function'" v-html="item()" />
            <span v-else>{{ item }}</span>
          </li>
          </template>
      </ul>
    </div>
    <a class="see-more" :href="vipPageUrl" target="_blank">查看会员权益对比 ></a>
  </div>
</template>

<script>
import {
  COMPANY_RIGHT,
  PERSON_RIGHT,
  FLAGSHIP_RIGHT,
  COMPANY_RIGHT_MORE,
  BIG_COMPANY_RIGHT_MORE,
  BIG_COMPANY_RIGHT
} from "../constant.js";
export default {
  name: "Sider",
  inject: ["app"],
  data(){
    return {
        COMPANY_RIGHT,
        PERSON_RIGHT,
        FLAGSHIP_RIGHT,
        COMPANY_RIGHT_MORE,
        BIG_COMPANY_RIGHT_MORE,
        BIG_COMPANY_RIGHT
    }
  },
  props: {
    isLenovoSite: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    //payAction: 0, // 购买行为：5降级，10普通购买，15续费，20升级
    payActionStatus() {
      switch (this.app.payAction) {
        case 15:
          return '续购后的到期时间:';
        case 20:
         return '升级后的到期时间:';
        default:
          return '开通后的到期时间:'

      }
    },
    vipVersionClass() {
      return {
        "free-vip": this.app.userInfo.vipLevel === 0,
        "personal-vip": this.app.userInfo.vipLevel === 4|| this.app.userInfo.vipLevel === 201,
      };
    },
    featureList(){
      if (this.app.packageType === 0 && (this.app.standardType === 0 || this.app.standardType === 1)){
        return COMPANY_RIGHT;
      } else if(this.app.packageType === 0 && this.app.standardType === 2){
        return BIG_COMPANY_RIGHT;
      } else if (this.app.packageType === 1){
        return COMPANY_RIGHT_MORE;
      } else if (this.app.packageType === 2){
        return FLAGSHIP_RIGHT
      }
      return '';
    },
    vipVersionName() {
      let versionIntroduce= {
        versionName: '',
        desc: ''
      }
      if (this.app.packageType === 0 && this.app.standardType === 0 || this.app.standardType === 1){
        versionIntroduce.versionName = '通用版'
        versionIntroduce.desc = "适用于个人非工商注册主体/工商注册主体*1人使用"
      } else if(this.app.packageType === 0 && this.app.standardType === 2){
        versionIntroduce.versionName = '标准版（大企业)'
        versionIntroduce.desc = "适用于<a href='https://www.yuque.com/ckt-help/pgmhs0/dw8g9lgrqbtfa12c?singleDoc#' target='_blank' style='color: #1B2337; text-decoration-line: underline;'>大型企业</a>工商注册主体*1人使用"
      } else if (this.app.packageType === 1){
        versionIntroduce.versionName = '协作版'
        versionIntroduce.desc = "适用于10人以内有共享协作需求的团队/企业使用"
      } else if (this.app.packageType === 2){
        versionIntroduce.versionName = this.isLenovoSite ? '更多咨询' : '旗舰版'
        versionIntroduce.desc = '适用于大型企业/跨地域/多子公司'
      }
      return versionIntroduce
    },
    vipPageUrl() {
      let host = location.host
      if(location.host.includes('mater')){
        host = 'www.chuangkit.com'
      }
      return `//${host}/price/vip#legalCompare`
    }
  },
};
</script>

<style lang="less" scoped>
@import url("../common/common.less");
.vip-pop-pay-sider {
  position: relative;
  .flex(space-between, flex-start, column);
  width: 184px;
  min-height: 496px;
  background: #F5F7FD;
  padding: 12px 16px 16px 16px;
  border-radius: 12px;
  overflow: hidden;
  .user-info {
    .flex(flex-start);
    margin-bottom: 12px;
    .avatar {
      height: 48px;
      width: 48px;
      border: 1px solid #ebeef5;
      border-radius: 50%;
    }
    .info {
      .flex(center, flex-start, column);
      margin-left: 8px;
      .user-name {
        .ellipsis;
        width: 6em;
        font-size: 14px;
        color: @fontColor;
        font-weight: bold;
      }
      .user-version {
        font-size: 12px;
        line-height: 20px;
        padding: 0 8px;
        border-radius: 2px;
        color: #6b3d1e;
        background-image: linear-gradient(90deg, #fceee1 0%, #fcdac1 100%);
        &.free-vip {
          color: #505a71;
          background: #ebeef5;
        }
        &.personal-vip {
          color: #0773fc;
          background: #e6f1fe;
        }
      }
    }
  }
  .vip-package-info{
    color: #0773FC;
    font-family: PingFang SC;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    .title{
      color: #1B2337;
      font-family: PingFang SC;
      font-weight: bold;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: left;
      &.num-title{
        margin-top: 8px;
      }
    }
    .info-data{
      margin-top: 4px;
    }
    .company-color{
      color:#6B3D1E;
    }
  }
  // 横线
.horizontal-line {
  height: 1px;
  border: 0;
  flex-shrink: 0;
  &.paysection {
    width: 100%;
    background: #dcdfe6;
    margin: 16px 0 16px 0;
  }
  &.personal {
    width: 106px;
    background: #e4e7ed;
    margin: 32px 0 18px;
  }
}
  .buy-feature-group {
    margin-top: 12px;
    color:#505A71;
      // li:before {
      //   position: absolute;
      //   left: 6px;
      //   top: 8px;
      //   content: "";
      //   width: 4px;
      //   height: 4px;
      //   background-color: #1B2337;
      //   border-radius: 50%;
      // }
     li:nth-child(-n + 1){
       color:#0773FC;
      //  &:before {
      //   position: absolute;
      //   left: 6px;
      //   top: 8px;
      //   content: "";
      //   width: 4px;
      //   height: 4px;
      //   background-color: #0773FC;
      //   border-radius: 50%;
      // }
     }
    .company-color:nth-child(-n + 1){
      color:#6B3D1E;
      // &:before{
      //   background-color: #6B3D1E;
      // }
    }
    .flagship-color:nth-child(-n + 1){
      color:#E2B100;
    }
    .ai-right-list{
      position: relative;
      border-radius: 4px;
      // span{
      //   &:before {
      //     position: absolute;
      //     left: 104px;
      //     top: -17px;
      //     content: "";
      //     width: 56px;
      //     height: 24px;
      //     background-image: url("../img/aiLable.svg");
      //     background-size: contain;
      //     background-repeat: no-repeat;
      //   }
      // }
    }
    .koutu-right-list{
      position: relative;
      border-radius: 4px;
      span{
        &:before {
          position: absolute;
          left: 104px;
          top: -4px;
          content: "";
          width: 56px;
          height: 24px;
          background-image: url("../img/koutuLable.svg");
          background-size: 100% 100%;
        }
      }
    }
    .buy-feature-item {
      // padding-left: 16px;
      position: relative;
      font-size: 12px;
      line-height: 20px;
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }
  .right-title{
    padding-bottom: 12px;
    border-bottom: 1px solid #EBEEF5;
    .version-name {
      padding-left: 30px;
      position: relative;
      font-weight: 500;
      color: #1B2337;
      font-size: 16px;
      line-height: 24px; 
      &.personal-icon{
        &::after{
          content: '';
          width: 16px;
          height: 16px;
          position: absolute;
          background-image: url("../img/person-level-icon.svg");
          background-size:cover;
          background-repeat: no-repeat;
          left: 7px;
          top: 4px;
        }
      }
      &.company-icon{
        &::after{
          content: '';
          width: 16px;
          height: 16px;
          position: absolute;
          background-image: url("../img/company-level-icon.svg");
          background-size:cover;
          background-repeat: no-repeat;
          left: 7px;
          top: 4px;
        }
      }
      &.flagship-icon{
        &::after{
          content: '';
          width: 16px;
          height: 16px;
          position: absolute;
          background-image: url("../img/flagship-level-icon.svg");
          background-size:cover;
          background-repeat: no-repeat;
          left: 7px;
          top: 4px;
        }
      }
    }
    .version-desc {
      margin-top: 6px;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      color: #505A71;
    }
  }
  .see-more {
    background: #FFF;
    text-align: center;
    border-radius: 8px;
    border: 1px solid #EBEEF5;
    width: 150px;
    height: 32px;
    font-size: 12px;
    color: #1B2337;
    line-height: 32px;
    cursor: pointer;
  }
}
</style>
