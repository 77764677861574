<template>
  <div
    class="vip-pop-pay-content-pay"
    :class="{ 'company-vip': app.vipIndex > 0}"
  >
      <div class="qrcode-way">
        <div class="mpay-qrcode-container">
          <qrcode :qrcodeurl="app.mPayUrl" :size="140" />
          <span
            v-if="app.qrcodeExpired"
            class="refresh-mask"
            @click="$emit('refresh-qrcode')"
          ></span>
        </div>
        <div class="mpay-text">
          <div class="pay-info-container">
            <div class="need-to-pay ">
              <span>￥<b>{{ (app.needToPay / 100).toFixed(2) }}</b></span>
              <b class="discount" v-if="(app.initPrice-app.initToPay+app.discountAmount) > 0 && app.subTrialDay === 0">已优惠{{ (app.initPrice-app.initToPay+app.discountAmount) / 100 }}元</b>
              <span class="subTryDay" v-if="app.subTrialDay != 0">免费试用{{ app.subTrialDay }}天</span>
            </div>
            <p class="info-data">开通后有效期至：<span class="limit-time">{{app.afterBuyExpireDate}}</span></p>
            <p class="tip-unit-price" v-if="app.packageType !== 0">
              共<span> {{app.personNum}} </span>个成员席位，还可邀请<span> {{app.personNum-app.memberAmount>0?app.personNum-app.memberAmount:0}} </span>人，10人以上请选购<span @click="choseflagship">旗舰版</span>
            </p>
            <p class="tip-unit-price" v-else>
              仅<span> 1 </span>人使用，多人协作可选择<span @click="choseVip(1)"> 协作版 </span>或<span @click="choseVip(2)"> 旗舰版 </span>
            </p>
            <p class="coupon-use" v-if="app.isSignPackage === 0">
              <span @click="$emit('coupon-show')">使用优惠券/码</span>
              <b>|</b>
              <span @click="$emit('transfer-show')">对公转账</span>
            </p>
            <div class="pay-info-container-bottom">
              <p class="qr-tip">
                <template v-if="app.payType!==2"><span class="aliPay"></span>支付宝</template> <template v-if="app.payType!==1"><span class="wxPay"></span>微信</template>&nbsp;&nbsp;扫码完成支付
              </p>
              <!-- 发票 + 协议 -->
              <div class="invoice-protocol">
                  支付即代表您同意<a href="/terms/skuvip" target="_blank">《创客贴VIP服务协议》</a>，支付后可在订单中心<a href="/list/invoice" target="_blank">开具发票</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 无缝滚动效果 -->
      <!-- <div class="marquee-wrap record-loop">
        <ul class="marquee-list" :class="{'animate-up': animateUp}">
          <li v-for="(item, index) in listData" :key="index">
            <img :src="item.headImageUrl" alt="">
            <span>{{desensitizedName(item.userName)}}购买了{{item.packageName}}
            </span>
          </li>
        </ul>
      </div> -->
    <!-- 一键升级确认弹窗 -->
    <lt-modal
      v-model="upgradeConfirmVisible"
      title="套餐升级"
      :simple="false"
      :z-index="1002"
      ok-text="确认升级"
      cancel-text="狠心放弃"
      @on-ok="$emit('use-balance-pay')"
      @on-cancel="upgradeConfirmVisible = false"
    >
      <p>
        您现在的{{ this.app.userVersion }}套餐将直接升级为该套餐，确认升级吗？
      </p>
    </lt-modal>
  </div>
</template>

<script>
import { PAY_WAY } from "../constant.js";
import qrcode from "./qrcode.vue";
import {VIP_VERSION} from "../constant.js";

export default {
  name: "PayDisplay",
  components: {
    qrcode,
  },
  inject: ["app"],
  data() {
    return {
      PAY_WAY, // 支付方式
      upgradeConfirmVisible: false, // 一键升级确认
      animateUp: false,
      listData: [],
      timer: null
    };
  },
  mounted() {
    this.getOrderRecordList()
  },

  methods:{
    choseflagship(){
      window.gio('track', 'button_click',{
        button_name: '旗舰版',
        page_name: '会员支付弹框',
        module_name: '协作版支付模块',
        page_url: window.location.href,
        is_login: '是',
        user_vip: VIP_VERSION[this.app.userInfo.vipLevel],
        team_id: this.app.teamInfo.teamId,
        team_vip: this.app.teamInfo.flag ? VIP_VERSION[this.app.teamInfo.adminUserVipLevel] : '免费团队',
        user_id:  this.app.userInfo.userId,
      })
      this.$emit('changePackageType', 2)
    },
    choseVip(type){
      window.gio('track', 'button_click',{
        button_name: type == 1 ?'协作版': '旗舰版',
        page_name: '会员支付弹框',
        module_name: '通用版支付模块',
        page_url: window.location.href,
        is_login: '是',
        user_vip: VIP_VERSION[this.app.userInfo.vipLevel],
        team_id: this.app.teamInfo.teamId,
        team_vip: this.app.teamInfo.flag ? VIP_VERSION[this.app.teamInfo.adminUserVipLevel] : '免费团队',
        user_id:  this.app.userInfo.userId,
      })    
      this.$emit('changePackageType', type)
    },
    desensitizedName (fullName) {
      try {
        let str = fullName.substr(0, 1);
        return str+'***';
      } catch (error) {
        return '***';
      }
    },
    scrollAnimate() {
      this.animateUp = true
      setTimeout(() => {
        this.listData.push(this.listData[0])
        this.listData.shift()
        this.animateUp = false
      }, 500)
    },
    getOrderRecordList(){
      try {
        this.$http
        .post("/vip/vip/memberOrder/getOrderRecordList",{ gw: true, GW: true })
        .then((res) => {
          if (res.body.code === 200) {
            this.listData = res.body.data
            this.timer = setInterval(this.scrollAnimate, 1500);
          }
        });
      } catch (error) {
        console.log(error)
      }
    }
  },
  destroyed() {
    clearInterval(this.timer)
  }
};
</script>

<style lang="less" scoped>
@import url("../common/common.less");
.vip-pop-pay-content-pay {
  background: #fff;
  .flex(flex-start);
  width: 658px;
  border-radius: 4px;
  // padding-right: 32px;
  // margin-top: 16px;
  overflow: hidden;
  // 快捷支付
  .qrcode-way {
    // margin-left: 16px;
    .flex();
    .mpay-qrcode-container {
      position: relative;
      width: 164px;
      height: 164px;
      padding: 12px;
      background-image: url("../img/qrcode_bg.svg");
      background-size: contain;
      .refresh-mask {
        position: absolute;
        top: 12px;
        left: 12px;
        width: 140px;
        height: 140px;
        background-color: rgba(0, 0, 0, 0.4);
        background-image: url("../img/price_refresh_qrcode.svg");
        background-size: 40%;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
      }
    }
    .mpay-text {
      .flex(flex-start, flex-start, column);
      margin-left: 16px;
      .qr-tip {
        display: flex;
        font-size: 12px;
        align-items: center;
        color: #8693ab;
        span {
          width: 12px;
          height: 12px;
          background-size: 100% 100%;
          background-repeat:no-repeat ;
          margin: 0 4px;
          &.aliPay{
           background-image: url("../img/vipPop_zf_icon.png");
          }
          &.wxPay {
            background-image: url("../img/vipPop_wx_icon.png") ;
          }
        }
      }
    }
  }
  // 应付金额
  .pay-info-container {
    height: 164px;
    position: relative;
    width: 420px;
    color: @fontColor;
    font-size: 14px;
    .need-to-pay {
      font-size: 14px;
      position: relative;
      color: #fa2323;
      overflow: hidden;
      span{
        float: left; 
      }
      b {
        font-family: DIN Alternate;
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
      }  
      .discount{
        background: #FEE9E9;
        float: left;
        border-radius: 4px 4px;
        padding: 0 6px;
        color:#FA2323;
        margin-top: 7px;
        margin-left: 8px;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
      }
      .subTryDay{
        display: flex;
        margin-left: 8px;
        margin-top: 7px;
        align-items: center;
        justify-content: center;
        width: 80px;
        border-radius: 4px;
        background: var(----light, #FEE9E9);
        color: var(---, #FA2323);
        font-size: 12px;
        line-height: 20px;
      }
    }
    .info-data{
      margin-top: 4px;
      color: #505A71;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      .limit-time{
        color: #0773FC;
      }
    }
    .tip-unit-price {
      margin-top: 2px;
      color: #505A71;
      font-family: PingFang SC;
      font-weight: regular;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: left;
      span{
        cursor: pointer;
       color: #0773FC;
      }
    }
    &-bottom{
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  // 优惠券
  .coupon-use {
    margin: 6px 0 6px 0;
    line-height: 20px;
    font-size: 12px;
    color: #505A71;
    text-decoration: underline;
    span {
      cursor: pointer;
      &:hover {
        color: #0668e3;
      }
    }
    b{
      padding:0 6px;
      color: #EBEEF5;
    }
  }
  .trans-coupon {
    margin-bottom: 16px;
  }
  &.company-vip {
    .qrcode-way .mpay-qrcode-container {
      background-image: url("../img/qrcode_bg_high_level.png");
    }
  }
  //购买记录滚动
// .marquee-wrap  {
//   margin-left: 16px;
//   width: 80%;
//   height: 104px;
//   border-radius: 20px;
//   margin: 0 auto;
//   overflow: hidden;
//   .marquee-list {
//     li {
//       display: flex;
//       height: 34px;
//       align-items: center;
//       img{
//         width: 20px;
//         height: 20px;
//         border-radius: 50% 50%;
//       }
//       text-overflow: ellipsis;
//       overflow: hidden;
//       white-space: nowrap;
//       padding: 0 20px;
//       list-style: none;
//       line-height: 34px;
//       text-align: center;
//       color: #211B37;
//       font-size: 12px;
//     }
//   }
//   .animate-up {
//     transition: all 0.5s ease-in-out;
//     transform: translateY(-34px);
//   }
// }
  .invoice-protocol{
    margin-top: 4px;
    color: #8693AB;
    font-size: 12px;
    line-height: 20px;
    a{
      text-decoration-line: underline;
      color: #505A71;
    }
  }

}
</style>
