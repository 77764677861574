<template>
  <div>
    <LtModal
      v-model="showGiftPack"
      className="transparent-bg"
      :mask-closable="false"
      :closable="closable"
      @on-visible-change="onModalCancel"
      @on-cancel="onCancelBtnClick"
    >
      <div class="images" v-if="state.showPack">
        <img src="../img/gift.png" alt="" srcset="" />
        <div class="carousel">
          <ul
            class="user-list"
            :class="{ animate: animate }"
            :style="{ transform: `translateY(${carouselNumber}px)` }"
          >
            <li class="user" v-for="(item, index) in listData" :key="index">
              {{ item }}
            </li>
          </ul>
        </div>
        <div class="count-down">
          <img class="icon-clock" src="../img/clock.svg" alt="" srcset="" />
          <div class="count-text">
            剩余<span class="ms">{{ time.h }}:</span>
            <span class="ms">{{ time.m }}:</span>
            <span class="ms">{{ time.s }}</span>
            <span class="ms">&nbsp;{{ time.ms }}</span>
          </div>
        </div>
        <div class="receive">
          <div class="receive-btn" @click="receivepack"></div>
        </div>
      </div>
      <div
        class="login-bindphone"
        v-show="state.show_login_bindPhone_bindSuccess"
      >
        <Sign
          v-if="state.showLoginSdk"
          :config="config"
          :isLogin="isLogin"
          :type="1"
          :route="route"
          :state="stateParam"
          :trackerBaseParams="trackerBaseParams"
          @setUserInfo="setUserInfo"
          @set-logreg-show="setLogregShow"
          @success="handleLoginSucceed"
          @showRisk="showRisk"
        ></Sign>
        <img
          v-if="!state.showLoginSdk && imgUrl"
          class="ad-banner"
          :src="imgUrl"
          alt=""
          srcset=""
        />
        <div class="bindphone" v-show="state.show_bindPhone_bindSuccess">
          <bind-phone
            v-if="state.showBPComp"
            :listData="listData"
            :visibile="state.showBPComp"
            :tipType="tipType"
            @gio="clickGio"
            @bindSuccess="bindSuccess"
            @pureBindPhoneSuccess="pureBindPhoneSuccess"
            @autoCloseBindPhone="autoCloseBindPhone"
            @pureBindPhoneFail="pureBindPhoneFail"
          />
        </div>
      </div>
      <!-- <keep-user
        v-if="state.showKeepUser"
        @keepUser="keepUser"
        @reject="reject"
      /> -->
      <task-center
        v-if="state.showTaskCenter"
        @bindPhoneEmitHandler="bindPhoneEmitHandler"
        @bindSuccess="bindSuccess"
      ></task-center>
      <retain-users
        v-if="state.showRetainUsers"
        @keepUser="keepUser"
        @reject="reject"
      ></retain-users>
      <Success
        v-if="state.showSuccess"
        @bindPhoneEmitHandler="bindPhoneEmitHandler"
        @bindSuccess="bindSuccess"
      />
    </LtModal>
    <riskLoginPwd
      :showRiskLogin="showRiskLogin"
      :riskLoginLimit="riskLoginLimit"
      :userInfo="userInfo"
      @closeRisk="closeRisk"
    />
    <bindFail
      :phone= "phone" 
      :userInfo="userInfo"
      :bindFailShow="bindFailShow"
      @closeFailToast='closeFailToast'
      @showBindPhone="showBindPhone"
    />
  </div>
</template>
<script>
  import taskCenter from './taskCenter.vue';
  import retainUsers from './retainUsers.vue';
  import Sign from '../signComp.vue';
  import Success from './success.vue';
  import bindPhone from './bindphone.vue';
  import bindFail from './bindfail.vue';
  // import keepUser from './keepUser.vue';
  import riskLoginPwd from '../riskLogin/index.vue'
  import { giftPack } from './mixin';
  import dayJs from 'dayjs';
  import _ from 'lodash';
  import pageNameEnum from '../pageNameEnum';
  import userList from './mockUser';
  const popupName = {
    step1: '试用会员福利引导弹框',
    step2: '登录弹框',
    step3: '领取试用会员弹框_登录成功+绑定',
    step4: '试用会员领取弹框_引导+绑定',
    step5: '试用会员领取成功弹框',
    step6: '领取试用会员挽留弹框',
    step9: '绑定弹框_登录成功+绑定',
  };
  const initSatus = {
    show_login_bindPhone_bindSuccess: false, // 显示登录、绑定手机号、领取成功模块
    showPack: false, // 显示领取礼包
    show_bindPhone_bindSuccess: false, //显示绑定手机号和领取成功
    showSuccess: false, // 领取成功
    showBPComp: false, // 绑定手机号
    showLoginSdk: true,
    showTaskCenter: false,
    showRetainUsers: false,
  };
  export default {
    name: 'giftPack',
    components: {
      Sign,
      bindPhone,
      Success,
      taskCenter,
      retainUsers,
      riskLoginPwd,
      bindFail,
    },
    props: {
      userInfo: {
        type: Object,
      },
      isLogin: {
        type: Boolean,
        default: false,
      },
      route: {
        type: Object,
      },
      config: {
        type: Object,
        default() {
          return {
            APIDOMAIN: '//api.chuangkit.com',
            APIDOMAINV2: '//api-v2.chuangkit.com',
          };
        },
      },
      giftPackShow: {
        type: Number,
        default: 0,
      },
      trackerBaseParams: {
        type: Object,
        default: () => ({})
      }
    },
    mixins: [giftPack],
    watch: {
      'state.showPack': {
        handler(val) {
          if (!val) {
            // 关闭时候 如果不是挽留弹框 关闭定时器
            if (!this.state.showRetainUsers) {
              this.clearTimer();
            }
          } else {
            // 显示第一步时如果没有开启定时器  则初始化第一步的定时器
            if (!this.carouselTimer && !this.countTimer) {
              this.initState();
            }
          }
          try {
            if (window.document) {
              document.addEventListener(
              'visibilitychange',
              this.carouselShowPackEventListener,
              false,
            );
            }
          } catch (error) {
            console.log(error);
          }
        },
        immediate: true,
      },
      state: {
        handler(val, oldVal) {
          this.oldState = oldVal;
        },
        deep: true,
        immediate: true,
      },
      giftPackShow: {
        handler: async function(val) {
          if (val) {
            if (!this.isLogin) {
              this.getBannerPictureInfo();
              this.setState({
                show_login_bindPhone_bindSuccess: true,
                showPack: false,
                show_bindPhone_bindSuccess: false,
                showSuccess: false,
                showBPComp: false,
                showLoginSdk: true,
                showGiftPack: true,
                closeDirect: true,
                loginSuccess: false,
                showRetainUsers: false,
              });
              this.tipType = 1;
              this.gioFn('step2');
            } else if (val === 1 || val === 2) {
              const { adStatus } = await this.getTaskAdvert();
              if (adStatus) {
                this.$message.closeAll();
                // this.$message('仅限新登录/注册用户可领取哦~');
                setTimeout(() => {
                  window.location.reload();
                }, 2e3);
                return;
              }
              // this.initPop();
              // 打开 任务中心 TODOLIST
              this.initTaskCenter();
            } else {
              // 领取试用会员成功
              this.bindSuccess();
            }
          }
        },
      },
    },
    data() {
      return {
        listData: userList,
        showGiftPack: false,
        closeDirect: true, // 是否直接关闭
        state: {
          show_login_bindPhone_bindSuccess: false, // 显示登录、绑定手机号、领取成功模块
          showPack: false, // 显示领取礼包
          show_bindPhone_bindSuccess: false, //显示绑定手机号和领取成功
          showSuccess: false, // 领取成功
          showBPComp: false, // 绑定手机号
          showLoginSdk: true,
          showTaskCenter: false,
          showRetainUsers: false,
        },
        oldState: {
          show_login_bindPhone_bindSuccess: false, // 显示登录、绑定手机号、领取成功模块
          showPack: true, // 显示领取礼包
          show_bindPhone_bindSuccess: false, //显示绑定手机号和领取成功
          showSuccess: false, // 领取成功
          showBPComp: false, // 绑定手机号
          showTaskCenter: false,
          showRetainUsers: false,
        },
        emitVal: {
          setLogregShow: '',
          setUserInfo: '',
          success: '',
        },
        loginSuccess: false,
        imgUrl: '', // banner
        stateParam: JSON.stringify({ giftPack: true }),
        packPoped: false,
        keepType: 0, // 挽留类型 0 未绑定手机号 1 已绑定手机号 2 登录未绑定手机号
        closable: true,
        tipType: 1,
        step: 'step1',
        buttonName: '免费领取',
        oldStep: 'step1',
        bindFailShow: false, //绑定手机号弹框
        phone: '',
        showRiskLogin: false, // 风控 登录限制
        riskLoginLimit: 0
      };
    },
    methods: {
      showRisk (limit) {
        this.showRiskLogin = true;
        this.riskLoginLimit = limit;
      },
      closeRisk () {
        this.showRiskLogin = false
      },
      autoCloseBindPhone() {
        this.setState({
          showTaskCenter: false,
          show_login_bindPhone_bindSuccess: false,
          showPack: false,
          show_bindPhone_bindSuccess: false,
          showSuccess: false,
          showBPComp: false,
          showLoginSdk: false,
          showGiftPack: false,
          closeDirect: true,
          loginSuccess: false,
          showRetainUsers: false,
        });
      },
      async bindPhoneEmitHandler() {
        this.getBannerPictureInfo();
        this.tipType = 0;
        this.setState({
          showTaskCenter: false,
          show_login_bindPhone_bindSuccess: true,
          showPack: false,
          show_bindPhone_bindSuccess: true,
          showSuccess: false,
          showBPComp: true,
          showLoginSdk: false,
          showGiftPack: true,
          closeDirect: false,
          loginSuccess: true,
          showRetainUsers: false,
        });
        this.gioFn('step4')
      },
      async initTaskCenter() {
        this.getBannerPictureInfo();
        this.setState({
          showTaskCenter: true,
          show_login_bindPhone_bindSuccess: false,
          showPack: false,
          show_bindPhone_bindSuccess: false,
          showSuccess: false,
          showBPComp: false,
          showLoginSdk: false,
          showGiftPack: true,
          closeDirect: true,
          loginSuccess: true,
          showRetainUsers: false,
        });
      },
      clickGio(v) {
        this.buttonName = v;
        this.gioClick();
      },
      onCancelBtnClick() {
        this.buttonName = '关闭';
        this.gioClick();
      },
      bindSuccess() {
        this.setState({
          show_login_bindPhone_bindSuccess: false,
          showPack: false,
          show_bindPhone_bindSuccess: false,
          showSuccess: true,
          showBPComp: false,
          showLoginSdk: false,
          showGiftPack: true,
          closeDirect: true,
          loginSuccess: true,
          showTaskCenter: false,
          showRetainUsers: false,
        });
        this.gioFn('step5');
      },
      clearTimer() {
        if (this.carouselTimer) {
          clearInterval(this.carouselTimer);
          this.carouselTimer = 0;
        }
        if (this.countTimer) {
          clearTimeout(this.countTimer);
          this.countTimer = 0;
        }
      },
      closeModal() {
        this.buttonName = '自动关闭';
        this.gioClick();
        this.keepType = 0;
        this.closeDirect = true;
        this.showGiftPack = false;
      },
      // 国家法律绑定
      pureBindPhoneSuccess() {
        this.buttonName = '立即绑定';
        this.gioClick();
        this.setState({
          show_login_bindPhone_bindSuccess: false,
          showPack: false,
          show_bindPhone_bindSuccess: false,
          showSuccess: false,
          showBPComp: false,
          showLoginSdk: false,
          showGiftPack: false,
          closeDirect: true,
          loginSuccess: true,
          showTaskCenter: false,
          showRetainUsers: false,
        });
      },
      reject() {
        this.buttonName = '狠心放弃';
        this.gioClick();
        this.closeDirect = true;
        this.loginSuccess = true;
        this.showGiftPack = false;
      },
      keepUser() {
        this.buttonName = '继续领福利';
        this.gioClick();
        this.closeDirect = false;
        const oldState = _.cloneDeep(this.oldState);
        this.gioFn(this.oldStep);
        this.state = oldState;
      },
      onModalCancel(status) {
        if (!status && this.closeDirect) {
          this.finalEmit();
          return;
        }
        if (!status && !this.closeDirect) {
          this.setState({
            show_login_bindPhone_bindSuccess: false,
            showPack: false,
            show_bindPhone_bindSuccess: false,
            showSuccess: false,
            showBPComp: false,
            showLoginSdk: false,
            showGiftPack: true,
            closeDirect: true,
            loginSuccess: true, // 不确定登录成功没
            showTaskCenter: false,
            showRetainUsers: true,
          });
          // 挽客弹窗 类型打点
          this.oldStep = this.step;
          this.gioFn('step6');
          return;
        }
        if (!status) {
          // TODO
          this.showGiftPack = false;
        }
      },
      async receivepack() {
        this.buttonName = '免费领取';
        this.gioClick();

        if (!this.isLogin) {
          this.setState({
            show_login_bindPhone_bindSuccess: true,
            showPack: false,
            show_bindPhone_bindSuccess: true,
            showSuccess: false,
            showBPComp: false,
            showLoginSdk: true,
            showGiftPack: true,
            closeDirect: true,
            loginSuccess: false,
            showTaskCenter: false,
            showRetainUsers: false,
          });
          this.gioFn('step2');
        } else {
          const { taskList } = await this.getTaskAdvert();
          const taskIdArr = taskList.reduce((acc, item) => {
            if (item.status === 1) {
              acc.push(item.taskId);
            }
            return acc;
          }, []);
          // 待领取的一个任务 点击直接领取 多个任务待领取跳转到任务中心去领取
          if (taskIdArr.length === 1) {
            const res = await this.getReward(taskIdArr[0]);
            if (res) {
              this.setState({
                show_login_bindPhone_bindSuccess: false,
                showPack: false,
                show_bindPhone_bindSuccess: false,
                showSuccess: true,
                showBPComp: false,
                showLoginSdk: false,
                showGiftPack: true,
                closeDirect: true,
                loginSuccess: true,
                showTaskCenter: false,
                showRetainUsers: false,
              });
              this.gioFn('step5');
            }
          } else {
            this.initTaskCenter();
          }
        }
      },
      getTaskAdvert() {
        return new Promise((resolve, reject) => {
          try {
            this.$http
              .get('/hatchery/activity/task/advert/get', {
                params: {
                  _dataClientType: 0,
                  platform: 0,
                  gw: true,
                  alias: 'task_for_reward',
                },
                data: {
                  gw: true,
                  requestMethod: 'GET',
                  template: true,
                  nottrans: true,
                },
              })
              .then((res) => {
                let code, data;
                // 如果是chuangkit-saas 项目
                if (this.$route.path.includes('/designtools') ||this.$route.path.includes('/design-service') || this.$route.path === '/') {
                  code = res.body && res.body.code;
                  data = res.body && res.body.data;
                } else {
                  code = res.data && res.data.body && res.data.body.code;
                  data = res.data && res.data.body && res.data.body.data;
                }
                if (code === 200) {
                  resolve(data);
                } else {
                  reject(null);
                }
              });
          } catch (error) {
            reject(error);
          }
        });
      },
      async getReward(taskId) {
        const res = await this.$http.get('/hatchery/activity/task/receive', {
          params: { taskId, gw: true, nottrans: true },
          data: {
            gw: true,
            requestMethod: 'GET',
            template: true,
            nottrans: true,
          },
        });
        let data;
        // 如果是chuangkit-saas 项目
        if (this.$route.path.includes('/designtools') || this.$route.path === '/') {
          data = res.body && res.body.data;
        } else {
          data = res.data && res.data.body && res.data.body.data;
        }
        return data;
      },
      async receiveCoupon() {
        const {
          body: { code },
        } = await this.$http.post(
          '/vip/vipCoupon/newUserAct/receiveCoupon',
          { visitSiteId: 1, gw: true, nottrans: true },
          { nottrans: true },
        );
        return code;
      },
      async fetchUserInfo() {
        const getInfo = await this.$http.post('/accountcenter/user/getAccountUserInfo', {
          client_type: 0,
          gw: true
        });
        return getInfo;
      },
      async handleLoginSucceed(type, logregInvoke) {
        // 只在登录成功时调
        if (!logregInvoke) {
          return;
        }
        this.emitVal.success = type;
        this.handleThirdpartyLogin();
      },
      setUserInfo(val) {
        this.emitVal.setUserInfo = val;
      },
      setLogregShow(val) {
        this.emitVal.setLogregShow = val;
      },
      finalEmit() {
        if (this.loginSuccess) {
          // this.$emit("set-logreg-show", this.emitVal.setLogregShow);
          this.emitVal.setUserInfo &&
            this.$emit('setUserInfo', this.emitVal.setUserInfo);
          this.emitVal.success && this.$emit('success', this.emitVal.success);
          this.$emit('getUserInfo');
          this.$emit('setGiftNumber');
        }
        const _initSatus = _.cloneDeep(initSatus);
        this.state = _initSatus;
        this.tipType = 1;
        this.keepType = 0;
        this.$emit('giftShow', 0);
        document.removeEventListener('visibilitychange', this.carouselEventListener);
      },
      // 也可以处理非跳转的登录成功
      async handleThirdpartyLogin() {
        // const getInfo = await this.$http.post('/user/getUserInfo', {
        //   client_type: 0,
        // });
        this.getBannerPictureInfo();
        const { adStatus, taskList } = await this.getTaskAdvert();

        if (adStatus === 1) {
          this.$message.closeAll();
          // this.$message('仅限新登录/注册用户可领取哦~');
          // 用户不符合活动条件  也未绑定手机号  国家法律要求
          const getInfo = await this.fetchUserInfo();
          this.getBannerPictureInfo();
          const UserInfo = getInfo.body.userInfo;
          if (!UserInfo.phone) {
            this.tipType = 2;
            this.setState({
              show_login_bindPhone_bindSuccess: true,
              showPack: false,
              show_bindPhone_bindSuccess: true,
              showSuccess: false,
              showBPComp: true,
              showLoginSdk: false,
              showGiftPack: true,
              closeDirect: true,
              loginSuccess: true,
              showTaskCenter: false,
              showRetainUsers: false,
            });
            this.gioFn('step9');
          } else {
            this.setState({
              show_login_bindPhone_bindSuccess: false,
              showPack: false,
              show_bindPhone_bindSuccess: false,
              showSuccess: false,
              showBPComp: false,
              showLoginSdk: false,
              showGiftPack: false,
              closeDirect: true,
              loginSuccess: true,
              showTaskCenter: false,
              showRetainUsers: false,
            });
            this.finalEmit();
          }
        }
        const getInfo = await this.fetchUserInfo();
        const UserInfo = getInfo.body.userInfo;
        // 绑定手机号任务未完成 =>去绑定手机号
        if (taskList.some((item) => item.taskId === 1 && !item.status)) {
          this.setState({
            show_login_bindPhone_bindSuccess: true,
            showPack: false,
            show_bindPhone_bindSuccess: true,
            showSuccess: false,
            showBPComp: true,
            showLoginSdk: false,
            showGiftPack: true,
            closeDirect: false,
            loginSuccess: true,
            showTaskCenter: false,
            showRetainUsers: false,
          });
          this.tipType = 1;
          this.gioFn('step3');
        } else if (taskList.some((item) => item.taskId === 1 && item.status === 2) && !UserInfo.phone) {
            // 完成绑定手机号任务领取后但是 又解绑了
            this.tipType = 2;
            this.setState({
              show_login_bindPhone_bindSuccess: true,
              showPack: false,
              show_bindPhone_bindSuccess: true,
              showSuccess: false,
              showBPComp: true,
              showLoginSdk: false,
              showGiftPack: true,
              closeDirect: true,
              loginSuccess: true,
              showTaskCenter: false,
              showRetainUsers: false,
            });
            this.gioFn('step9');
        } else if (
          taskList.some((item) => item.status === 1)
        ) {
          // 手机号已绑定 || 偏好设置未领取 但未领取 => 直接领取成功 => 到领取成功弹窗
          const item = taskList.find((item) => item.status === 1); 
          const status = await this.getReward(item.taskId);
          if (status) {
            this.setState({
              show_login_bindPhone_bindSuccess: false,
              showPack: false,
              show_bindPhone_bindSuccess: false,
              showSuccess: true,
              showBPComp: false,
              showLoginSdk: false,
              showGiftPack: true,
              closeDirect: true,
              loginSuccess: true,
              showTaskCenter: false,
              showRetainUsers: false,
            });
            this.tipType = 1;
            this.gioFn('step5')
          }
        } else {
          this.setState({
            show_login_bindPhone_bindSuccess: false,
            showPack: false,
            show_bindPhone_bindSuccess: false,
            showSuccess: false,
            showBPComp: false,
            showLoginSdk: false,
            showGiftPack: false,
            closeDirect: true,
            loginSuccess: true,
            showTaskCenter: false,
            showRetainUsers: false,
          });
          this.finalEmit();
        }
      },
      setExpires() {
        const date = dayJs();
        localStorage.setItem('popDate', `${date.$y}${date.$M}${date.$D}`);
      },
      async initState() {
        
        this.countTime(
          dayJs()
            .add(5, 'm')
            .format('YYYY/MM/DD HH:mm:ss'),
          this.closeModal,
        );
        this.setExpires();
        await this.getUserList();
        this.carousel(42);
      },
      async initPop() {
        const getInfo = await this.fetchUserInfo();
        this.getBannerPictureInfo();
        const { LoginTimeOut } = getInfo.body;
        if (LoginTimeOut) {
          this.setState({
            show_login_bindPhone_bindSuccess: false,
            showPack: true,
            show_bindPhone_bindSuccess: false,
            showSuccess: false,
            showBPComp: false,
            showLoginSdk: false,
            showGiftPack: true,
            closeDirect: false,
            loginSuccess: false,
            showTaskCenter: false,
            showRetainUsers: false,
          });
          this.gioFn('step1');
          this.setExpires();
          return;
        }
        const { taskList } = await this.getTaskAdvert();
        // 未完成绑定手机号任务 =>直接弹出绑定手机号任务
        if (taskList.some((item) => item.taskId === 1 && item.status === 0)) {
          this.setState({
            show_login_bindPhone_bindSuccess: true,
            showPack: false,
            show_bindPhone_bindSuccess: true,
            showSuccess: false,
            showBPComp: true,
            showLoginSdk: false,
            showGiftPack: true,
            closeDirect: false,
            loginSuccess: true,
            showTaskCenter: false,
            showRetainUsers: false,
          });
          this.keepType = 2;
          this.tipType = 0;
          this.gioFn('step4');
          this.setExpires();
          return;
        }
        // 如果绑定手机号 或者 行业偏好设置 任务完成未领取 => 弹第一个弹框
        if (taskList.some((item) => item.status === 1)) {
          this.setState({
            show_login_bindPhone_bindSuccess: false,
            showPack: true,
            show_bindPhone_bindSuccess: false,
            showSuccess: false,
            showBPComp: false,
            showLoginSdk: false,
            showGiftPack: true,
            closeDirect: false,
            loginSuccess: false,
            showTaskCenter: false,
            showRetainUsers: false,
          });
          this.gioFn('step1');
          this.setExpires();
        }
      },
      async getBannerPictureInfo() {
        try {
          let isIncludeSecondDomin = await this.getSelfDominPic()
          if (isIncludeSecondDomin) return
          const {
            body: { code, data },
          } = await this.$http.get(
            '/accountcenter/loginBanner/getLoginBanner',
            {
              data: { gw: true, requestMethod: 'GET' },
              params: { app_id: 10 },
            },
          );
          if (code === 200) {
            if (data && data.imgHost && data.bannerUrl) {
              this.imgUrl = `https://${data.imgHost}/${data.bannerUrl}`;
            }
          }
        } catch (error) {
          console.log(error);
        }
      },
      getSelfDominPic(){
        return new Promise(async(resolve)=>{
          try {
            let secondDomain = window.location.hostname.split('.')[0]
            const {
              body: { code, data },
            } = await this.$http.post('/team/fuse/custom/login/page/get', { gw: true,secondDomain})
            if (code === 200) {
              if (data?.loginDialogPic) {
                this.imgUrl = `https:${data.loginDialogPic[0].imgUrl}`;
                resolve(true)
              } else {
                resolve(false)
              }
            } else {
              resolve(false)
            }
          } catch (error) {
            resolve(false)
          }
        })
      },
      gioFn(step) {
        this.step = step;
        try {
          window.gio('track', 'new_gift_popup_show', {
            page_name: pageNameEnum[this.$route.path] || '',
            is_login:
              this.isLogin || this.state.showSuccess || this.state.showBPComp,
            forward_module:
              this.giftPackShow === 1
                ? '右侧活动入口'
                : this.giftPackShow === 2
                ? '页顶右上角活动入口'
                : '全屏活动引导框',
            popup_name: popupName[step],
          });
        } catch (error) {
          console.log(error);
        }
      },
      gioClick() {
        try {
          window.gio('track', 'new_gift_popup_click', {
            page_name: pageNameEnum[this.$route.path] || '',
            is_login:
              this.isLogin || this.state.showSuccess || this.state.showBPComp,
            popup_name: popupName[this.step],
            button_name: this.buttonName,
          });
        } catch (error) {
          console.log(error);
        }
      },
      setState({
        show_login_bindPhone_bindSuccess,
        showPack,
        show_bindPhone_bindSuccess,
        showSuccess,
        showBPComp,
        showLoginSdk,
        showGiftPack = false,
        closeDirect = true,
        loginSuccess = false,
        showTaskCenter = false,
        showRetainUsers = false,
      }) {
        const state = _.cloneDeep(this.state);
        state.show_login_bindPhone_bindSuccess = show_login_bindPhone_bindSuccess; // 显示登录、绑定手机号、领取成功模块
        state.showPack = showPack; // 显示领取礼包
        state.show_bindPhone_bindSuccess = show_bindPhone_bindSuccess; //显示绑定手机号和领取成功
        state.showSuccess = showSuccess; // 领取成功
        state.showBPComp = showBPComp; // 绑定手机号
        state.showLoginSdk = showLoginSdk;
        state.showTaskCenter = showTaskCenter;
        state.showRetainUsers = showRetainUsers;
        this.state = state;
        this.showGiftPack = showGiftPack;
        this.closeDirect = closeDirect;
        this.loginSuccess = loginSuccess;
      },
      async getUserList() {
        // /hatchery/vipCoupon/task/receivedVip
        const {
          body: { code, data },
        } = await this.$http.get('/vip/vipCoupon/task/receivedVip', {
          data: {
            gw: true,
            requestMethod: 'GET',
            template: true,
            nottrans: true,
          },
          params: { visitSiteId: 1, gw: true },
          nottrans: true,
        });
        if (code === 200) {
          this.listData = data.concat(data.slice(0, 1));
        }
      },
      carouselShowPackEventListener() {
        if (document.visibilityState === 'visible') {
          if (this.state.showPack) {
            this.carousel(42);
          }
        } else {
          clearInterval(this.carouselTimer);
        }
      },
      //显示绑定手机号失败弹框
      pureBindPhoneFail(val) {
        this.closeDirect = true;
        this.showGiftPack = false;      
        this.bindFailShow = true 
        this.phone = val
      },
      // 关闭绑定手机号失败弹框
      closeFailToast(){
        this.bindFailShow = false
      },
      // 再出现绑定手机号弹框
      showBindPhone(){
        this.handleThirdpartyLogin();
      }
    },
    async beforeMount() {
      // 三方登录跳转回来的
      // this.$route.query.handleLogin
      // REFERRER_MAP[document.referrer]
      // 当天显示过则直接返回
      // 获取banner
      // this.getBannerPictureInfo();
      const date = dayJs();
      const popDate = `${date.$y}${date.$M}${date.$D}`;
      const packPoped = localStorage.getItem('popDate') == popDate;
      this.packPoped = packPoped;
      // 处理三方登录返回
      // if (localStorage.getItem("giftPack")) {
      //   localStorage.removeItem("giftPack");
      //   await this.handleThirdpartyLogin();
      //   // 今天活动没有弹过
      // } else
      if (!packPoped) {
        const data = await this.getTaskAdvert();
        const { adStatus } = data || {};
        // // 活动实效内 & 今天未弹框
        if (!adStatus) {
          this.initPop();
        }
      }
    },
  };
</script>
<style lang="less" scoped>
  .images {
    width: 701px;
    position: relative;
    font-family: PingFang SC;
    user-select: none;
    img {
      display: block;
      width: 701px;
      height: auto;
    }
    .carousel {
      position: absolute;
      width: 250px;
      height: 42px;
      left: 50%;
      transform: translateX(-50%);
      top: 450px;
      overflow: hidden;
      background: rgba(248, 248, 251, 0.4);
      border-radius: 20px;
      .animate {
        transition: transform 1s;
      }
      .user-list {
        position: absolute;
        top: 0;
        .user {
          width: 250px;
          height: 42px;
          text-align: center;
          line-height: 42px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #ffffff;
          text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.245028);
        }
      }
    }
    .count-down {
      position: absolute;
      width: 260px;
      height: 48px;
      left: 50%;
      transform: translateX(-50%);
      top: 620px;
      background: rgba(0, 0, 0, 0.6);
      border-radius: 33px;
      color: #ffffff;
      text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.245028);
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 0 24px;
      .icon-clock {
        width: 24px;
        height: 24px;
      }
      .count-text {
        flex: 1;
        display: flex;
        font-size: 22px;
        justify-content: center;
        // .time {
        //   flex: 1;
        // }
        .ms {
          width: 32px;
          flex-shrink: 0;
        }
      }
    }
    .receive {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 515px;
      .receive-btn {
        width: 232px;
        height: 80px;
        background: url('../img/receive-btn.png') no-repeat center;
        object-fit: cover;
        box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.2);
        border-radius: 36px;
        background-size: cover;
        cursor: pointer;
        animation: entry 3s ease-in-out infinite;
        animation-play-state: running;
        &:hover {
          animation-play-state: paused;
        }
      }

      @keyframes entry {
        0% {
          transform: scale(0.8);
        }

        50% {
          transform: scale(1);
        }

        100% {
          transform: scale(0.8);
        }
      }
    }
  }
  .login-bindphone {
    position: relative;
    width: 750px;
    height: 500px;
    .ad-banner {
      width: 320px;
      height: 500px;
      display: block;
      object-fit: cover;
    }
    .bindphone {
      background-color: #ffffff;
      position: absolute;
      right: 0;
      top: 0;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
</style>
