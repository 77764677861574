<template>
  <div class="flagship-qustion-box">
    <div class="qr-code-box">
      <img src="../img/flag-tip-code.jpg" />
    </div>
    <div class="right">
      <div class="title">扫码联系专属顾问咨询购买</div>
      <div class="des">10人以上团队使用扫码联系专属客户顾问咨询购买</div>
      <div class="info">
        为企业提供包含内容生产、内容管理、内容流转，定制化视觉设计，AIGC智能设计等在内的一站式解决方案，<a
          href="https://beta.chuangkit.com/contech?isConsult=true&semPosition=会员支付弹框-申请体验"
          target="_blank"
          >申请在线体验></a
        >
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'flagshipQuestion',
    data() {
      return {};
    },
  };
</script>

<style lang="less">
  .flagship-qustion-box {
    width: 100%;
    height: 164px;
    margin-top: 18px;
    display: flex;
    .qr-code-box {
      width: 160px;
      height: 160px;
      padding: 10px;
      background-image: url('../img/qrcode_bg.svg');
      background-size: contain;
      background-repeat: no-repeat;
      img {
        width: 140px;
        height: 140px;
      }
    }
    .right {
      margin-left: 16px;
      .title {
        color: #1B2337;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        margin-top: 4px;
      }
      .des {
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        margin-top: 8px;
        color: #505A71;
      }
      .info {
        width: 452px;
        height: 80px;
        padding: 20px 12px;
        font-size: 12px;
        margin-top: 24px;
        background-color: #f5f7fd;
        border-radius: 8px;
        a {
          cursor: pointer;
          &:hover {
            color: #0667E2;
          }
        }
      }
    }
  }
</style>
