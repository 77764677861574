<template>
  <div class="vip-pop-intro">
    <div class="intro-left">
      <div class="intro-left-header">
        <i
          class="feature-icon"
          :class="[`feature-icon-${activeFeature.index}`]"
        ></i>
        <div>
          <p class="title">{{ activeFeature.title }}</p>
          <p class="sub-title">
            {{
              `升级${
                [3, 4, 6, 8].includes(activeFeature.index) ? "企业VIP" : "会员"
              }即可享受此权益`
            }}
          </p>
        </div>
      </div>
      <p class="intro-left-description">{{ activeFeature.desc }}</p>
      <video
        class="intro-left-video"
        :src="activeFeature.video"
        muted
        autoplay
        loop
      >
        your browser does not support the video tag
      </video>
      <div class="intro-left-upgrade-vip" @click="$emit('upgrade-vip')">
        升级会员
      </div>
    </div>
    <div class="intro-right">
      <div class="feature-title">会员权益包括：</div>
      <div class="feature-list" ref="featureList">
        <div
          class="feature-item"
          v-for="(item, index) in INTRO_FEATURE"
          :key="index"
          :class="{ active: index === activeFeature.index }"
          @click="activeIndex = index"
        >
          <i class="feature-icon" :class="[`feature-icon-${index}`]"></i>
          <span class="title">{{ item.title }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { INTRO_FEATURE } from "../constant.js";

export default {
  name: "RightsIntro",
  props: {
    featureIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      INTRO_FEATURE, // 权益列表
      activeIndex: this.featureIndex, // 当前活跃权益项
    };
  },
  computed: {
    activeFeature() {
      const idx = this.activeIndex;
      return {
        ...INTRO_FEATURE[idx],
        index: idx,
      };
    },
  },
  mounted() {
    const featureListEl = this.$refs.featureList
    const activeFeatureEl = featureListEl.children[this.activeIndex]
    if (!activeFeatureEl) return
    activeFeatureEl.scrollIntoView(0)
  }
};
</script>

<style lang="less" scoped>
@import url("../common/common.less");
.vip-pop-intro {
  .flex(center, flex-start);
  height: 612px;
  padding: 40px 20px 30px;
  .intro-left {
    .flex(flex-start, flex-start, column);
    width: 640px;
    padding: 0 24px 0 16px;
    border-right: 1px solid #eee;
    .intro-left-header {
      .flex();
      .feature-icon {
        width: 64px;
        height: 64px;
        background-size: 1000%;
        margin-right: 12px;
        background-image: url('../img/vipPop_icon.svg');

        each(range(9), {
          &-@{value} {
            background-position-x: -64px * @value;
          }
        })
      }
      .title {
        color: @fontColor;
        font-size: 20px;
        font-weight: bold;
      }
      .sub-title {
        font-size: 14px;
        color: @fontColor;
      }
    }
    .intro-left-description {
      margin-bottom: 20px;
    }
    .intro-left-video {
      display: block;
      margin-bottom: 16px;
      width: 602px;
      height: 338px;
      box-shadow: 0 0 8px 0 rgba(81, 114, 156, 0.12);
    }
    .intro-left-upgrade-vip {
      width: 240px;
      height: 40px;
      color: #6b3d1e;
      font-weight: bold;
      line-height: 40px;
      text-align: center;
      border-radius: 4px;
      background-image: linear-gradient(90deg, #fceee1 0%, #fcdac1 100%);
      cursor: pointer;
      &:hover {
        background-image: linear-gradient(270deg, #f3d3ba 0%, #f4e7d9 100%);
      }
    }
  }
  .intro-right {
    padding-left: 24px;
    .feature-title {
      color: @fontColor;
      font-size: 20px;
      font-weight: bold;
      line-height: 24px;
      margin-bottom: 20px;
    }
    .feature-list {
      height: 500px;
      min-width: 218px;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        opacity: 0;
      }
      &:hover {
        &::-webkit-scrollbar {
          opacity: 1;
        }
        .beautiful-scroll-bar();
      }
      &>.feature-item {
        .flex(flex-start);
        cursor: pointer;
        .feature-icon {
          width: 40px;
          height: 40px;
          margin-right: 8px;
          background-image: url('../img/vipPop_icon.svg');

          each(range(9), {
            &-@{value} {
              background-position-x: -40px * @value;
            }
          })
        }
        .title {
          font-size: 14px;
          color: @fontColor;
        }
        &:not(:last-child) {
          margin-bottom: 16px;
        }
        &.active .title {
          font-weight: bold;
        }
      }
    }
  }
}
</style>
