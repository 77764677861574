<template>
  <div class="flagship-vip-box">
            <div class="flagship-top">
              <p class="flagship-top-title">旗舰版定制授权：</p>
              <p class="flagship-top-item1">正版授权</p>
              <p class="flagship-top-item2">商用零售风险</p>
            </div>
            <div class="flagship-center">
              <div class="flagship-center-list-box">
                <div class="flagship-center-list-box-top">
                  <p class="title">大型企业授权模式</p>
                  <a class="desc" href='https://www.yuque.com/ckt-help/pgmhs0/dw8g9lgrqbtfa12c?singleDoc#' target='_blank' style='color: #8693AB; text-decoration-line: underline;'>中小微企业划分标准</a>
                </div>
                <ul>
                  <li>央企/国企事业单位</li>
                  <li>上市公司</li>
                  <li>外资企业 合资企业</li>
                  <li>集团公司 股份公司</li>
                  <li>大企业 事业部</li>
                  <li>连锁企业</li>
                </ul>
              </div>
              <div class="flagship-center-list-box">
                <div class="flagship-center-list-box-top">
                  <p class="title">专属行业授权</p>
                  <p class="desc">根据不同行业性质打包授权</p>
                </div>
                <ul>
                  <li>教育/培训</li>
                  <li>金融/保险 /证券</li>
                  <li>医疗/医美</li>              
                  <li>汽车行业</li>
                  <li>事业单位</li>
                  <li>更多</li>
                </ul>
              </div>
              <div class="flagship-center-list-box">
                <div class="flagship-center-list-box-top">
                  <p class="title">为甲方授权</p>
                  <p class="desc">不限授权主体灵活配置</p>
                </div>
                <ul>
                  <li>广告传媒 公司</li>
                  <li>代运营公司</li>
                  <li>MCN机构</li>              
                  <li>建站公司</li>
                  <li>独立博主</li>
                  <li>更多</li>
                </ul>
              </div>
            </div>
            <div class="flagship-bottom">
              <div class="flagship-bottom-left">
                <p class="title">使用流程：</p>
                <div class="list-box">
                  <div class="list-item-box">
                    <div class="list-item">
                      <div class="list-item-top flagship-flow1"></div>
                      <p  class="list-item-bottom">购买套餐</p>
                    </div>
                    <div class="list-arrow"></div>
                    <div class="list-item">
                      <div class="list-item-top flagship-flow2"></div>
                      <p  class="list-item-bottom">企业认证</p>
                    </div>
                    <div class="list-arrow"></div>
                    <div class="list-item">
                      <div class="list-item-top flagship-flow3"></div>
                      <p  class="list-item-bottom">下载设计</p>
                    </div>
                    <div class="list-arrow"></div>
                    <div class="list-item">
                      <div class="list-item-top flagship-flow4"></div>
                      <p  class="list-item-bottom">下载授权书</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flagship-bottom-right">
                <p class="title">咨询客服</p>
                <div class="flagship-bottom-right-content">
                  <div class="customer">
                    <img src="../img/qrcode.png" alt="">
                  </div>
                  <div class="right-box">
                    <p class="title">
                      1V1专属服务
                    </p>
                    <p class="desc">
                      专属客户经理为您提供定制咨询
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
  </template>
  
  <script>
  export default {
    data() {
      return {};
    },
    methods: {},
  };
  </script>
  <style lang='less' scoped>
.flagship-vip-box{
  padding-left: 32px ;
  width: 664px;
  p{
    margin: 0;
    padding: 0;
  }
  .flagship-top{
    display: flex;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: #1B2337;
    .flagship-top-item1{
      padding-left: 40px;
      font-weight: 400;
      position: relative;
      &::after {
        position: absolute;
        content: '';
        bottom: 0;
        left: 16px;
        height: 20px;
        width: 20px;
        background-size:cover;
        background-repeat: no-repeat;
        background-image:  url("../img/flagship-quanyi2.svg");
      }
    }
    .flagship-top-item2{
      padding-left: 40px;
      font-weight: 400;
      position: relative;
      &::after {
        position: absolute;
        content: '';
        bottom: 0;
        left: 16px;
        height: 20px;
        width: 20px;
        background-size:cover;
        background-repeat: no-repeat;
        background-image:  url("../img/flagship-quanyi1.svg");
      }
    }
  }
  .flagship-center{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    &-list-box{
      border-radius: 12px;
      border: 1px solid #EBEEF5;
      background-color: #FFF;
      padding: 12px 16px 16px 16px;
      width: 197px;
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      justify-content: center;
      &-top{
        padding-bottom: 8px;
        .title{
          font-weight: 600;
          color: #1B2337;
          line-height: 22px; /* 157.143% */
        }
        .desc{
          font-size: 12px;
          font-weight: 400;
          color: #8693AB;
          line-height: 20px; /* 166.667% */
        }
      }
      ul{
        display: flex;
        flex-wrap: wrap;
        li{
          margin-top: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 78px;
          height: 46px;
          font-size: 12px;
          background: #F5F7FD;
          padding: 0 8px;
          border-radius: 6px;
          color:#505A71;
          &:nth-child(2n){
            margin-left: 6px;
          }
        }
      }
    }
  }
  .flagship-bottom{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    .title{
      font-weight: 600;
      font-size: 14px;
      color: #1B2337;
      line-height: 22px;
    }
    &-left{
      .list-box{
        display: flex;

        margin-top: 13px;
        border-radius: 12px;
        background: #F5F7FD;
        width: 415px;
        height: 106px;
        .list-item-box{
          display: flex;
        }
        .list-arrow{
          width: 24px;
          height: 24px;
          margin-top: 24px;
          background-size:cover;
          background-repeat: no-repeat;
          background-image:  url("../img/arrow.svg");
        }
        .list-item{
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-content: center;
          .list-item-top{
            width: 44px;
            height: 44px;
            border-radius: 8px;
            background: #FFF;
            background-size:cover;
            background-repeat: no-repeat;
            &.flagship-flow1{
              background-image:  url("../img/flagship-flow1.svg");
            }
            &.flagship-flow2{
              background-image:  url("../img/flagship-flow2.svg");
            }
            &.flagship-flow3{
              background-image:  url("../img/flagship-flow3.svg");
            }
            &.flagship-flow4{
              background-image:  url("../img/flagship-flow4.svg");
            }
          }
          .list-item-bottom{
            margin-top: 12px;
            color: #505A71;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
          }
        }
      }
    }
    &-right-content{
      margin-top: 13px;
      display: flex;
      align-items: center;
      .customer{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 104px;
        height: 104px;
        background-size:cover;
        background-repeat: no-repeat;
        background-image:  url("../img/qrcode_bg.svg");
        img{
          width: 92px;
          height: 92px;
        }
      }
      .right-box{
        width: 80px;
        margin-left: 8px;
        .desc{
          margin-top: 4px;
          color: #8693AB;
          font-size: 12px;
          line-height: 20px; /* 166.667% */
        }
      }
    }
  }
}

  </style>
  