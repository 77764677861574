<template>
  <div class="success-pop" :class="level===201 ? 'person-success-pop' : 'company-success-pop'">
    <LtIcon
      type="close"
      size="16"
      class="close-icon"
      @click="$emit('on-cancel')"
    />
    <div class="top">
      <img src="../img/flowers.png" alt="" v-if="level !== 201">
      <p class="tip">支付成功</p>
      <p class="detail">恭喜您成为<span class="vip-name">{{choosePackageName}}</span> <span v-if="level === 201">，有效期至：{{afterBuyExpireDate}}</span> </p>
      <p  v-if="level !== 201">有效期至：{{afterBuyExpireDate}}</p>
    </div>

    <div class="qrcode-content" v-if="level ===201">
      <p class="guide" v-if="randState">扫码添加您的【设计顾问】</p>
      <p class="guide" v-else>恭喜您获得会员福利</p>
      <div class="content">
        <div class="qrcode-wrap">
          <img v-if="randState" src="../img/customerQR.png" alt="【官方客服】" class="qrcode"/>
          <img v-else src="../img/fuli.png" alt="会员福利" class="qrcode"/>
        </div>
        <ul v-if="randState">
          <li><p>高效售后服务</p> <p>快速解答您的所有问题</p></li>
          <li><p>专业设计咨询</p> <p>领取使用教程｜分享使用技巧</p></li>
        </ul>
        <ul v-else>
          <li><p>教程素材只送不卖</p> <p>0元领取多款笔刷/绘画素材/直播教学</p></li>
          <li><p>零基础学插画</p> <p>进群免费获得作业批改/学习指导/接单交流</p></li>
        </ul>
      </div>
    </div>

    <div class="bottom">
      <p class="title">两步享受尊贵特权</p>
      <div class="progress-box">
        <div class="progress">
          <div class="one-progress"><img src="../img/loading.svg" alt=""></div>
          <div class="crosswise"></div>
          <div class="two-progress">2</div>
        </div>
        <div class="progress-info">
          <p class="left">
            <span>完善授权信息</span>
            <span>保障商用无忧</span>
          </p>
          <p class="right" v-if="remainSeats>0">
            <span>还可邀请{{remainSeats}}位成员</span>
            <span>共享团队协作</span>
          </p>
          <p class="right" v-else>
            <span>去我的团队</span>
            <span>体验团队协作</span>
          </p>
        </div>

      </div>
      <div class="btn" @click="$emit('goSuccess')">
        去完善授权信息
      </div>
    </div>
  </div>
</template>
 
<script>
export default {
  name: "paySuccess",
  props:{
    afterBuyExpireDate: {
      type: String,
      default: '',
    },
    choosePackageName: {
      type: String,
      default: '',
    },
    level: {
      type: Number,
      default: 0,
    },
    userId: {
      type: Number,
      default: 0,
    },
  },
  data(){
    return {
      remainSeats: sessionStorage.getItem('remainSeats'),
      randState:true
    }
  },
  // mounted(){
  //   let spstr = this.userId.toString().split('');
  //   this.randState =Math.ceil(spstr[spstr.length-1])%2===0
  // }
};
</script>

<style lang="less" scoped>
.success-pop{
  position: relative;
  &.company-success-pop{
    width: 495px;
    height: 540px;
    background-size: 100% 100%;
    background-image: url('../img/pay-success.png');
    .top{
      width: 100%;
      position: absolute;
      top: 80px;
      color: #1B2337;
      font-family: PingFang SC;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      img{
        width: 80px;
      }
      p:not(:first-child){
        margin-top: 16px;
      }
      .tip{
        color: #1B2337;
        font-family: PingFang SC;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: center;
      }
      span{
        color: #0773FC;
        font-weight: bold;
      }
    }
    .bottom{
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translateX(-50%);
      .title{
        color: #0773FC;
        font-family: PingFang SC;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: center;
      }
      .progress-box{
        .progress{
          margin-top: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          .one-progress{
            background: #0773FC;
            width: 32px;
            height: 32px;
            border-radius: 50% 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
              animation: turn 2s linear infinite;
              width: 14px;
            }
          }
          .crosswise {
            margin: 0 12px;
            width: 239px;
            height: 0;
            background: linear-gradient(-90deg, #EBEEF5 0%, #0773FC 100%);
            background-origin: border-box;
            border-bottom: 2px dotted #FFFFFF;
          }
          .two-progress{
            width: 32px;
            height: 32px;
            border-radius: 50% 50%;
            background: #EBEEF5;
            border: 1px dashed #C5C8CE;
            color: #8693AB;
            font-family: PingFang SC;
            font-weight: bold;
            font-size: 16px;
            line-height: 32px;
            letter-spacing: 0px;
            text-align: center;
          }
        }
        .progress-info{
          margin: 0 auto;
          margin-top: 19px;
          width: 384px;
          display: flex;
          justify-content: space-between;
          p{
            display: flex;
            flex-direction: column;
            align-items: center;
            color: #1B2337;
            font-family: PingFang SC;
            font-weight: regular;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: 0px;
            span:last-child{
              color: #0773FC;
            }
          }
        }
      }
      @keyframes turn {
        0% {
            transform: rotate(0deg);
        }

        20% {
            transform: rotate(-72deg);
        }

        40% {
            transform: rotate(-144deg);
        }

        60% {
            transform: rotate(-216deg);
        }

        80% {
            transform: rotate(-288deg);
        }

        100% {
            transform: rotate(-360deg);
        }
      }
      .btn{
        cursor: pointer;
        margin: 3px auto;
        width: 146px;
        height: 40px;
        text-align: center;
        border-radius: 4px;
        background: #0773FC;
        color: #FFFFFF;
        font-family: PingFang SC;
        font-size: 14px;
        line-height: 40px;
        &:hover{
          background: #0667E2;
        }
      }
    }
  }
  &.person-success-pop{
    padding-top: 48px;
    box-sizing: border-box;
    width: 566px;
    height: 585px;
    .top{
      width: 100%;
      color: #1B2337;
      font-family: PingFang SC;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      img{
        width: 80px;
      }
      .tip{
        color: #1B2337;
        font-family: PingFang SC;
        font-weight: bold;
        font-size: 26px;
        line-height: 34px;
        letter-spacing: 0px;
        text-align: center;
      }
      .detail{
        margin-top: 8px;
        font-size: 16px;
        line-height: 24px;
        color: #505A71;
      }
      .vip-name{
        color: #0773FC;
        font-weight: bold;
      }
    }
    .bottom{
      .title{
        margin-top: 30px;
        color: #1B2337;
        font-family: PingFang SC;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: center;
      }
      .progress-box{
        .progress{
          margin-top: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          .one-progress{
            background: #0773FC;
            width: 32px;
            height: 32px;
            border-radius: 50% 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
              animation: turn 2s linear infinite;
              width: 14px;
            }
          }
          .crosswise {
            margin: 0 12px;
            width: 239px;
            height: 0;
            background: linear-gradient(-90deg, #EBEEF5 0%, #0773FC 100%);
            background-origin: border-box;
            border-bottom: 2px dotted #FFFFFF;
          }
          .two-progress{
            width: 32px;
            height: 32px;
            border-radius: 50% 50%;
            background: #EBEEF5;
            border: 1px dashed #C5C8CE;
            color: #8693AB;
            font-family: PingFang SC;
            font-weight: bold;
            font-size: 16px;
            line-height: 32px;
            letter-spacing: 0px;
            text-align: center;
          }
        }
        .progress-info{
          margin: 0 auto;
          margin-top: 19px;
          width: 384px;
          display: flex;
          justify-content: space-between;
          p{
            display: flex;
            flex-direction: column;
            align-items: center;
            color: #1B2337;
            font-family: PingFang SC;
            font-weight: regular;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: 0px;
            span:last-child{
              color: #0773FC;
            }
          }
        }
      }
      @keyframes turn {
        0% {
            transform: rotate(0deg);
        }

        20% {
            transform: rotate(-72deg);
        }

        40% {
            transform: rotate(-144deg);
        }

        60% {
            transform: rotate(-216deg);
        }

        80% {
            transform: rotate(-288deg);
        }

        100% {
            transform: rotate(-360deg);
        }
      }
      .btn{
        cursor: pointer;
        margin: 6px auto;
        width: 146px;
        height: 40px;
        text-align: center;
        border-radius: 4px;
        background: #0773FC;
        color: #FFFFFF;
        font-family: PingFang SC;
        font-size: 14px;
        line-height: 40px;
        &:hover{
          background: #0667E2;
        }
      }
    }
  }
  .close-icon{
    cursor: pointer;
    position: absolute;
    right: 26px;
    top: 25px;
  }
  .qrcode-content{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    .qrcode-wrap{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 116px;
      height: 116px;
      border: 1px solid #EBEEF5;
      border-radius: 6px;
    }
    .qrcode{
      width: 100px;
    }
    .title{
      margin-top: 16px;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: #1B2337;
    }
    .guide{
      font-weight: bold;
      color: #1B2337;
      font-size: 16px;
      margin-top: 24px;
    }
    .content{
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 26px;
      padding-bottom:30px;
      margin-top: 16px;
      border-bottom: 1px solid #F1F3F9;
      ul{
        margin-left: 50px;
        p{
          font-size: 16px;
          line-height: 24px;
          &:first-child{
            font-weight: bold;
            color: #1B2337;
          }
          &:last-child{
            font-size: 14px;
            color: #505A71;
          }
        }
        li{
          position: relative;
          &:before{
              content: '';
              left: -14px;
              top: 8px;
              position: absolute;
              width: 6px;
              height: 6px;
              border-radius: 3px 3px;
              background: #0773FC;
          }
          &:last-child{
            margin-top: 12px;
          }
        }
      }
    }
   }
}
</style>
