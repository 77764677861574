var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vip-pop-pay-box"},[_c('div',{staticClass:"vip-pop-pay-content-packages"},[((_vm.vipPackages.length > 4) && _vm.rightBtnShow)?_c('div',{staticClass:"arrow-btn right-btn",on:{"click":_vm.toright}}):_vm._e(),((_vm.vipPackages.length >  4) && _vm.leftBtnShow)?_c('div',{staticClass:"arrow-btn left-btn",on:{"click":_vm.toleft}}):_vm._e(),_c('ul',{staticClass:"package-list",attrs:{"id":"package-list"}},_vm._l((_vm.vipPackages),function(item){return _c('li',{key:item.id,staticClass:"package-cell",class:{
          'package-item-personal' : item.level !== 205,
          'package-item-company' : item.level === 205,
          'half-price': item.deadline > 0,
          active: _vm.selectedPackage.id === item.id,
          forbidden: !_vm.app.availableLevelList.includes(item.level)
        },style:({ '--count': _vm.vipPackages.length }),attrs:{"id":'package-' + item.id},on:{"click":function($event){return _vm.$emit('on-select', item)}}},[[_c('div',{staticClass:"vip-package-top"},[_c('div',{staticClass:"left"},[_c('p',{staticClass:"duration"},[_vm._v(" "+_vm._s(item.showName|| item.timeName)+" ")])]),_c('div',{staticClass:"right"},[(_vm.getSaleDescription(item.saleDescription))?_c('span',{staticClass:"vip-label"},[_vm._v(" "+_vm._s(_vm.getSaleDescription(item.saleDescription))+" ")]):_vm._e(),_c('p',{staticClass:"discount-price"},[_vm._v(" ￥"),_c('b',[_vm._v(_vm._s(_vm.packageType === 1? item.discountUnitPrice : item.discountPrice / 100))]),_c('span',[_vm._v(_vm._s(_vm.packageType===1?'/人':''))])]),(item.discountPrice !== item.initPrice)?_c('s',{staticClass:"init-price"},[_vm._v(" 原价 ¥ "+_vm._s(_vm.packageType === 1 ? item.initUnitPrice : item.initPrice / 100)+_vm._s(_vm.packageType===1?'/人':'元')+" ")]):_vm._e()])]),(item.deadline>0)?_c('div',{staticClass:"vip-label-bottom timer"},[_c('span',{staticStyle:{"margin-right":"8px"}},[_vm._v("福利剩余")]),_c('span',[_vm._v(_vm._s(_vm.timeText))])]):_c('p',{staticClass:"vip-label-bottom"},[_vm._v(" "+_vm._s(_vm.getSaleDescription(item.saleDescription,1))+" ")])]],2)}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }