<template>
    <div class="service_Modal" v-if="enterpriceServiceState">
        <ul class="modal_list">
            <li class="modal_list_item" v-for="item in enterServiceList" :key="item.id" @click="openPage(item)">
                <div class="modal_left">
                    <img :src="item.img" class=""/>
                </div>
                <div class="modal_right">
                    <span>{{ item.title }}</span>
                    <span>{{ item.desc }}</span>
                </div>
                <span class="arrow-name"></span>
            </li>
        </ul>
        <div class="modal_bottom"  @click="enterPrise">已有企业SaaS版账号？ <span class="enterPrise_enter">点此进入>></span></div>
    </div>
  </template>
  
  <script>

  export default {
    name: 'EnterpriceServiceComponent',
    components: {
    },
    props:{
        enterpriceServiceState: {
            type: Boolean,
            default: false,
        },
    },
    data () {
      return {
        enterServiceList:[
            {
                img:require('./img/service1.png'),
                title:'API开放平台',
                desc:'企业全端接入，易于集成，即刻拥有智能设计能力',
                href:'/terms/introduce',
                id:1
            },
            {
                img:require('./img/service2.png'),
                title:'内容中台',
                desc:'企业营销内容流转，全链路解决方案',
                href:'/contech',
                id:2
            },
            {
                img:require('./img/service3.png'),
                title:'渠道合作',
                desc:'创客贴渠道代理/分销商招募，携手共赢亿级红利',
                href:'/activity/channelAgentPage',
                id:3
            }
        ]
      }
    },
    methods: {
        openPage(item){
            window.open(item.href);
        },
        enterPrise(){
            this.$emit('enetrExtraInfo')
        }
    },
  }
  </script>
  
  <style lang='less' scoped>
  .service_Modal {
    display: flex;
    width: 304px;
    padding: 12px;
    position: absolute;
    top: 47px;
    right: 0;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 6px 24px 4px rgba(27, 35, 55, 0.08);
    .modal_list{
        padding-bottom: 12px;
        .modal_list_item{
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 8px;
            position: relative;
            cursor: pointer;
            .modal_left{
                margin-right: 12px;
                img{
                    width: 56px;
                    height: 56px;
                }
            }
            .modal_right{
                display: flex;
                flex-direction: column;
                span:first-child{
                    color: var(--1, #1B2337);
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 22px;
                }
                span:last-child{
                    color: var(--3, #8693AB);
                    font-size: 12px;
                    line-height: 16px;
                    margin-top: 2px;
                }
            }
            .arrow-name{
                width: 20px;
                height: 20px;
                position: absolute;
                top:8px;
                right: 18px;
                background: url(./img/arrow_icon.svg);
                visibility: hidden;
            }
            &:hover{
                border-radius: 8px;
                background: var(--9, #F5F7FD);
                .modal_right{
                    span:first-child{
                        color: var(--unnamed, #0773FC);
                    }
                }
                .arrow-name{
                    visibility: visible;
                    transition: right .3s, opacity .3s;
                    right: 8px;
                }
            }
        }
        .modal_list_item:first-child{
            margin-bottom: 8px;
        }     
    }
    .modal_bottom{
        color: var(--3, #8693AB);
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        cursor: pointer;
        .enterPrise_enter{
            color: var(--unnamed, #0773FC);
            font-weight: 400;
            cursor: pointer;
        }
    }
    
  }
  </style>