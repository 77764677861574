<template>
  <div class="qrcode-container">
    <canvas :qrcodeurl="qrcodeurl" ref="qr"></canvas>
  </div>
</template>

<script>
import QRCode from 'qrcode'

export default {
  name: "Qrcode",
  props: {
    qrcodeurl: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      default: 100,
    },
  },
  beforeUpdate() {
    this.update();
  },
  mounted() {
    this.update();
  },
  methods: {
    update() {
      QRCode.toCanvas(this.$refs.qr, this.qrcodeurl, {
        width: this.size, margin: 0
      }, (err) => {
        if (err) {
          // Error
        }
        // Success
      })
    }
  },
};
</script>
