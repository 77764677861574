<template>
  <section class="coupon-modal">
    <header>
      <div class="tabs">
        <span
          v-for="item in tabs"
          :class="{'tab--actived': selectedTab === item.type}"
          :key="item.type"
          @click="selectedTab = item.type"
        >{{item.title}}</span>
      </div>
      <lt-icon
        type="close"
        class="close-icon"
        @click="$emit('close-pop')"
      ></lt-icon>
    </header>

    <section class="coupon-list__wrapper" v-show="selectedTab === 0">
      <lt-scroll
        :barStyle="{wrapWidth: '448px', wrapHeight: '266px', autoHide: false}"
        :scrollReloadTag="scrollReloadTag"
      >
        <ul class="coupon-list__content">
          <li v-for="item in showList"
              :class="['coupon-list__item', {'coupon-list__item--cant': item.thresholdAmount > price}]"
              :key="item.unionCode"
              @click="handleCouponClick(item)"
          >
            <div class="coupon-item__info">
              <p class="coupon-item__amount">
                ￥<span class="coupon-item__amount-number">{{item.discountAmount / 100}}</span>
              </p>
              <p class="coupon-item__use-limit">
                <span class="coupon-item__amount-threshold">满{{item.thresholdAmount / 100}}可用</span>
                <span class="coupon-item__valid-time">{{getDate(item.validBeginTime)}}-{{getDate(item.validEndTime)}}</span>
              </p>
            </div>
          </li>
        </ul>

        <section class="no-content" v-if="!showList.length">
          <img src="./img/price_pay_blank.png" title="无优惠券">
          <p>你暂时无可用优惠券</p>
        </section>
      </lt-scroll>
    </section>

    <section class="coupon-code__wrapper" v-show="selectedTab === 1">
      <section class="coupon-input-container">
        <span class="label">输入优惠码:</span>
        <LtInput
          v-model="inputDiscountCode"
          :autofocus="true"  
          placeholder="输入优惠码" 
          class="code-input" 
        />
        <!-- <a class="get-way" href="http://edu.chuangkit.com/forum.php?mod=viewthread&tid=809&extra=" target="_blank">如何获取优惠码？</a> -->
      </section>
      <div class="coupon-btn">
        <button @click="useDiscountCode" class="primary-btn" :class="{'btn--active': canClick}">确定</button>
        <button @click="$emit('close-pop')" class="default-btn">取消</button>
      </div>
    </section>
  </section>
</template>

<script>
  export default {
    name: 'couponModal',
    props: {
      // 优惠类型 0券 1码
      type: {
        type: Number,
        default: 0
      },
      tpi: Number, // 会员套餐 ID
      level: Number, // 会员类型
      price: Number // 套餐价格
    },
    data () {
      return {
        selectedTab: 1,
        tabs: [
          {
            title: '优惠券',
            type: 0
          },
          {
            title: '优惠码',
            type: 1
          }
        ],
        scrollReloadTag: Date.now(),
        showList: [],
        inputDiscountCode: '',
      }
    },
    computed: {
      canClick () {
        return this.inputDiscountCode.trim().length > 0
      }
    },
    watch: {
      type (v) {
        this.selectedTab = v || 0
      },
      selectedTab(v) {
        if (v === 0) {
          this.getCouponListByUserId({})
        }
      },
    },
    created () {
      this.selectedTab = this.type || 0
      if (this.selectedTab === 0) {
        this.getCouponListByUserId({autoToggle: true})
      }
    },
    methods: {
      // 获取用户优惠券列表
      getCouponListByUserId ({autoToggle}) {
        let data = {
          gw: true,
          GW: true,
          scenario_type: 0, // -1全部；0会员；1印刷
          type: 0 // 0未使用；1已使用；2已过期；3全部
        }
        this.$http.post('/vip/coupon/getCouponListByUserId', data).then(res => {
          // 状态码:-1参数错误；-2未登录；200获取成功
          let code = res.body.code
          switch (code) {
            case 200:
              this.showList = res.body.data.filter(e => {
                if (e.usageScenarioType === 0) {
                  let now = new Date().getTime()
                  if (e.validBeginTime <= now && e.validEndTime >= now) {
                    // https://coding.net/u/yunheng/p/chuangkit/attachment/3949599/preview/3949724
                    // 只需要判断versionOrPaper和traffOrCraft，两个都为空表示优惠券是通券，否则优先判断traffOrCraft，如果traffOrCraft不为空，
                    // 则判断会员套餐的tariffPackagesId是否在traffOrCraft中包含，如果包含，则该套餐可用优惠券，如果不包含再判断versionOrPaper里是否包含套餐的id
                    if (e.versionOrPaper.length === 0 && e.traffOrCraft.length === 0) {
                      return e
                    } else {
                      if (e.traffOrCraft.indexOf(this.tpi) > -1) {
                        return e
                      } else {
                        if (e.versionOrPaper.indexOf(this.level) > -1) {
                          return e
                        }
                      }
                    }
                  }
                }
              })
              // 可用 > 不可用；
              // 领取/获取时间由新到旧。
              let canUse = [] // 可用
              let cantUse = [] // 不可用
              this.showList.forEach( e => {
                if (e.thresholdAmount > this.price) {
                  cantUse.push(e)
                } else {
                  canUse.push(e)
                }
              })
              canUse.sort((a,b) => {
                return b.createTime - a.createTime
              })
              cantUse.sort((a,b) => {
                return b.createTime - a.createTime
              })
              this.showList = [...canUse, ...cantUse]

              if (autoToggle) {
                if (this.showList.length === 0) {
                  this.selectedTab = 1
                }
              }
              this.$nextTick(() => {
                this.scrollReloadTag = Date.now()
              })
              break
            case -2:
              this.$message('请先登录')
              this.$emit('login')
              break
            case -1:
              this.$message('参数错误')
              break
            default:
              this.$message(`获取优惠券列表失败，错误信息：code-${code} msg-${res.body.msg}`)
          }
        })
      },
      useDiscountCode () {
        if (!this.canClick) return
        // 获取优惠券信息
        this.$http.post('/vip/coupon/getCouponInfo', { c_code: this.inputDiscountCode, gw:true, GW:true}).then(res => {
          let code = res.body.code
          switch (code) {
            case 1:
              if (res.body.qcb) {
                let info = res.body.qcb
                let now = new Date().getTime()
                if (info.isUse === 1) {
                  this.$message('优惠码已被使用')
                } else if (now <= info.startTime.time) {
                  this.$message('未到优惠码使用开始时间')
                } else if (now >= info.endTime.time) {
                  this.$message('优惠码已过期')
                } else {
                  if (this.price < info.bottomAmount) {
                    this.$message('该优惠码须满' + info.bottomAmount / 100 + '元使用')
                  } else {
                    let couponInfo = {
                      couponType: 1,
                      unionCode: info.couponCode,
                      discountAmount: info.amount,
                      bottomAmount: info.bottomAmount
                    }
                    this.$message(`成功使用${couponInfo.discountAmount / 100}元优惠码`)
                    this.$emit('get-coupon-info', couponInfo)
                  }
                }
              }
              break
            case -1:
              this.$message('优惠码无效')
              break
            case -2:
              this.$emit('login')
              break
            case -3:
              this.$message('该活动只能参加一次')
              break
            case -4:
              this.$message('当前非活动有效期')
              break
            case -5:
              this.$message('当天输入错误次数过多')
              break
          }
        })
      },
      handleCouponClick (coupon) {
        if (coupon.thresholdAmount > this.price) {
          // 不满足使用条件
          this.$message('该优惠券须满' + coupon.thresholdAmount / 100 + '元使用')
          return
        }
        this.$message(`成功使用${coupon.discountAmount / 100}元优惠券`)
        let info = coupon
        info.bottomAmount = info.thresholdAmount
        info.couponType = 0
        this.$emit('get-coupon-info', info)
      },
      getDate (time) {
        return new Date(time).toLocaleDateString().replace(/\//g, '.')
      }
    }
  }
</script>

<style lang="less" scoped>
  @import "couponModal.less";
</style>
