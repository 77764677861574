var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vip-pop-pay-sider"},[_c('div',{},[_c('div',{staticClass:"right-title"},[_c('p',{staticClass:"version-name",class:{
            'personal-icon' : _vm.app.packageType === 0,
            'company-icon' : _vm.app.packageType === 1,
            'flagship-icon': _vm.app.packageType === 2
          }},[_vm._v(_vm._s(_vm.vipVersionName.versionName))]),_c('p',{staticClass:"version-desc",domProps:{"innerHTML":_vm._s(_vm.vipVersionName.desc)}})]),_c('ul',{staticClass:"buy-feature-group"},[_vm._l((_vm.featureList),function(item,index){return [_c('li',{key:index,staticClass:"buy-feature-item",class:{
          'company-color': _vm.app.packageType === 1,
          'flagship-color': _vm.app.packageType === 2,
          'ai-right-list': item === 'AI创作100贴贴/席位/月'|| item === 'AI创作100贴贴/月',
        }},[(typeof item === 'function')?_c('span',{domProps:{"innerHTML":_vm._s(item())}}):_c('span',[_vm._v(_vm._s(item))])])]})],2)]),_c('a',{staticClass:"see-more",attrs:{"href":_vm.vipPageUrl,"target":"_blank"}},[_vm._v("查看会员权益对比 >")])])
}
var staticRenderFns = []

export { render, staticRenderFns }