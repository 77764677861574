<template>
  <div class="vip-pop-pay-box">
    <div class="vip-pop-pay-content-packages">
      <!-- 移动按钮 -->
      <div class="arrow-btn right-btn" @click="toright" v-if="(vipPackages.length > 4) && rightBtnShow" ></div>
      <div class="arrow-btn left-btn" @click="toleft" v-if="(vipPackages.length >  4) && leftBtnShow"></div>
      <!-- 全套餐 -->
      <ul class="package-list" id="package-list" >
        <li
          v-for="item in vipPackages"
          :key="item.id"
          :id="'package-' + item.id"
          class="package-cell"
          :class="{
            'package-item-personal' : item.level !== 205,
            'package-item-company' : item.level === 205,
            'half-price': item.deadline > 0,
            active: selectedPackage.id === item.id,
            forbidden: !app.availableLevelList.includes(item.level)
          }"
          :style="{ '--count': vipPackages.length }"
          @click="$emit('on-select', item)"
        >
          <!-- 个人VIP -->
          <template>
            <div class="vip-package-top">
              <div class="left">
                <!-- 套餐名称 -->
                <p class="duration">
                  {{ item.showName|| item.timeName }}
                </p>
              </div>
              <div class="right">
                <!-- 右上角运营位 -->
                <span class="vip-label" v-if='getSaleDescription(item.saleDescription)'>
                  {{getSaleDescription(item.saleDescription)}}
                </span>
                <!-- 现价 -->
                <p class="discount-price">
                  ￥<b>{{ packageType === 1? item.discountUnitPrice : item.discountPrice / 100}}</b>
                  <span>{{packageType===1?'/人':''}}</span>
                </p>
                <!-- 原价 -->
                <s v-if="item.discountPrice !== item.initPrice" class="init-price">
                  原价 ¥ {{ packageType === 1 ? item.initUnitPrice : item.initPrice / 100}}{{packageType===1?'/人':'元'}}
                </s>
              </div>
            </div>
            <div class="vip-label-bottom timer" v-if="item.deadline>0">
              <span style="margin-right: 8px;">福利剩余</span>
              <span>{{timeText}}</span>
            </div>
            <p class="vip-label-bottom" v-else>
               {{getSaleDescription(item.saleDescription,1)}}
            </p>
  
          </template>
        </li>
      </ul>
    </div>
    
  </div>
  </template>
  
  <script>
  export default {
    name: "PackageDisplay",
    inject: ["app"],
    props: {
      vipPackages: {
        type: Array,
        required: true,
      },
      selectedPackage: {
        type: Object,
        required: true,
      },
      packageType: {
        type: Number,
        default: 0,
      },
    },
    data() {
      return {
        scrollLeft: 0,
        step: 166,
        leftBtnShow: false,
        rightBtnShow: true,
        timeText:'',
        interval:null,
      };
    },
    watch:{
    'selectedPackage.id': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$nextTick(()=>{
            //滚动到选中的套餐的位置
            this.scrollToSelectedPackage()
          })
        }
      }
    },
    },
    mounted(){
      //列表中存在特价套餐，开始倒计时
      const halfPricePackArr = this.vipPackages.filter(item=>item.deadline>0)
      if(halfPricePackArr.length>0){
        const duration = halfPricePackArr[0].deadline
        this.changeClockNum(duration)
        this.startCountdown(duration)
      }
      
      this.scrollToSelectedPackage();
      var scroll = document.getElementById("package-list");
      scroll.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy(){
      clearInterval(this.interval)
    },
    methods: {
      changeClockNum(duration){
        let hours = parseInt((duration / (1000 * 60 * 60)) % 24);
        let minutes = parseInt((duration / (1000 * 60)) % 60);
        let seconds = parseInt((duration / 1000) % 60);

        hours = hours < 10 ? "0" + hours : hours;
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        this.timeText = hours + ":" + minutes + ":" + seconds;
        console.log('duration',duration)
      },
      startCountdown(duration){
        const interval = setInterval(() => {
          if ((duration -= 1000) < 0) {
              clearInterval(interval);
              //倒计时结束 重新请求列表
              this.$emit('on-refresh')
              //滚动到头部
              this.scrollToSelectedPackage()
          }else{
            this.changeClockNum(duration)
          }
        }, 1000);
        this.interval = interval
      },
      scrollToSelectedPackage() {
        setTimeout(() => {
          if (this.selectedPackage && this.selectedPackage.id) {
            const selectedElement = document.getElementById('package-' + this.selectedPackage.id);
            //如果该元素不在可视区域内，滚动到该元素
            const offsetLeft = selectedElement.offsetLeft;
            if (selectedElement && offsetLeft > 600) {
              selectedElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
            }
          }
        }, 100);
      },
      handleScroll(){
        let scroll = document.getElementById("package-list");
        if (scroll.scrollLeft === 0 ){
          this.leftBtnShow = false
        } else {
          this.leftBtnShow = true
        }
        console.log((scroll.scrollLeft + 632),(161 * this.vipPackages.length))
        if ((scroll.scrollLeft + 632) < (161 * this.vipPackages.length-15)){
          this.rightBtnShow = true
        } else {
          this.rightBtnShow = false
        }
      },
      toright() {
        let outDiv = document.getElementById("package-list");
        outDiv.scrollLeft += 632;
        outDiv.addEventListener('scroll', this.handleScroll)
      },
      toleft() {
        let outDiv = document.getElementById("package-list");
        outDiv.scrollLeft -= 632;
        outDiv.addEventListener('scroll', this.handleScroll)
      },
      // 获取运营位文案
      getSaleDescription(data, index = 0) {
        if (!data) return;
        const saleArr = data.trim().split("^");
        return saleArr[index];
      },
    },
  };
  </script>
  
  <style lang="less" scoped>
  @import url("../common/common.less");
  @count: var(--count);
  .vip-pop-pay-content-packages {
    position: relative;
    .package-list {
      scroll-behavior: smooth;
      .flex(flex-start);
      width: 100%;
      padding-top: 12px;
      overflow-x: scroll;
      &::-webkit-scrollbar { width: 0; height: 0; color: transparent; }
      li {
        .flex(space-between, center, column);
        width: 146px;
        background: #fff;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        color: @fontColor;
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 15px;
        }
      }
      .package-cell{
        .vip-package-top{
          width: 146px;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          position: relative;
          .vip-label {
            position: absolute;
            top: -12px;
            right: -2px;
            padding: 0 8px;
            height: 24px;
            background: linear-gradient(90deg, #FF6464 2.95%, #FA2323 99.68%);
            border-radius: 12px 8px 8px 4px;
            font-size: 12px;
            color: #fff;
            text-align: center;
            line-height: 24px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 100%;
          }
          .left{
            padding: 16px 0 12px 0;
            .flex(space-between, center, column);
            .time{
              color: #505A71;
              font-size: 14px;
              line-height: 22px;
            }
          }
          .right{
            .flex(center, center, column);
          };
        }
        .duration {
          width: 146px;
          overflow: hidden;
          white-space: nowrap;
          font-size: 16px;
          line-height: 24px;
          font-weight: bold;
          text-align: center;
        }
        .discount-price {
          .flex(center, flex-end);
          font-size: 14px;
          line-height: 25px;
          b {
            font-size: 24px;
            margin: 0 2px;
            line-height: 32px;
          }
          span{
            color: #1B2337;
            font-size: 14px;
          }
        }
        .init-price {
          font-size: 12px;
          color: rgba(27, 35, 55, 0.4);
          line-height: 20px;
          font-weight: 400;
          margin-top: 4px;
        }
        .vip-label-bottom {
          overflow: hidden;
          text-overflow: ellipsis;
          flex-shrink: 0;
          white-space: nowrap;
          border-radius: 0 0 10px 10px;
          width: 100%;
          height: 24px;
          font-size: 12px;
          line-height: 24px;
          font-weight: 400;
          text-align: center;
        }
        .vip-lable-tip {
          line-height: 24px;
          font-size: 12px;
          color: rgba(27, 35, 55, 0.4);
          .brighten{
            color: #FF4800;
          }
        }
      }
      .package-item-personal{
        height: 150px;
        border: 1px solid #00000019;
        &.half-price{
          background: #FFFBFB;
        }
        &:hover{
          border-color: #0773FC;
        }
        .discount-price b{
          color: #1B2337;
        }
        .vip-label-bottom{
          background: #F3F4F9;
          color: #505A71;
          &.timer{
            background: #FEE9E9;
            color: #FA2323;
          }
        }
        &.active{
          background: #F5FAFF;
          border: 2px solid #0773FC;
          &.half-price{
            background: #FFFBFB;
          }
          .vip-label-bottom{
            background: #0773FC;
            color: #FFFFFF;
            &.timer{
              background: #FEE9E9;
              color: #FA2323;
            }
          }
        }
      }
      .package-item-company{
        height: 188px;
        border: 1px solid #00000019;
        &.half-price{
          background: #FFFBFB;
        }
        &:hover{
          border-color: #FFC9A0;
        }
        .duration{
          color: #1B2337;
          b{
            color: #1B2337;
          }
        }
        .discount-price{
          color: #1B2337;
          span{
            font-size: 12px;
            margin-left: 4px;
          }
        }
        .vip-label-bottom{
          background: #F3F4F9;
          color: #505A71;
          &.timer{
            background: #FEE9E9;
            color: #FA2323;
          }
        }
        &.active{
          background:#FFFAF6;
          border: 2px solid #FEBF93;
          &.half-price{
            background: #FFFBFB;
          }
          .vip-label-bottom{
            background: #FFC9A0;
            color: #1B2337FF;
            &.timer{
              background: #FEE9E9;
              color: #FA2323;
            }
          }
        }
      }
      .forbidden{
        opacity: 0.6;
      }
    }
    .arrow-btn{
      cursor: pointer;
      position: absolute;
      z-index: 10;
      top: 50%;
      margin-top: -30px;
      width: 64px;
      height: 64px;
      background-image: url("../img/right_btn.svg");
      background-size: contain;
      background-repeat: no-repeat;
    }
    .right-btn{
      right: -28px;
    }
    .left-btn{
      left: -30px;
      transform: scaleX(-1);
    }
  }
  </style>
  