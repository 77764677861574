import Tracker from "@tracker-sdk/web";

const sleep = (t) => new Promise((resolve) => setTimeout(resolve, t));

class withTracker extends Tracker {
  isLogin = false;

  constructor(prop) {
    super(prop);
  }

  setUserInfo(userInfo) {
    if (!userInfo) return;
    const baseHeader = {
      user_id: userInfo.userId,
      user_vip: userInfo.vipLevelName,
      user_vip_level: userInfo.vipLevel,
    };
    this.setBaseParams(baseHeader);
  }

  setTeamInfo(teamInfo) {
    if (!teamInfo) return;
    const vipNameObj = {
      0: "普通用户",
      1: "企业VIP-基础版",
      2: "协作会员",
      3: "旗舰版",
      4: "个人版",
      5: "企业VIP-标准团队版",
      6: "企业专业版VIP",
      7: "企业VIP-加强版",
      8: "企业VIP-基础团队版",
      9: "企业VIP-全用途版",
      100: "基础版VIP",
      105: "全用途版VIP",
      110: "标准团队版VIP",
      115: "高级团队版VIP",
      120: "企业专业版",
      125: "新媒体商户版",
      130: "全用途商户版",
      135: "团队协作版",
      199: "标准版（个人）",
      200: "标准版（企业）",
      211: "通用版（个人）",
      212: "通用版（企业）",
      201: "个人高级版",
      202: "高级版",
    };
    const baseHeader = {
      team_id: teamInfo.teamId,
      team_vip: vipNameObj[teamInfo.adminUserVipLevel],
      team_name: decodeURI(teamInfo.teamName),
      team_vip_level: teamInfo.teamVipLevel,
    };
    this.setBaseParams(baseHeader);
  }

  setAdInfo() {
    let event_track_obj = {
      ocpc_flag: "",
      ocpc_source: "",
      ocpc_time: "",
      utm_source: "",
      utm_medium: "",
      utm_campaign: "",
      utm_term: "",
      utm_content: "",
    };
    try {
      const ckt_ocpc = localStorage.getItem("ckt_ocpc") || ""; //360 百度 ocpc
      const ckt_ocpc_url = localStorage.getItem("ckt_ocpc_url") || ""; //360 百度 ocpc
      const ckt_ocpc_time = localStorage.getItem("ckt_ocpc_time") || ""; // ckt_ocpc_time
      const ckt_ocpc_utmState = localStorage.getItem("ckt_utm_source") || ""; // ckt_ocpc_time
      const reg_utm_source = new RegExp("(^|/?|&)utm_source=([^&]*)(&|$)", "i");
      const reg_utm_medium = new RegExp("(^|/?|&)utm_medium=([^&]*)(&|$)", "i");
      const reg_utm_campaign = new RegExp(
        "(^|/?|&)utm_campaign=([^&]*)(&|$)",
        "i"
      );
      const reg_utm_term = new RegExp("(^|/?|&)utm_term=([^&]*)(&|$)", "i");
      const reg_utm_content = new RegExp(
        "(^|/?|&)utm_content=([^&]*)(&|$)",
        "i"
      );
      const search_ocpc_url = ckt_ocpc_url;
      let params_utm_source = "";
      let params_utm_medium = "";
      let params_utm_term = "";
      let params_utm_campaign = "";
      let params_utm_content = "";
      search_ocpc_url &&
      search_ocpc_url.match(reg_utm_source) &&
      (params_utm_source = search_ocpc_url.match(reg_utm_source)[2]);
      search_ocpc_url &&
      search_ocpc_url.match(reg_utm_medium) &&
      (params_utm_medium = search_ocpc_url.match(reg_utm_medium)[2]);
      search_ocpc_url &&
      search_ocpc_url.match(reg_utm_campaign) &&
      (params_utm_campaign = search_ocpc_url.match(reg_utm_campaign)[2]);
      search_ocpc_url &&
      search_ocpc_url.match(reg_utm_term) &&
      (params_utm_term = search_ocpc_url.match(reg_utm_term)[2]);
      search_ocpc_url &&
      search_ocpc_url.match(reg_utm_content) &&
      (params_utm_content = search_ocpc_url.match(reg_utm_content)[2]);
      event_track_obj.utm_source = decodeURI(params_utm_source);
      event_track_obj.utm_medium = decodeURI(params_utm_medium);
      event_track_obj.utm_campaign = decodeURI(params_utm_campaign);
      event_track_obj.utm_term = decodeURI(params_utm_term);
      event_track_obj.utm_content = decodeURI(params_utm_content);
      event_track_obj.ocpc_time = ckt_ocpc_time + "";
      if (ckt_ocpc_utmState) {
        event_track_obj.ocpc_flag = "empty";
        event_track_obj.ocpc_source = event_track_obj.utm_source;
      }
      if (ckt_ocpc && ckt_ocpc.indexOf("bd_vid=") > -1) {
        let params = "";
        const reg = new RegExp("(^|/?|&)bd_vid=([^&]*)(&|$)", "i");
        const search = ckt_ocpc.substr(1);
        search && (params = search.match(reg)[2]);
        event_track_obj.ocpc_flag = params;
        event_track_obj.ocpc_source = "百度ocpc";
      }
      if (ckt_ocpc && ckt_ocpc.indexOf("bd_vid=") === -1) {
        event_track_obj.ocpc_source = "360ocpc";
        event_track_obj.ocpc_flag = ckt_ocpc;
      }
    } catch (err) {
      console.error(err);
    }
    this.setBaseParams(event_track_obj);
  }

  setIsLogin(isLogin) {
    this.isLogin = isLogin;
    if (!isLogin) {
      this.clearUserAddTeam();
    }
  }

  clearUserAddTeam() {
    const baseHeader = {
      user_id: "0",
      user_vip: "emp",
      user_vip_level: "0",
      team_id: "0",
      team_name: "emp",
      team_vip: "emp",
      team_vip_level: "0",
    };
    this.setBaseParams(baseHeader);
  }

  async pageName() {
    await sleep(1000);
    const {pathname, href} = location;
    const nameMap = {
      "/price/vip": '会员页',
      "/difference": '会员补差价升级页',
      "/koutu": "一键抠图介绍页",
      "/picture": "图片编辑介绍页",
      "/terms/introduce": "开放平台介绍页",
      "/screen-marketing": "视频营销",
      "/video-cooperation": "视频协作介绍页",
      "/clientpage": "客户端下载页",
      "/designerecruit": "签约设计师",
      "/logreg": "登录页",
    };
    return nameMap[pathname] || "未定义";
  }

  withReport(event) {
    this.setAdInfo();
    this.report({
      event: "active",
      event_name: "用户活跃",
      datetime: Date.now(),
      trigger_timing: "用于统计活跃用户，所有用户可以直接访问的页面及项目均需上报",
      params: event
    });
  }

  loginReport(data) {
    this.setAdInfo()
    this.report(data)
  }
  
  async urlChange() {
    const pageName = await this.pageName();
    this.withReport({
      page_name: pageName,
      page_title: document.title,
      page_url: location.href,
      is_login: this.isLogin,
    });
  }
}

const tracker = new withTracker({
  baseParams: {
    business_lines: "1",
    product_name: "1",
    product_model_name: "1",
    platform: "1",
  },
  alias: "base",
  env: workEnv,
});

export default tracker;
