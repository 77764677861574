<template>
  <div>
    <LtModal
      class="vip-pop-modal"
      v-model="vipPopShow"
      :footer-hide="true"
      width="auto"
      @on-cancel="onVisibleChange"
    >
      <!-- 权益介绍页 -->
      <rights-intro
        v-if="!payShow"
        :feature-index="activeFeatureIndex"
        @upgrade-vip="upgradeVip"
      />
      <!-- 购买 -->
      <div v-else>
        <!-- 切换Tab -->
        <div 
           class="vip-tab"             
          :class="{
              vipTabCompany: packageType === 1,
              vipTabPerson: packageType === 0,
              vipTabFlagship: packageType === 2
            }"
        >
          <!-- 用户信息 -->
          <div class="user-info">
            <div class="left">
              <img :src="userInfo.userHeadImgUrl" class="avatar" />
              <div class="info">
                <p>
                  <span class="user-name">
                    {{ decodeURIComponent(userInfo.nickname.replace(/%/g, '%25'))}}
                  </span>
                  <span class="user-version">
                    {{userVersion}}
                  </span>
                </p>
                <p class="desc" v-if="userInfo.vipLevel === 0">升级会员，海量模板素材免费商用</p>
                <p class="desc" v-else>当前会员有效期至：{{expireDate}} <span v-if="teamInfo.flag">   |    共 {{ teamInfo.maxMemberAmount}} 席位，已使用 {{memberAmount}} 个</span></p>
              </div>
            </div>
            <div class="right"><span @click="questionList">常见问题</span> ｜ <span @click="contactService">联系客服</span></div>
          </div>
          <div class="tab-box">
            <div
              v-for="(item, index) in VIP_TAB"
              :key="index"
              :class="{
                active: packageType === index,
                company: 1 === index,
                person:  0 === index,
                flagship: 2 === index
              }"
              class="tab-item"
              @click="changeTab(index)"
            >
              <p class="title" >

                {{ item.title === '旗舰版' && isLenovoSite? '更多咨询' : item.title }}
              </p>
              <p class="range">
                {{ item.range }}
              </p>
            </div>
          </div>
        </div>
        <div  class="vip-pop-pay">
          <pay-sider :isLenovoSite="isLenovoSite"/>
          <!-- 通用版 协作版套餐-->
          <div class="vip-pop-pay-content">
            <!-- 通用版提示 -->
            <div class="standard-des" v-if="packageType === 0">
              <!-- <span class="warning-icon"></span> -->
              <div class="standard-tips-content">
                通用版会员适用于个人/企业1人使用，可按需选择合适的套餐，如需多人协作使用可选择
                <span class="link-style" @click="changeTab(1)">协作版</span>
                或<span class="link-style" @click="changeTab(2)">旗舰版</span>
              </div>
            </div>
            <!-- 协作版 选择席位数 -->
            <div class="package-person-time" v-if="packageType === 1">
              <p class="title">选择席位数</p>
              <div class="choose-box num">
                  <!-- 自定义席位数 -->
                  <div class="num-button decrease" :class="{'disable':inputNum<=2}" @click="handleDecrease">-</div>
                    <div
                      class="input-box"
                      :class="{                    
                        person: packageType === 0,
                        company: packageType > 0
                        }" 
                      >
                    <input maxlength = '6' v-model="inputNum"  @input="changeInput" placeholder="自定义人数"  ref="ipt">
                    <!-- <img :src=" packageType === 0 && ![2,3,5,10].includes(chooseNum) ? personEditImg : elseEditImg" alt="" v-if="inputNum!==''" @click="editInput"> -->
                    </div>
                    <div class="num-button increase" :class="{'disable':inputNum==999999}" @click="handleIncrease">+</div>
              
              </div>
            </div>
            <!-- 套餐展示 -->
            <div class="package-display-wrap">
              <package-holder v-if="vipPackagesReq"/>
              <package-display
                v-else
                :vip-packages="vipPackages"
                :selected-package="selectedPackage"
                :style="vipSeries >= 0 ? 'padding-top: 48px' : ''"
                @on-select="onSelect"
                @on-refresh="getAllVipPackages"
                :inputNum="inputNum"
                :packageType="packageType"
              />
            </div>
            <!-- 续费提示 -->
            <div v-if="isSignPackage === 1" class="sign-box">到期后将按每{{yearMonth}}{{nextPrice/ 100}}元自动续费，可随时取消
              <lt-poptip
                trigger="hover"
                :visible-arrow="true"
                placement="top"
                class="have-information"
              >
                <div class="pc-help-icon"></div>
                <div slot="content" class="content">
                  <p>【自动续费声明】</p>
                  <ul>
                    <li>1.签约到期前1天为您自动续费</li>
                    <li>2.可随时取消自动续费，取消后不再自动续费</li>
                  </ul>
                </div>
              </lt-poptip>
              </div>
            <!-- 基础版 选择授权类型 -->
            <div class="standard-author-type-box" v-if="packageType === 0">
              <span>选择授权类型</span>
              <div class="standard-tip">            
                <lt-poptip
                  trigger="hover"
                  :visible-arrow="true"
                  placement="bottom"
                  class="have-information"
                >
                  <div class="pc-help-icon"></div>
                  <div slot="content" class="content">
                    <div class="standard-tip-title"><span class="title">如何选择企业授权方式？</span></div>
                    <p>个人商用授权：</p>
                    <ul>
                      <li>授权主体为个人、支持个人商用，适用个人新媒体账号、个人网店、个体店铺、个人直播带货等个人宣传商业用途</li>
                    </ul>
                    <p>企业商用授权：</p>
                    <ul>
                      <li>授权主体为企业/个体户，支持企业商用，适用企业公众号、企业店铺、企业印刷出版、企业直播带货等企业宣传商业用途。</li>
                    </ul>
                  </div>
                </lt-poptip>
              </div>
              <div class="standard-author-type-list">
                <div class="standard-author-type-item" v-for="item in standardAuthorTypeList" :key="item.id" @click="chooseStandard(item.id)">
                  <span class="select-status" :class="{selected: item.id === standardType}"></span>
                  <div class="standard-author-type-name">{{item.name}}</div>
                  <div class="price-des" v-if="item.id === 1">
                    <span class="old-price">￥300</span>
                    <span class="present-price">￥0</span>
                    <span class="timelimit-tips">限时福利</span>
                  </div>
                </div>
              </div>
            </div>
            <template v-if="selectedPackage.id !==0 ">
              <!-- 优惠券展示 -->
              <div class="balance-box" v-if=" isSignPackage === 0" :style="{margin: packageType===1?'24px 0':'18px 0'}">
                <p>
                  <span class="lable">优惠券：</span>
                  <span  v-if=" discountAmount !==0 && couponType === 0 " class="coupon-money">-¥{{discountAmount/100}}</span>
                  <span class="null-tip" v-else>暂无可用优惠券</span>
                </p>
                <p class="coupon-tip" 
                  v-if=" discountAmount !==0&& couponType === 0"
                >
                  <span  class="time" v-if='this.validEndTime - this.systemTime< 86400000'>优惠券剩余有效时间：{{formatDuring}}</span>
                  <span v-else>已为您选择了最适合的优惠券</span>
                </p>
              </div>
              <!-- 支付 -->
              <pay-display
                v-if="!showFlagshipCode"
                @changePackageType="changePackageType"
                @refresh-qrcode="refreshQrcode"
                @transfer-show="transferModalShow = true"
                @coupon-show="couponModalShow = true"
              />
              <!-- 旗舰版咨询二维码 -->
              <flagship-question v-else/>
            </template>
          </div>
          <!-- 旗舰版套餐 -->
          <!-- <flagshipDisplay  v-else></flagshipDisplay> -->
        </div>
      </div>

      <!-- 优惠券弹窗 -->
      <LtModal v-model="couponModalShow" :z-index="1002" :closable="false">
        <Coupon
          :type="couponType"
          :level="selectedPackage.level"
          :tpi="choosePackageId"
          :price="initToPay"
          @login="login"
          @get-coupon-info="getCouponInfo"
          @close-pop="couponModalShow = false"
        />
      </LtModal>

      <!-- 对公转账弹窗 -->
      <LtModal v-model="transferModalShow" :closable="false" :z-index="1003">
        <PublicTransfer
          :transferAmount="needToPay"
          :openType="1"
          :requestPrefix="requestPrefix"
          :tariffPackageId="choosePackageId"
          :level="selectedPackage.level"
          :seatInputCount="personNum"
          @close="transferModalShow = false"
        />
      </LtModal>
    </LtModal>
    <!--支付成功弹框-->
    <LtModal 
      v-model="vipSuccess"
      :closable="false"
      :mask-closable="false"
    >
      <paySuccess 
        @goSuccess = 'handleBuySucceed'  
        @on-cancel="closeSuccess" 
        :level="selectedPackage.level"
        :afterBuyExpireDate = 'afterBuyExpireDate' 
        :choosePackageName = 'choosePackageName'
        :userId="userInfo.userId"
      ></paySuccess>
    </LtModal>
    <!--关闭支付成功弹窗 -->
    <LtModal 
     v-model="vipSuccessClose"
     :closable="false"
     :mask-closable="false"
     >
     <div class="close-vip-success">
      <p class="title">温馨提示</p>
      <LtIcon
        type="close"
        size="16"
        class="close-icon"
        @click="sureCancel"
      />
      <ul>
        <li>如果您还未完善授权信息，可以在后续下载设计时，按照提示完善相关信息。</li>
        <li>如果您还未邀请团队成员，后续可以在“首页＞我的团队” 模块里邀请团队成员。</li>
      </ul>
      <div class="btn" @click="sureCancel">我已知晓</div>
     </div>
    </LtModal>
    <!--优惠券弹框-->
    <LtModal 
      v-model="CouponSuccess"
      :closable="true"
      class="coupon-modal"
      :mask-closable="false"
      @on-cancel="CouponSuccessClose"
    >
      <div class="couponBox">
        <div 
        :class="{
                  couponBack: showType === 2 ,
                  couponLineBox: showType === 1 
                }"
        >
          <div class="coupon-content">
            <div class="coupon-number"><span class="money-title">¥</span><span>{{couponDiscountAmount/100}}</span></div>
            <div class="coupon-desc">
              <p>会员优惠券</p>
              <p>满{{thresholdAmount/100}}可用</p>
            </div>
          </div>
          <p class="coupon-time">有效期剩余：<span class="time-line">{{couponFormatDuring}}</span></p>
          <div class="coupon-btn" @click="useCoupon()"></div>
          <p class="coupon-round">{{couponVipDetail}}</p>
        </div>
      </div>
    </LtModal>
    <flagshipModal :showFlagTips.sync="showFlagTips"/>
  </div>
</template>

<script>
import { getTime, matchVip } from "./utils";
import CryptoJS from 'crypto-js'
import RightsIntro from "./components/rights-intro.vue";
import PackageDisplay from "./components/package-display.vue";
import PackageHolder from "./components/package-holder.vue";
import PayDisplay from "./components/pay-display.vue";
import flagshipQuestion from './components/flagship-question.vue';
import PaySider from "./components/pay-sider.vue";
import Coupon from "./components/couponModal/couponModal.vue";
import PublicTransfer from "./components/publicTransferPop/publicTransferPop.vue";
import flagshipModal from './components/flagship-modal.vue';
import paySuccess from "./components/pay-success.vue";
import flagshipDisplay from "./components/flagship-display.vue";
import debounce from './debounce'
import {TIME_TYPE,PERSON_NUM,VIP_VERSION, GIO_VIP_PAYPAGE, VIP_TAB} from "./constant.js";
const APIV2 = { apidomainv2: true };

export default {
  name: "VipPop",
  components: {
    RightsIntro,
    PackageDisplay,
    PayDisplay,
    PaySider,
    Coupon,
    PublicTransfer,
    paySuccess,
    PackageHolder,
    flagshipDisplay,
    flagshipQuestion,
    flagshipModal
  },
  provide() {
    return {
      app: this
    };
  },
  props: {
    defaultPersonNum:{
      type: Number,
      default: 1,
    },
    timeIndex: {
      type: Number,
      default: 0,
    },
    visible: {
      type: Boolean | Number,
      default: false,
    },
    userInfo: {
      type: Object,
      required: true,
    },
    featureIndex: {
      type: Number,
      default: 0,
    },
    activeFeatureIndex: {
      type: Number,
      default: 0,
    },
    showBuyPop: {
      type: Boolean,
      default: false,
    },
    teamInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    duration: {
      type: Number,
      default: 0,
    },
    downloadCount: {
      type: Number,
      default: 0,
    },
    vipSeries: {
      type: Number,
      default: -1,
    },
    locationFlag: {
      type: Number,
    },
    resourceData: {
      type: Object,
      default: function() {
        return {};
      },
    },
    requestPrefix: {
      type: String,
      default: "ul://",
    },
    source:{
      type: Object,
      default: function(){
        return {};
      }
    },
    isShowVipSuccess: {
      type: Boolean,
      default: true
    },
    designCustomPostion:{ //由设计页传过来的用于初始化定位的参数
      type: String, //personal：个人商业使用 company：企业商用
      default: ''
    },
    tariffPackageParasm: {
      type: Object,
      default: function() {
        return {};
      },
    },
    packageLevel:{
      type: Number,
      default: 0
    },
    packageId:{
      type: Number,
      default: 0
    },
    standardOrEnterprise:{ //'standard' 通用版 'enterprise' 企业版
      type: String,
      default: ''
    },
  },
  data() {
    return {
      GIO_VIP_PAYPAGE,
      VIP_TAB,
      packageType: 0, // 套餐类型 0个人套餐，1企业套餐，2旗舰版
      standardType: 0, // 通用套餐中的0: 个人， 1: 中小企业  2:标准版大企业210
      nextPackageId:0, // 上一笔购买的套餐id
      nextMemberCount: 0, //上一笔购买套餐席位
      maxNumber: 1,
      minNumber: 1,
      baseNum: 1, //基数
      vipSuccess: false,
      CouponSuccess: false,
      vipSuccessClose: false,
      vipPopShow: this.visible,
      // 支付页
      payShow: this.showBuyPop,
      vipPackagesReq: true,
      companyVip: [], // 企业套餐
      personalVip1: [], // 标准个人
      personalVip2: [],// 标准中小微
      standardEnterprise: [],//标准大企业
      premiumEnterprise:[],//高级版大企业
      memberAmount: 1, // 团队成员数量
      // 选中的套餐
      selectedPackage: {
        id: 0,
        level: 0,
        initPrice: 0,
        twoYearsPackageId: 0,
      },
      // 优惠券/码
      couponModalShow: false,
      couponList: [], // 优惠券列表
      discountAmount: 0, // 优惠额度
      couponDiscountAmount: 0,
      couponType: 0, // 优惠券类型 0优惠券；1优惠码
      bottomAmount: 0, // 优惠门槛
      discountCode: "", // 发请求时使用的优惠码
      validEndTime:0, // 优惠券到期时间
      systemTime: 0, //系统时间
      // 支付
      initToPay: 0, // 未使用优惠券时需要支付的金额
      payAction: 0, // 购买行为：5降级，10普通购买，15续费，20升级
      mobPayToken: "",
      mPayUrl: "", // 移动端购买地址
      qrcodeExpired: false, // 支付二维码是否过期
      afterBuyExpireDate: "", //  支付后的到期时间提示
      expireDate: '', // 到期时间
      transferModalShow: false, // 对公转账弹窗
      // 选中请求套餐
      choosePackageId: 0,
      choosePackageName: '',
      // 企业版购买实际年数
      yearCount: 0,
      TIME_TYPE,
      PERSON_NUM,
      timeType: 0,//0 按年买， 1按月买
      personNum: 1, // 默认选中1人
      minxPerson: 1,
      inputNum: 2,
      chooseNum:'',
      personEditImg: require('./img/edit.svg'),
      elseEditImg: require('./img/edit1.svg'),
      isSignPackage: '', //选中的是否是续约套餐 0普通套餐 1订阅套餐
      payType: 0, //续约支付的时候支持支付宝还是微信 0全部 2支付宝 1微信 
      subTrialDay:0, //试用天数
      nextPrice: '', //下次续费价格
      yearMonth: '', //1月 2年 3季度
      availableLevelList:[211,212,205], //可购买的套餐level
      showType: 0,
      thresholdAmount:0,
      couponVipPackageName:'',
      couponValidEndTime:'',
      couponSystemTime:0,
      traffOrCraft:0,
      versionOrPaper: 0,
      nolifeStyle:'',//是否展示终身会员ab
      showFlagTips: false,//旗舰版咨询弹框
      standardAuthorTypeList:[
        {
          id:0,
          name:'个人商用授权'
        },
        {
          id:1,
          name:'企业商用授权'
        }
      ],
    };
  },
  computed: {
    isLenovoSite(){
      // return true
      const sourceName = localStorage.getItem("utm_source") 
      const isLenovoPc = sourceName === 'lxllq' || sourceName === 'lxllqsy' || sourceName === 'lxllqAIzl' || sourceName === '联想浏览器名站首页' || sourceName === '联想浏览器AI专栏'
      console.log('isLenovoPc',isLenovoPc)
      return window.jsbridge || isLenovoPc ? 1 : 0
    },
    // 优惠券会员展示名称
    couponVipName(){
      let versionOrPaper = this.versionOrPaper
      return VIP_VERSION[versionOrPaper]
    },   
    //优惠券详情
    couponVipDetail(){
      if (this.versionOrPaper == 0 ){
        return ''
      }
      return `限${VIP_VERSION[this.versionOrPaper]}会员${this.couponVipPackageName}${this.couponVipPackageName ? '套餐' : '' }可用`
    },
    // 实际需要支付的金额
    needToPay() {
      const amount = this.initToPay - this.discountAmount;
      return amount > 0 ? amount : 0;
    },
    // 展示的会员套餐信息
    vipPackages() {
      let companyVip = this.companyVip; //高级版
      let personalVip1 = this.personalVip1;//个人商用
      let personalVip2 = this.personalVip2;//小企业商用
      //标准版大企业
      const standardEnterprise = this.standardEnterprise
      //高级版大企业
      const premiumEnterprise = this.premiumEnterprise
      let vipPackages =[]
      if(this.packageType === 0){ //标准版
        if(this.standardType === 0){//个人
          vipPackages = personalVip1
        }else if(this.standardType === 1){//中小微
          vipPackages = personalVip2
        }else if(this.standardType === 2){ //标准版大企业
          vipPackages = standardEnterprise
        }
      }else if(this.packageType === 1){//高级版
        vipPackages = companyVip
      }
      return vipPackages;
    },
    // 倒计时时间
    couponFormatDuring() {
    console.log(this.couponValidEndTime , this.couponSystemTime)
      var mss = this.couponValidEndTime - this.couponSystemTime
      var seconds = Math.floor(mss / 1000);
      var h = Math.floor(seconds / 3600);
      var m = Math.floor((seconds % 3600) / 60);
      var s = seconds % 60;
      h = h >= 10 ? h : `0${h}`,
      m = m >= 10 ? m : `0${m}`,
      s = s >= 10 ? s : `0${s}`
      if (mss>0) {
        return `${h} 小时 ${m} 分钟 ${s} 秒`
      }
      return `00 小时 00 分钟 00 秒`
    },
    // 倒计时时间
    formatDuring() {
      var mss = this.validEndTime - this.systemTime
      var h = parseInt((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var m = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60));
      var s = parseInt((mss % (1000 * 60)) / 1000);
      h = h >= 10 ? h : `0${h}`,
      m = m >= 10 ? m : `0${m}`,
      s = s >= 10 ? s : `0${s}`
      if (mss>0) {
        return `${h}:${m}:${s}`
      }
      return `00:00:00`
    },
    // 用户的会员版本
    userVersion() {
      return VIP_VERSION[this.userInfo.vipLevel] || "个人免费版";
    },
    //团队人数超10人的团队只能购买旗舰版
    isOverTenMember(){
      return this.memberAmount > 10
    },
    //当前在高级版tab下 且没有可购买的套餐 且团队成员大于10人
    showFlagshipCode(){
      return this.packageType === 1 && ((this.availableLevelList.length === 0 && this.isOverTenMember) || this.inputNum>this.maxNumber)
    },
    canBuyStandard(){
      const standList =[211,212]
      return this.checkCanBuy(standList)
    },
    canBuyEnterprise(){
      const enterpriseList =[205]
      return this.checkCanBuy(enterpriseList)
    }
  },
  watch: {
    visible() {
      this.initBuy();
    },
    showBuyPop(v) {
      this.payShow = v;
    },
    payShow(v) {
      if (v) {
        this.initBuy();
      }
    },
    packageType(v) {
      //定位到标准版但不能买 则定位到高级版
      if(v === 0 && !this.canBuyStandard){
        this.packageType = 1
      }
    },
  },
  created() {
    this.initBuy();
    // 是否直接展示支付弹窗
    this.payShow = this.showBuyPop;
  },
  methods: {
    checkCanBuy(levelList){
      let canbuy = false
      this.availableLevelList.forEach(item => {
        if (levelList.includes(item)){
          canbuy = true
        }
      })
      return canbuy
    },
    editInput(){
      this.$refs.ipt.focus()
    },
    closeSuccess(){
      this.vipSuccess = false
      this.vipSuccessClose = true
    },
    sureCancel(){
      window.location.reload();
      this.vipSuccessClose = false
    },
    chooseStandard(type){
      try {
        window.gio('track', 'button_click',{
          button_name: type ==0 ?'个人商用授权': type ==1?'中小微企业授权':'大型企业商用',
          page_name: '会员支付弹框',
          module_name: '通用版套餐选择',
          page_url: window.location.href,
          is_login: '是',
          user_vip: VIP_VERSION[this.userInfo.vipLevel],
          team_id: this.teamInfo.teamId,
          team_vip: this.teamInfo.flag ? VIP_VERSION[this.teamInfo.adminUserVipLevel] : '免费团队',
          user_id:  this.userInfo.userId,
        })    
      } catch (error) {
        console.log('error',error)
      }
      const cantbuyPersonStandar = type ===0 && !this.availableLevelList.includes(211)
      const cantbuyMiniStandar = type ===1  && !this.availableLevelList.includes(212)
      const cantbuyBigComStandar = type === 2 &&!this.availableLevelList.includes(210)
      if (cantbuyPersonStandar || cantbuyMiniStandar || cantbuyBigComStandar) {
        if (this.userInfo.vipLevel === 120 || this.userInfo.vipLevel === 3 || this.userInfo.vipLevel === 220){
          this.$message("您当前为旗舰版会员，不支持降级至低版本会员，请继续购买旗舰版会员或联系客服")
          return
        }
        if (this.memberAmount > this.maxNumber) {
          this.$message(`您当前为${VIP_VERSION[this.userInfo.vipLevel]}会员且总人数大于${this.maxNumber}人，不支持降级至低版本会员, 请购买旗舰版或联系客服`)
        } else {
          this.$message(`您当前为${VIP_VERSION[this.userInfo.vipLevel]}会员，不支持降级至低版本会员, 请购买协作版或旗舰版`)
        }
        return
      }
      this.standardType = type
      //切换后需定位到相同价格的套餐
      this.getSamePricePackage(type)
    },
    getSamePricePackage(){
      const currentSelectPrice = this.selectedPackage.discountPrice
      const targetPackage = this.vipPackages.find(item => item.discountPrice === currentSelectPrice)
      if(targetPackage){
        this.selectedPackage = targetPackage
      }else{//默认第一个
        this.selectedPackage = this.vipPackages[0]
      }
      this.onSelect();
    },
    chooseInput(type){
      if(type === 'decrease'){
        this.inputNum--
      }else if(type === 'increase'){
        if (this.inputNum > 999998){
          return
        }
        this.inputNum++
      }
      if (this.inputNum || this.inputNum === ''){
        if (this.inputNum < this.memberAmount) {
          this.inputNum = this.memberAmount
          this.$message("输入的人数不能少于团队人数")
          return
        }

        if (this.inputNum < this.minNumber + 1) {
          this.inputNum = this.minNumber
          this.$message(`协作版${this.minNumber}席位起售`)
        }
        this.personNum = this.inputNum
        if(this.inputNum > 10){
          return 
        }
        this.getAllVipPackages()
      }
    },
    changeInput(e){
			this.inputNum = e.target.value.replace(/\D/g, '').replace(/^0{1,}/g, '');
      debounce(this.chooseInput,1000);
    },
    handleDecrease(){
      this.chooseInput('decrease')
    },
    handleIncrease(){
      this.chooseInput('increase')
    },
    countDownSec() {
      if (this.systemTime > 0) {
        this.timer = setInterval(() => {
          this.systemTime += 1000
        }, 1000)
      }
    },
    couponCountDownSec() {
      // clearTimeout(this.couponTimer)
      if (this.couponSystemTime > 0) {
        this.couponTimer = setInterval(() => {
          this.couponSystemTime += 1000
        }, 1000)
      }
    },
    changeTab(index){
      var button_name = [{name:"通用版"},{name: '协作版'},{name:'旗舰版'}]
      window.gio('track', 'button_click',{
        button_name: button_name[index].name,
        page_name: '会员支付弹框',
        module_name: '会员版本选择',
        page_url: window.location.href,
        is_login: '是',
        user_vip: VIP_VERSION[this.userInfo.vipLevel],
        team_id: this.teamInfo.teamId,
        team_vip: this.teamInfo.flag ? VIP_VERSION[this.teamInfo.adminUserVipLevel] : '免费团队',
        user_id:  this.userInfo.userId,
      })
      this.changePackageType(index) 
    },
    changePackageType(index){
      if (this.packageType === index) return
      if(index === 2){
        this.showFlagTips = true
        return
      }
      //定位至标准版 
      //可以买标准版大企业
      const canBuyBigStandard = this.availableLevelList.includes(210)
      if (index === 0 && !this.canBuyStandard && !canBuyBigStandard) {
        if (this.userInfo.vipLevel === 120 || this.userInfo.vipLevel === 3  || this.userInfo.vipLevel === 220){
          this.$message("您当前为旗舰版会员，不支持降级至低版本会员，请继续购买旗舰版会员或联系客服")
          return
        }
        if (this.memberAmount > this.maxNumber) {
          this.$message(`您当前为${VIP_VERSION[this.userInfo.vipLevel]}会员且总人数大于${this.maxNumber}人，不支持降级至低版本会员, 请购买旗舰版或联系客服`)
        } else {
          this.$message(`您当前为${VIP_VERSION[this.userInfo.vipLevel]}会员，不支持降级至低版本会员, 请购买协作版或旗舰版`)
        }
        return
      }else if (index === 0  && !this.canBuyStandard && canBuyBigStandard){
        this.standardType = 2
      }
      if (index === 1 && !this.canBuyEnterprise) {
        if (this.userInfo.vipLevel === 120 || this.userInfo.vipLevel=== 3 || this.userInfo.vipLevel === 220){
          this.$message("您当前为旗舰版会员，不支持降级至低版本会员，请继续购买旗舰版会员或联系客服")
          return
        } else {
          this.$message(`当前团队人数大于${this.maxNumber}人，无法购买协作版请购买旗舰版或联系客服`)
          return
        }
      }
      this.vipPackagesReq = true;
      this.packageType = index
      if (index===2) return
      this.getDefaultNumber();
      this.getAllVipPackages();
    },
    changeNum(type, name){
      window.gio('track', 'button_click',{
        button_name: name,
        page_name: '会员支付弹框',
        module_name: '协作版套餐选择',
        page_url: window.location.href,
        is_login: '是',
        user_vip: VIP_VERSION[this.userInfo.vipLevel],
        team_id: this.teamInfo.teamId,
        team_vip: this.teamInfo.flag ? VIP_VERSION[this.teamInfo.adminUserVipLevel] : '免费团队',
        user_id:  this.userInfo.userId,
      })    
      if (type < this.memberAmount){
        this.$message({
          dangerouslyUseHTMLString: true,
          message:'输入的人数不能小于您已邀请的团队成员人数，去<span style="cursor:pointer;color:#0773fc">管理团队成员</span>',
          duration: 3000,
          onClick: () => {
            window.open('/designtools/team/member');
          }
        })
        return
      }
      this.personNum = type
      this.chooseNum = type
      this.getAllVipPackages()
    },
    login() {
      this.$message("请先登录");
      this.$emit("login");
    },
    // 升级会员
    upgradeVip() {
      try {
        // 会员特权介绍弹窗-升级会员按钮-点击事件埋点
        window.gio("track", "vip_introduction_popup_upgrade_click");
        // 会员支付弹窗-浏览 埋点
        window.gio("track", "vip_pay_popup_view");
      } catch (e) {
        console.error("gio error \n" + e);
      }
      this.payShow = true;
    },
    // 购买初始化
    async initBuy() {
      if (!this.visible) return;
      this.vipPopShow = true;
      this.timeType = this.timeIndex
      this.vipopShowBuiredPoint()
      if (this.payShow) {
        await this.getVipPackageInfo(); // 获取当前用户购买过的套餐
        await this.getMemberAmount(); // 获取团队成员数量
        await this.applyWapMemberPayToken(); // 申请手机端购买会员
        await this.getCouponListByUserId(); // 获取用户的优惠券列表
        if (this.packageType===2) return
        await this.getAllVipPackages(); // 获取套餐列表
      }
    },   
    baseHeader() {
      const vipNameObj = {
        0: '普通用户',
        1: '企业VIP-基础版',
        2: '协作会员',
        3: '高级团队VIP',
        4: '个人版',
        5: '企业VIP-标准团队版',
        6: '企业专业版VIP',
        7: '企业VIP-加强版',
        8: '企业VIP-基础团队版',
        9: '企业VIP-全用途版',
        100: '基础版VIP',
        105: '全用途版VIP',
        110: '标准团队版VIP',
        115: '高级团队版VIP',
        120: '企业专业版',
        125: '新媒体商户版',
        130: '全用途商户版',
        135: '团队协作版',
        201: '个人高级版',
        199: '标准版（个人）',   
        200: '标准版（企业）',
        211: "通用版（个人）",
        212: "通用版（企业）",
        202: '企业高级版',
        203: '高级版',
        205: '协作版',
        220: '旗舰版'
      }
      const event_track_obj = this.getTrackObj()
      const desktopAppData = window._WUFA_TRACKER_DATA_ || {}
      return {
        uuid: sessionStorage.getItem('uuid'),//访问用户ID
        user_id: this.userInfo.userId,//用户id
        user_vip: this.userInfo.vipLevelName,//普通用户、（具体的会员名称，比如新媒体商户版）
        team_id: this.teamInfo.teamId,//当前所在团队id
        team_name: this.teamInfo && decodeURI(this.teamInfo.teamName), //当前所在团队名称
        team_vip: this.teamInfo && vipNameObj[this.teamInfo.adminUserVipLevel],//当前所在团队vip
        domain: window.location.hostname,//域名
        business_lines: '1',//业务线
        product_name: '1',//产品名称
        platform: '4',//应用平台
        ocpc_time: event_track_obj.ocpc_time,
        ocpc_flag: event_track_obj.ocpc_flag,
        utm_source: event_track_obj.utm_source,
        ocpc_source: event_track_obj.ocpc_source,
        utm_medium: event_track_obj.utm_medium,
        utm_campaign: event_track_obj.utm_campaign,
        utm_term: event_track_obj.utm_term,
        utm_content: event_track_obj.utm_content,
        screen_size: `${window.innerHeight}*${window.innerWidth}`,//屏幕大小（高*宽）
        reporting_time: new Date().getTime(),//上报时间
        department: 1,//埋点实施部门
        ...desktopAppData,
      }
    } ,
    //默认由会员版本从低到高排序 能买哪个买哪个
    getInitPosition(){
      //如果列表为空 并且团队人数超10人 则定位至高级版
      if(this.availableLevelList.length === 0 && this.isOverTenMember){
        this.packageType = 1
        return
      }
      if (this.availableLevelList.includes(211)){
        this.standardType = 0
        return
      }
      if (this.availableLevelList.includes(212)){
        this.standardType = 1
        return
      } 
      if (this.availableLevelList.includes(210)){
        this.standardType = 2
        return
      }
      if (this.availableLevelList.includes(205)){
        this.packageType = 1
        return
      }
    },
    getInitPostionByCertainPac(){
      if(this.packageLevel === 199 || this.packageLevel === 211){
        this.packageType = 0
        this.standardType = 0
      }else if(this.packageLevel === 200 || this.packageLevel === 212){
        this.packageType = 0
        this.standardType = 1
      }else if(this.packageLevel === 210){
        this.packageType = 0
        this.standardType = 2
      }else if(this.packageLevel === 205){
        this.packageType = 1
      }
    },
    getDesignInitPosition(){
      if(this.designCustomPostion === 'personal'){//定位标准版 个人商业使用
        if(this.availableLevelList.includes(211)){
          this.packageType = 0
          this.standardType = 0
        }
      }else if(this.designCustomPostion === 'company'){
        if(this.availableLevelList.includes(212)){ //能买200 则定位至标准版中小微
          this.packageType = 0
          this.standardType = 1 
        }else if(this.availableLevelList.includes(205)){ //能买205 则定为至高级版中小微
          this.packageType = 1
        }
      }
    },
    // 获取当前用户上笔购买套餐
    async getVipPackageInfo(){
      try { 
        const { body:{ code ,data  } } = await this.$http.post('/vip/member/getVipPackageInfo',{ gw: true, GW: true })
        if (code === 200 ){
          this.expireDate = data.expireDate;
          this.nextPackageId = data.packageId;
          let memberLevelLimitList = data.memberLevelLimitList.filter(
              (e) => e.vipLevel === 205
            );
          this.nextMemberCount = (data.memberCount > memberLevelLimitList[0].maxNumber ?  memberLevelLimitList[0].maxNumber : data.memberCount) || 1
          this.maxNumber = memberLevelLimitList[0].maxNumber || 1
          this.minNumber = memberLevelLimitList[0].minNumber ||  1
          this.availableLevelList = data.availableLevelList
          if(this.designCustomPostion){
            //设计页定制套餐定位【根据公司体量(个人/中小微/大型企业)】 
            this.getDesignInitPosition()
          }else if(this.packageLevel){
            //指定具体套餐id定位
            this.getInitPostionByCertainPac()
          }else if(this.standardOrEnterprise){//指定标准版或者企业版
            if(this.standardOrEnterprise === 'standard'&& this.canBuyStandard){
              this.packageType = 0
              if(!this.availableLevelList.includes(211)){
                this.standardType = 1
              }
            }else{
              this.packageType = 1
            }
          }else{
            //默认套餐定位
            this.getInitPosition()
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 获取团队成员数量
    async getMemberAmount() {
      await this.$http.post("/team/team/getUserTeams", { gw:true, GW:true }).then((res) => {
        // -2未登录；-103用户无团队；200获取成功
        const teams = res.body.data;
        if (res.body.code === 200 && teams.length > 0) {
          for (let i = 0, len = teams.length; i < len; i++) {
            if (teams[i].adminUserId === this.userInfo.userId) {
              this.memberAmount = teams[i].memberAmount;
              this.getDefaultNumber()
            }
          }
        }
      });
    },
    async vipopShowBuiredPoint(){
      try {
        if (this.payShow) {
          //会员页支付弹框曝光
          gio('track', 'vip_pay_show', {
            forward_module_name: this.source.moduleName,
            forward_page_name: this.source.pageName,
            popup_name: 'VIP购买弹框',
            vip_abtest: this.nolifeStyle,
            is_login: '是',
            from: this.source.from,
            ...this.baseHeader(),
          })
          this.reportDataStore('payShow')
        } else {
          //会员权益弹框曝光
          gio('track', 'vip_rights_show', {
            forward_module_name: this.source.moduleName,
            forward_page_name: this.source.pageName,
            popup_name: 'VIP权益介绍弹框',
            is_login: '是',
            from: this.source.from,
            ...this.baseHeader(),
          })
          this.reportDataStore('rightShow')
        }
      } catch (e){
        console.log(e)
      }
    },
    // 获取套餐列表
    async getAllVipPackages() {
      // let addMemberCount
      // if ( this.userInfo.vipLevel>201 && this.packageType===0 ){
      //   addMemberCount = 0
      // } else {
      //   addMemberCount = this.personNum-this.baseNum > 0 ? this.personNum-this.baseNum : 0
      // }
      let packInfoParams = {
        GW: true,
        gw: true
      };
      if(this.isLenovoSite){
        packInfoParams.platform = 10
      }
      const {body:{data:{style,type}}}= await this.$http.post("/vip/member/getPackageABTestInfo", packInfoParams);
      this.nolifeStyle = style
      let params = {
        type: type||93,
        addMemberCount: this.personNum-this.minNumber < 0 ? 0 : this.personNum-this.minNumber,
        GW: true,
        gw: true
      };
      if (this.tariffPackageParasm && this.tariffPackageParasm.tariffPackageId>0) {
        params.type = this.tariffPackageParasm.type
      }
      await this.$http.post("/vip/member/getPackageList", params).then((res) => {
        if (res.body.code === 200) {
          const allPackages = res.body.data;
          this.personalVip1 = matchVip(allPackages, 211);
          this.personalVip2 = matchVip(allPackages, 212);
          //高级版
          this.companyVip = matchVip(allPackages, 205);
          console.log('companyVip',this.companyVip)
          //标准版大企业
          this.standardEnterprise = matchVip(allPackages, 210);
          //高级版大企业
          this.premiumEnterprise = matchVip(allPackages, 204);
          if (this.vipPackages.length === 0){
            this.selectedPackage = {
              id: 0,
              level: 0,
              initPrice: 0,
              twoYearsPackageId: 0,
            }
            return
          } 
          this.vipPackagesReq = false
          // 获取各套餐类型中的默认选择套餐
          this.getDefaultPackage();
        }
      });
    },
    getDefaultPackage() {
      console.log("vippackages",this.vipPackages)
      if(this.packageId){
        const halfPricePack = this.vipPackages.filter(item=>item.id === this.packageId)
        if (halfPricePack.length>0){
          this.selectedPackage = halfPricePack[0]
          this.onSelect();
          return
        }
      }
      //定位到上一笔的订单id 否则默认第一个
      if ( this.vipPackages.find((e) => e.id === this.tariffPackageParasm.tariffPackageId) !== undefined ){
        this.selectedPackage = this.vipPackages.filter( item => item.id === this.tariffPackageParasm.tariffPackageId )[0]
      } else if ( this.vipPackages.find((e) => e.id === this.nextPackageId) === undefined ){
        this.selectedPackage = this.vipPackages[0]
      } else {
          this.selectedPackage = this.vipPackages.filter( item => item.id === this.nextPackageId )[0]
      }
      if ( this.vipPackages.find((e) => e.id === this.traffOrCraft)){
        this.selectedPackage = this.vipPackages.filter( item => item.id === this.traffOrCraft )[0]
      }
      // 选择默认套餐
      this.onSelect();
    },
    //给输入框赋默认值
    getDefaultNumber(){
      if (this.packageType !== 1){
        this.personNum = 1
        return
      } 
      this.personNum = Math.max(this.minNumber, this.nextMemberCount, this.memberAmount)
      this.inputNum = this.personNum
    },
    // 选择套餐
    async onSelect(item) {
      try {
        if (item) {
          if (!this.availableLevelList.includes(item.level)){
            this.$message("不支持购买")
            return
          }
          this.selectedPackage = item;
        }
        const unit = {
          1:'月',
          2: '年',
          3: '季度'
        }
        console.log('this.selectedPackage',this.selectedPackage)
        this.yearMonth = unit[this.selectedPackage.yearMonth]
        this.payType = this.selectedPackage.supPayWay	
        this.subTrialDay = this.selectedPackage.subTrialDay	
        this.isSignPackage = this.selectedPackage.subType
        this.choosePackageId = this.selectedPackage.id
        this.choosePackageName = this.selectedPackage.name
        this.nextPrice = this.selectedPackage.nextPrice
        this.initPrice = this.selectedPackage.initPrice
        // 获取购买当前选中套餐后的到期时间
        await this.getAfterBuyExpireDate();
        // 自动选择可用优惠券
        this.autoUseCoupon();        
      } catch (error) {
        console.log(error)
      }
    },
    // 购买当前选中套餐后的到期时间
    async getAfterBuyExpireDate() {
      if (this.choosePackageId === 0) return;
      try {
        const params = {
          gw: true,
          GW: true,
          token: this.mobPayToken,
          packageId: this.choosePackageId,
          type: 0,
          addMemberCount: this.personNum-this.minNumber < 0 ? 0 : this.personNum-this.minNumber,
        };
        const { body:{ code, msg ,data  } }  = await this.$http.post("/vip/member/getPayAmount", params)
          if (code === 200) {
            this.afterBuyExpireDate = getTime(data.expireDate);
            this.initToPay = data.actualPayCount;
            this.payAction = data.purchaseAction;
            this.yearCount = data.yearCount
            this.price = data.price
          } else {
            this.$message(msg);
          }
        } catch (error) {
          console.log(error)
        }
    },
    // 获取用户优惠券列表
    async getCouponListByUserId() {
      const data = {
        scenario_type: 0, // -1全部；0会员；1印刷
        type: 0, // 0未使用；1已使用；2已过期；3全部
        gw:true,
        GW:true,
      };
      await this.$http.post("/vip/coupon/getCouponListByUserId", data).then((res) => {
        // 状态码:-1参数错误；-2未登录；200获取成功
        const code = res.body.code;
        switch (code) {
          case 200:
            this.couponList = res.body.data.filter(
              (e) =>
                e.usageScenarioType === 0 &&
                e.validBeginTime <= e.systemTime &&
                e.validEndTime >= e.systemTime
            );
            break;
          case -2:
            this.login();
            break;
          default:
            this.$message(
              `获取优惠券列表失败，错误信息：code-${code} msg-${res.body.msg}`
            );
        }
      });
    },
    // 优惠券自动使用
    autoUseCoupon() {
      // 优惠券自动使用逻辑按优先级排序：金额最大 -> 最近过期日期 -> 最小的优惠券
      let canUseList = [];
      // 挑选出未过期的且适用订单场景的
      this.couponList.forEach((coupon) => {
        const {
          usageScenarioType, // 使用场景类型(0会员；1印刷)
          validBeginTime, // 优惠券有效开始时间
          validEndTime, // 优惠券有效结束时间
          versionOrPaper, // 会员场景下代表套餐版本level
          traffOrCraft, // 会员场景下代表会员套餐id
          thresholdAmount, // 使用门槛金额（单位：分）
        } = coupon;
        const systemTime = new Date().getTime()
        console.log(validEndTime ,systemTime)
        if (
          usageScenarioType === 0 &&
          validBeginTime <= systemTime &&
          validEndTime >= systemTime
        ) {
          // 只需要判断versionOrPaper和traffOrCraft，两个都为空表示优惠券是通券，否则优先判断traffOrCraft，
          // 如果traffOrCraft不为空，则判断会员套餐的id是否在traffOrCraft中包含，
          // 如果包含，则该套餐可用优惠券，如果不包含再判断versionOrPaper里是否包含套餐的level
          const canUse =
            (versionOrPaper.length === 0 && traffOrCraft.length === 0) ||
            traffOrCraft.includes(this.choosePackageId) ||
            versionOrPaper.includes(this.selectedPackage.level);

          if (canUse && thresholdAmount <= this.selectedPackage.discountPrice) {
            canUseList.push(coupon);
          }
        }
      });
      // 无可用优惠券 - 不使用优惠 或者签约续费不能使用优惠券
      if (canUseList.length === 0 || this.isSignPackage === 1) {
        this.cancelCoupon();
        return;
      }
      canUseList.sort((a, b) => {
        // 按照优惠额度降序排序
        if (a.discountAmount - b.discountAmount > 0) {
          return -1;
        }
        // 额度相同按照过期时间升序排序
        if (a.discountAmount === b.discountAmount) {
          return b.validEndTime - a.validEndTime;
        }
      });
      //
      this.getCouponInfo({
        ...canUseList[0],
        couponType: 0,
      });
    },
    // 获取使用的优惠券信息
    getCouponInfo(info) {
      // 检查优惠券与总价
      this.bottomAmount = info.bottomAmount || info.thresholdAmount; // 优惠门槛
      if (this.initToPay < this.bottomAmount) {
        this.$message("该优惠券须满" + this.bottomAmount / 100 + "元使用");
        this.cancelCoupon();
        return;
      }
      this.systemTime = new Date().getTime() ;
      this.validEndTime = info.validEndTime;
      clearTimeout(this.timer)
      if (info.validEndTime - this.systemTime< 86400000){
        this.countDownSec()
      }
      this.discountAmount = info.discountAmount; // 优惠额度
      this.discountCode = info.unionCode; // 优惠码
      this.couponType = info.couponType || 0; // 0券 1码
      this.couponModalShow = false;
      this.generateMobPayUrl();
    },
    // 不使用优惠
    cancelCoupon() {
      this.bottomAmount = 0;
      this.discountAmount = 0;
      this.discountCode = "";
      this.generateMobPayUrl();
    },
    // 关闭弹窗
    async onVisibleChange(state) {
      const today = new Date();
      // 获取年、月和日
      const year = today.getFullYear();
      const month = today.getMonth() + 1; // 月份是从0开始计数的，所以要加1
      const day = today.getDate();
      if (!state) {
        if ((localStorage.getItem('userId'+this.userInfo.userId)&&localStorage.getItem('userId'+this.userInfo.userId) == `${year}-${month}-${day}`) ||(localStorage.getItem('openNumber'+this.userInfo.userId)&& Number(localStorage.getItem('openNumber'+this.userInfo.userId)) >= 3)){
          this.closeVipPopup()
        } else {
          try {
            const { body:{ code ,data  } } = await this.$http.post("/vip/coupon/popupCoupon/info", {gw: true, GW:true})
            if (code === 200){
              if (data.showType !== 0){
                window.gio('track', 'coupon_popup',{
                  forward_page_name:'会员支付弹框',
                  forward_module_name: '会员支付弹框_关闭',
                  popup_name: this.showType === 1? '优惠券领取成功弹框': '优惠券过期提示弹框',
                  page_url: window.location.href,
                  is_login: '是',
                  coupon_id: data.unionCode,
                  coupon_amount: data.discountAmount/100,
                  user_vip: VIP_VERSION[this.userInfo.vipLevel],
                  team_id: this.teamInfo.teamId,
                  team_vip: this.teamInfo.flag ? VIP_VERSION[this.teamInfo.adminUserVipLevel] : '免费团队',
                  user_id:  this.userInfo.userId,
                })   
                this.showType = data.showType
                this.vipPopShow = true
                this.CouponSuccess = true
                const today = new Date();
                // 获取年、月和日
                const year = today.getFullYear();
                const month = today.getMonth() + 1; // 月份是从0开始计数的，所以要加1
                const day = today.getDate();
                localStorage.setItem('userId'+this.userInfo.userId, `${year}-${month}-${day}`)
                let num = localStorage.getItem('openNumber'+this.userInfo.userId) ? Number(localStorage.getItem('openNumber'+this.userInfo.userId))+1 : 1
                localStorage.setItem('openNumber'+this.userInfo.userId,num)
                this.couponDiscountAmount = data.discountAmount
                this.traffOrCraftArray =  data.traffOrCraft// 会员场景下代表会员套餐id
                this.versionOrPaper = data.versionOrPaper.length> 0 ? data.versionOrPaper[0] : 0 // 会员场景下代表套餐版本level
                this.thresholdAmount = data.thresholdAmount
                this.couponValidEndTime = data.validEndTime
                this.couponSystemTime = new Date().getTime()
                clearTimeout(this.couponTimer)
                this.couponCountDownSec()
                if (this.traffOrCraftArray && this.traffOrCraftArray.length) {
                  let allPackagesVip = [...this.companyVip,...this.personalVip1, ...this.personalVip2];
                  let couponPackgeVip = allPackagesVip.filter(
                        (e) => this.traffOrCraftArray.includes(e.id)
                      );
                  if (couponPackgeVip.length > 0) {
                    this.traffOrCraft = couponPackgeVip[0].id
                    this.versionOrPaper =  couponPackgeVip[0].level
                    this.couponVipPackageName = couponPackgeVip[0].showName
                  }
                }
              } else {
                this.closeVipPopup()
              }

            } else {
              this.closeVipPopup()
            }
          } catch (error) {
            this.closeVipPopup()
          }
        }
      }
    },
    CouponSuccessClose(){
      window.gio('track', 'button_click',{
        button_name: '关闭',
        page_name: '会员支付弹框',
        module_name: this.showType === 1? '优惠券领取成功弹框': '优惠券过期提示弹框',
        page_url: window.location.href,
        is_login: '是',
        user_vip: VIP_VERSION[this.userInfo.vipLevel],
        team_id: this.teamInfo.teamId,
        team_vip: this.teamInfo.flag ? VIP_VERSION[this.teamInfo.adminUserVipLevel] : '免费团队',
        user_id:  this.userInfo.userId,
      })   
    },
    useCoupon(){
      window.gio('track', 'button_click',{
        button_name: '立即使用',
        page_name: '会员支付弹框',
        module_name: this.showType === 1? '优惠券领取成功弹框': '优惠券过期提示弹框',
        page_url: window.location.href,
        is_login: '是',
        user_vip: VIP_VERSION[this.userInfo.vipLevel],
        team_id: this.teamInfo.teamId,
        team_vip: this.teamInfo.flag ? VIP_VERSION[this.teamInfo.adminUserVipLevel] : '免费团队',
        user_id:  this.userInfo.userId,
      })   
      let allPackagesVip = [...this.companyVip,...this.personalVip1, ...this.personalVip2];
      if (this.traffOrCraft) {
        let couponPackgeVip = allPackagesVip.filter(
              (e) => e.id === this.traffOrCraft
            );
        this.versionOrPaper =  couponPackgeVip[0].level
      }
      this.CouponSuccess = false;
      if (this.versionOrPaper === 205){
        this.packageType = 1
      }
      if (this.versionOrPaper === 199 || this.versionOrPaper === 211){
        this.packageType = 0
        this.standardType = 0
      }
      if (this.versionOrPaper === 200 || this.versionOrPaper === 212){
        this.packageType = 0
        this.standardType = 1
      }
      this.initBuy()
    },
    closeVipPopup(){
      try {
          const event = this.payShow ? "vip_pay_popup_close_click" : "vip_introduction_popup_close_click";
          window.gio("track", event);
        } catch (error) {
          console.error("gio error \n" + error)
        }
        if (!this.showBuyPop) {
          this.payShow = false;
        }
        this.initToPay = 0;
        this.inputNum = 2;
        this.personNum = 1
        clearTimeout(this.payStateTimer);
    },
    // 申请手机端购买会员所需token
    async applyWapMemberPayToken() {
      if (this.userInfo.LoginTimeOut) {
        this.login();
        return;
      }
      const trackParams = this.getTrackObj()
      await this.$http
        .post("/vip/vip/memberOrder/applyWapMemberPayToken", { type: 0, ...trackParams, gw:true, GW:true})
        .then((res) => {
          if (res.body.code === 1) {
            this.mobPayToken = res.body.token;
            this.generateMobPayUrl();
          } else {
            this.qrcodeExpired = true;
          }
        });
    },
    // 生成手机端购买会员的链接
    generateMobPayUrl() {
      // if (this.initToPay === 0) return;
      // 适配m站没有beta环境
      let origin = ''
      if (location.origin.indexOf("beta-mater") > -1){
         origin = location.origin.replace("beta-mater", "beta-www");
      } else {
         origin = location.origin.replace("beta", "beta-www");
      }
      origin = origin.replace(/(www|mater)/, "m")
      //解决抠图域名问题
      if(origin.indexOf('moni-m') > -1){
        origin = origin.replace('moni-m','monim')
      }
      this.mPayUrl = `${origin}/mobPay.html?t=${
        this.mobPayToken
      }&id=${this.choosePackageId}&isSign=${this.isSignPackage}&isLenovoSite=${this.isLenovoSite}`;
      // 优惠券信息
      if (this.discountCode) {
        this.mPayUrl += `&cc=${this.discountCode}`;
        if (this.couponType === 0) {
          this.mPayUrl += `&quan=${this.discountAmount}`;
        }
      }
      if(this.source && this.source.from){
        this.mPayUrl += `&from=${this.source.from}`
      }
      // 额外增加的席位数
      this.mPayUrl += `&amc=${this.personNum-this.minNumber < 0 ? 0 : this.personNum-this.minNumber}&style=${this.nolifeStyle}`;
      // 埋点-来源模块、来源页面、uuid、用户id、用户会员状态、团队id、团队会员转台
      this.mPayUrl += `&uuid=${sessionStorage.getItem('uuid')}&user_id=${this.userInfo.userId}&team_id=${this.teamInfo.teamId}&team_vip=${this.teamInfo.adminUserVipLevel}`
      if (this.source.moduleName) {
        this.mPayUrl +=`&m=${this.source.moduleName}`
      }
      if (this.source.pageName) {
        this.mPayUrl +=`&p=${this.source.pageName}`
      }
      // 埋点 - 模板相关付费路径
      const resourceDataArr = Object.entries(this.resourceData);
      resourceDataArr.forEach((value) => {
        if (value[1]) {
          this.mPayUrl += `&rt_${value[0]}=${value[1]}`;
        }
      });
      clearTimeout(this.payStateTimer);
      // 支付状态轮询
      this.getPayState(this.mobPayToken, () => {
        sessionStorage.setItem("needToPay", this.needToPay)
        this.buriedPointPayPage()
        // 埋点, 支付成功后上报打开vipPop的浮层来源 (批量设计新增)
        // 为满足开发模板相关分发效率及转化数据统计需求
        // 所以需要在此处增加私有业务代码
        // 产品确认-张旺旺 2021.11.10
        this.pcTemplatePayment()
        if (this.selectedPackage.level === 212){
          window.gio("track", "vip_done_show", {
            vip_package_name: '通用版',
            vip_abtest: this.nolifeStyle,
            page_name: '会员购买成功弹框'
          });
        }
        sessionStorage.setItem("remainSeats",this.personNum-this.memberAmount>0?this.personNum-this.memberAmount:0)
        this.vipPopShow = false
        this.$emit('on-pay-success')
        if ( this.selectedPackage.level === 205){
          this.isShowVipSuccess && (this.vipSuccess = true)
        } else {
          this.handleBuySucceed()
        }
      });
      // 支付二维码刷新状态
      this.qrcodeExpired = false;
      if (this.qrcodeRefreshTimer) {
        clearTimeout(this.qrcodeRefreshTimer);
      }
      this.qrcodeRefreshTimer = setTimeout(() => {
        this.qrcodeExpired = true;
      }, 1000 * 60 * 10); // 十分钟后提示刷新
    },
    // 刷新二维码
    refreshQrcode() {
      if (this.userInfo.LoginTimeOut) {
        this.login();
        return;
      }
      this.applyWapMemberPayToken();
    },
    // 查询订单支付状态
    getPayState(token, func) {
      let self = this;
      const timer = () => {
        if (self.qrcodeExpired) return;
        self.$http
          .post("/vip/vip/memberOrder/getPayState", { pay_token: token, gw:true, GW: true})
          .then((res) => {
            let flag = true; // 设置检测标志
            if (res.body.code === 1) {
              func();
              flag = false;
              self.ocpc_360_report(); //360 - OCPC
            }
            clearTimeout(self.payStateTimer);
            if (flag) {
              self.payStateTimer = setTimeout(timer, 3000);
            }
          });
      };
      clearTimeout(self.payStateTimer);
      // 两秒后首次查询支付状态
      self.payStateTimer = setTimeout(timer, 2000);
    },
    //360 百度 OCPC统计
    ocpc_360_report () {
      try {
        let ckt_ocpc = localStorage.getItem("ckt_ocpc") || "";
        if (ckt_ocpc) {
          localStorage.setItem("ckt_ocpc", ckt_ocpc);
          const data = {
            event: "order",
            gw: true,
            GW: true 
          }
          if (/bd_vid/.test(ckt_ocpc)) {
            data.bdVidUrl = ckt_ocpc
          } else {
            data.qhClickId = ckt_ocpc
          }
          this.$http
            .post('/hatchery/ocpc/saveOcpcData', data)
            .then((res) => {
              const code = res.body.code; // 状态码：200 请求成功，-201 获取失败
              if (code === 200) {
                console.log("qhclickid ocpc success");
              } 
          });
        }
      } catch (e) {
        console.log("qhclickid null");
      }
    },
    // 支付成功
    async handleBuySucceed() {
      try {
        window.gio("track", "payment_succeed_popup", {
          popup_from: this.locationFlag
        });
      } catch (e) {
        console.error("gio error \n" + e);
      }
      const obj = {
        id: `${Date.now()}-${this.userInfo.userId}`,
        total: Number(sessionStorage.getItem("needToPay")) / 100,
        sku: this.choosePackageId,
        name: encodeURIComponent(this.selectedPackage.name),
        price: this.selectedPackage.discountPrice / 100,
        level: this.selectedPackage.level,
      };
      let href = "/vipsuccess";
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          href += href.includes("?") ? "&" : "?";
          href += `${key}=${obj[key]}`;
        }
      }
      this.vipSuccess = false
      this.vipPopShow = false
      window.open(href);
      window.location.reload();
    },
    buriedPointPayPage() {
      try {
        window.gio("track", "ckt_pay", {
          is_renew_sku: this.isSignPackage ? '是' : '否', //是否为签约套餐
          pay_position: "pc", // 支付平台
          upgrade_type: '延期升级', // 升级类型
          payPage: GIO_VIP_PAYPAGE[location.pathname] !== undefined ? GIO_VIP_PAYPAGE[location.pathname]:'其他'
        });
      } catch (e) {
        console.error("gio error \n" + e);
      }
    },
    // 监听支付成功后上报数据至公司接口
    // 为满足开发模板相关分发效率及转化数据统计需求
    async pcTemplatePayment  () {
      try {
        if (!this.userInfo.isCkt) return
        let member_purchase = this.selectedPackage.level === 205 ? `企业会员-${this.selectedPackage.name}` : `通用版-${this.selectedPackage.name}`
        let uniqueid = window.uniqueid || ''
        let searchArrToObj = {}
        if (location.search) {
          let searchStr =  location.search.substr(1)
          let searchArr = searchStr.split("&")
          searchArr.forEach( item => {
            let itemContentArr = item.split('=')
            searchArrToObj[itemContentArr[0]] = itemContentArr[1]
          })
        }
        let paramsObj = {
          member_purchase,
          payment_amount: Number(sessionStorage.getItem("needToPay")),
          payment_time: new Date().getTime(),
          design_id: searchArrToObj.d,
          unique_identification: uniqueid,
          GW: true
        }
        await this.$http.post('/contentcopyright/templateDateCollectController/pcTemplatePayment', { ...paramsObj })
      } catch (err) {
        console.error('上报埋点信息至公司报错：' + err)
      }
    },
    questionList(){
      let host = location.host
      if(location.host.includes('mater')){
        host = 'www.chuangkit.com'
      }
      window.open(`//${host}/price/vip#legalCompare`)
    },
    contactService() {
      try {
        const userId = this && this.userInfo && this.userInfo.userId || '未登录'
        const nickname =this && this.userInfo && this.userInfo.nickname || '未登录'
        const vipLevelName = this && this.userInfo && this.userInfo.vipLevelName || '普通用户'
        const time = new Date().getTime()
        const signature = CryptoJS.SHA1(`nonce=${time}&timestamp=${time}&web_token=${userId}&1e15f01a61353b917f3f40df371d44d1`).toString().toUpperCase();
        ud('init')
        ud({
          'customer': {
            "nonce": time,
            "signature": signature,
            "timestamp": time,
            "web_token": userId,
            "im_user_key": '1e15f01a61353b917f3f40df371d44d1',
            "c_cf_用户ID": userId,
            "c_cf_用户昵称": nickname,
            "c_cf_用户会员": vipLevelName,
            "c_cf_来源入口": 'web_会员弹框_联系客服'
          }
        })
        ud('showPanel')
      } catch (e) {
        console.log(e, 'init udesk api is error')
      }
    },
    getTrackObj(){
      const desktopAppData = window._WUFA_TRACKER_DATA_ || {}
      let event_track_obj={
          ocpc_flag: '',
          ocpc_source: '',
          ocpc_time: '',
          utm_source: '',
          utm_medium: '',
          utm_campaign: '',
          utm_term: '',
          utm_content: '',
          ...desktopAppData
        }
        const ckt_ocpc = localStorage.getItem("ckt_ocpc") || ""; //360 百度 ocpc
        const ckt_ocpc_time = localStorage.getItem("ckt_ocpc_time") || ""; // ckt_ocpc_time
        let opcp_obj = {}
        const ckt_ocpc_url = localStorage.getItem("ckt_ocpc_url") || ""; //360 百度 ocpc
        const ckt_ocpc_utmState = localStorage.getItem("ckt_utm_source") || ""; // ckt_ocpc_time
        const reg_utm_source = new RegExp('(^|/?|&)utm_source=([^&]*)(&|$)', 'i')
        const reg_utm_medium = new RegExp('(^|/?|&)utm_medium=([^&]*)(&|$)', 'i')
        const reg_utm_campaign = new RegExp('(^|/?|&)utm_campaign=([^&]*)(&|$)', 'i')
        const reg_utm_term = new RegExp('(^|/?|&)utm_term=([^&]*)(&|$)', 'i')
        const reg_utm_content = new RegExp('(^|/?|&)utm_content=([^&]*)(&|$)', 'i')
        const search_ocpc_url = ckt_ocpc_url.substr(1)
        let params_utm_source = ''
        let params_utm_medium = ''
        let params_utm_term = ''
        let params_utm_campaign = ''
        let params_utm_content = ''
        search_ocpc_url && search_ocpc_url.match(reg_utm_source)&& (params_utm_source = search_ocpc_url.match(reg_utm_source)[2])
        search_ocpc_url && search_ocpc_url.match(reg_utm_medium)&&(params_utm_medium = search_ocpc_url.match(reg_utm_medium)[2])
        search_ocpc_url && search_ocpc_url.match(reg_utm_campaign)&&(params_utm_campaign = search_ocpc_url.match(reg_utm_campaign)[2])
        search_ocpc_url && search_ocpc_url.match(reg_utm_term)&&(params_utm_term = search_ocpc_url.match(reg_utm_term)[2])
        search_ocpc_url && search_ocpc_url.match(reg_utm_content)&&(params_utm_content = search_ocpc_url.match(reg_utm_content)[2])
        event_track_obj.utm_source = decodeURI(params_utm_source)
        event_track_obj.utm_medium = decodeURI(params_utm_medium)
        event_track_obj.utm_campaign = decodeURI(params_utm_campaign)
        event_track_obj.utm_term = decodeURI(params_utm_term)
        event_track_obj.utm_content = decodeURI(params_utm_content)
        event_track_obj.ocpc_time = ckt_ocpc_time+ ''
        if (ckt_ocpc_utmState) {
          event_track_obj.ocpc_flag= 'empty'
          event_track_obj.ocpc_source= event_track_obj.utm_source
        }
        if (ckt_ocpc && ckt_ocpc.indexOf("bd_vid=") > -1) {
          opcp_obj["bdVidUrl"] = ckt_ocpc
          let params = ''
          const reg = new RegExp('(^|/?|&)bd_vid=([^&]*)(&|$)', 'i')
          const search = ckt_ocpc.substr(1)
          search && (params = search.match(reg)[2])
          event_track_obj.ocpc_flag= params
          event_track_obj.ocpc_source='百度ocpc'
        }
        if (ckt_ocpc && ckt_ocpc.indexOf("bd_vid=") == -1){
          opcp_obj["qhClickId"] = ckt_ocpc
          event_track_obj.ocpc_source='360ocpc'
          event_track_obj.ocpc_flag= ckt_ocpc
        }
        return event_track_obj
    },
    async reportDataStore(type){
      try {
        let collectBaseData = {
            gw:true,
            isInclude: true,
            GW: true,
            nottrans: true,
            jsonformat: true,
            isNewInterface: true,
            header: this.baseHeader(),
            event:{
              event:type==='payShow'?'vip_pay_show':'vip_rights_show',
              event_name: type==='payShow'?'会员页支付弹框曝光':'VIP权益介绍弹框曝光',
              trigger_timing: type==='payShow'?'会员页支付弹框曝光（前端打点）':'VIP权益介绍弹框曝光（前端打点）',
              params:{
                forward_module_name: this.source.moduleName,
                forward_page_name: this.source.pageName,
                popup_name: type==='payShow'?'VIP购买弹框':'VIP权益介绍弹框',
                vip_abtest: this.nolifeStyle,
                from: this.source.from,
                is_login: '是'
              }
            }
          }
        //数据上报数仓
        await this.$http.post("/dw/collector/sendMessageEvent", collectBaseData, {headers: {'Content-Type':'application/json'}})
        .then((res) => {
          console.log(res)
        })
      } catch (error) {
        console.log(error)
      }
    },
  },
  beforDestory() {
    clearTimeout(this.timer)
    clearTimeout(this.couponTimer)
    clearTimeout(this.payStateTimer);
    clearTimeout(this.qrcodeRefreshTimer);
  },
};
</script>
<style lang="less" scoped>
@import "vipPop";
.vip-pop-modal /deep/ .lt-modal-content{
  border-radius: 16px;
}
@media screen and (max-height: 640px) {
  .vip-pop-modal /deep/ .lt-modal-wrap{
    align-items:flex-start;
  }
}
.vip-pop-modal{
  height: 642px;
}
//人数及时间选项
.package-person-time{
  display:flex;
  margin-bottom: 16px;
  .title{
    color: #505A71;
    font-family: PingFang SC;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    display: flex;
    align-items: center;
  }
  .choose-box{
    display: flex;
    margin-left: 40px;
    ul{
      margin-bottom: 0;
    }
    .num-button{
      width: 32px;
      height: 32px;
      font-size: 22px;
      border-radius: 6px;
      cursor: pointer;
      color: #1B2337;
      text-align: center;
      background-color: #F3F4F9;
      &:hover{
        background-color: #EBEEF5;
      }
    }
    .decrease{
      margin-right: 8px;
      line-height: 27px;
    }
    .increase{
      margin-left: 8px;
      line-height: 27px;
    }
    .disable{
      background-color: #F3F4F9;
      color: #8693AB;
      cursor: not-allowed;
      &:hover{
        background-color: #F3F4F9;
      }
    }
    .input-box{
      width: 100px;
      height: 32px;
      border: 1px solid #EBEEF5;
      border-radius: 6px;
      &:hover{
        border-color: #0773FC;
      }
      input{
      cursor: pointer;
      background: transparent;
      width: 100%;
      height: 100%;
      color: #1B2337;
      font-weight: bold;
      border:0;
      outline:0;
      text-align: center;
      }
      input::-webkit-input-placeholder{
        color:#1B2337;
      }
      input:focus::-webkit-input-placeholder{
      color: transparent;
      }
      input:disabled{
        color:rgba(27, 35, 55, 0.2);
      }
    }
    ul{
      display: flex;
      align-items: center;
      justify-content: space-around;
      background: #F5F7FD;
      border-radius: 8px;
      display: flex;
      height: 40px;
      padding: 4px;
      li{
        cursor: pointer;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 118px;
        height: 32px;
        border-radius: 8px;
        color: #1B2337;
        font-family: PingFang SC;
        font-size: 12px;
        &:not(:first-child){
          margin-left: 8px;
        }
        div{
          width: 100%;
          height: 34px;
          line-height: 34px;
        }
        &.active{
          div {
          border-radius: 3px;
          font-weight: bold;
          &.person{
            background: #0A5DFD;
            color: #fff;
            input{
              color: #fff;
            }
          }
          &.company{
            width: 118px;
            font-weight: 500;
            font-size: 14px;
            background: #FCDAC1;
            color: #6B3D1E;
            border-radius: 8px;
            input{
              color: #6B3D1E;
            }
          }
          }

        }
        &.forbidden{
          color: rgba(27, 35, 55, 0.2)
        }
      }
      &.right{
        display: none;
        margin-left: 12px;
      }
    }
  }
  .num{
    margin-left: 40px;
  }
  .body{
    margin-left: 27px;
  }
}
.sign-modal-show{
  width: 395px;
  height: 250px;
  padding:24px;
  position: relative;
  .close-icon{
    cursor: pointer;
    position: absolute;
    top: 25px;
    right: 25px;
  }
  .title{
    padding-bottom: 32px;
    color: #1B2337;
    font-family: PingFang SC;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
  }
  li{
    position: relative;
    padding-left: 20px;
    color: #1B2337;
    font-family: PingFang SC;
    font-weight: regular;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    &:last-child{
      margin-top: 12px;
    }
  }
  .btn{
    margin: 0 auto;
    margin-top: 32px;
    border-radius: 4px;
    background: #0773FC;
    width: 120px;
    height: 40px;
    color: #FFFFFF;
    font-family: PingFang SC;
    font-weight: regular;
    font-size: 14px;
    line-height: 40px;
    letter-spacing: 0px;
    text-align: center;
    cursor: pointer;
    &:hover{
      background: #0667E2;
    }
  }
}
.close-vip-success{
  width: 495px;
  height: 284px;
  padding:24px;
  position: relative;
  .close-icon{
    cursor: pointer;
    position: absolute;
    top: 25px;
    right: 25px;
  }
  .title{
    padding-bottom: 32px;
    color: #1B2337;
    font-family: PingFang SC;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
  }
  li{
    position: relative;
    padding-left: 20px;
    color: #1B2337;
    font-family: PingFang SC;
    font-weight: regular;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    &:before {
      position: absolute;
      left: 10px;
      top: 6px;
      content: "";
      width: 4px;
      height: 4px;
      background-color: #1B2337;
      border-radius: 50%;
    }
    &:last-child{
      margin-top: 12px;
    }
  }
  .btn{
    margin: 0 auto;
    margin-top: 32px;
    border-radius: 4px;
    background: #0773FC;
    width: 120px;
    height: 40px;
    color: #FFFFFF;
    font-family: PingFang SC;
    font-weight: regular;
    font-size: 14px;
    line-height: 40px;
    letter-spacing: 0px;
    text-align: center;
    cursor: pointer;
    &:hover{
      background: #0667E2;
    }
  }
}
</style>
