// 正在售卖的企业会员level
export const SELL_COMPANY_VIP = [125, 130, 135, '135_10'];

// 所有的VIP等级
export const VIP_VERSION = {
  0: '普通用户',
  1: '企业VIP-基础版',
  2: '协作会员',
  3: '高级团队VIP',
  4: '个人版',
  5: '企业VIP-标准团队版',
  6: '企业专业版VIP',
  7: '企业VIP-加强版',
  8: '企业VIP-基础团队版',
  9: '企业VIP-全用途版',
  100: '基础版VIP',
  105: '全用途版VIP',
  110: '标准团队版VIP',
  115: '高级团队版VIP',
  120: '企业专业版',
  125: '新媒体商户版',
  130: '全用途商户版',
  135: '团队协作版',
  201: '个人高级版',   
  202: '企业高级版',
  203: '高级版',
205: '协作版',
  204: '高级版（大企业）',
  205: '协作版',
  220: '旗舰版',
  199: '标准版（个人）',
  200: '标准版（企业）',
  210: '标准版（大企业）',
  211: "通用版（个人）",
  212: "通用版（企业）",
  '135_10': "团队协作10人版"
};
// gio埋点会员版本枚举
export const GIO_VIP_VERSION = {
  4: "个人版",
  125: "企业新媒体商户版",
  130: "企业全用途商户版",
  135: "企业团队协作版",
  199: '标准版（个人）',
  200: '标准版（企业）',
  211: "通用版（个人）",
  212: "通用版（企业）",
  201: '个人高级版',
  202: '企业高级版',
  203: '高级版',
205: '协作版',
  205: '协作版',
  220: '旗舰版'
}
// payPage枚举
export const GIO_VIP_PAYPAGE = {
  "/price/vip": "价格页",
  "/design": "编辑页",
  "/odyssey/design":"编辑页",
  "/pic/image":"图片编辑页",
  "/activity618": '618活动页'
}
/**
 * 会员升降级规则顺序
 * 个人版VIP(4)<标准版VIP(1)<新媒体商户版(125)<标准团队版(8)<加强版VIP(7)<协作版VIP(2)
 * <全用途版(9)<全用途商户版(130)<加强版VIP(5)<高级VIP(3)<团队协作版(135)
 */
export const LEVEL_RULES = [0, 4, 1, 125, 8, 7, 2, 9, 130, 5, 3, 135];

// 会员权益列表
export const INTRO_FEATURE = [
  {
    title: "50万+精品模板",
    desc:
      "不会PS也能轻松做图！平台海量精品模板，独家买断特邀设计师素材版权，涵盖节日热点/营销文案/活动宣传等众多场景和行业，每月实时更新模板1000+。极简的操作界面，仅需拖拽让工作效率飞起来。",
    video:
      "https://eyuankupub-cdn-oss.chuangkit.com/sys/front/video/price_video_template.mp4",
  },
  {
    title: "400+款正版字体",
    desc:
      "还在为字体商用侵权发愁？数百款正版字体尽在创客贴，一对一客服在线授权，线上线下商业使用零风险。精选常用+多种风格字体，只为满足你的各种设计风格。",
    video:
      "https://eyuankupub-cdn-oss.chuangkit.com/sys/front/video/price_video_font.mp4",
  },
  {
    title: "8000万+正版图片",
    desc:
      "想要快速找到可商用的好图片？答案就在创客贴。在这里全球优质创意图片应有尽有，图库每日更新。依然正版版权保障，为你安心做市场推广省心省力。推荐适配中小型企业，互联网企业及新媒体工作等。",
    video:
      "https://eyuankupub-cdn-oss.chuangkit.com/sys/front/video/price_video_picture.mp4",
  },
  {
    title: "团队模板",
    desc:
      "团队模板是帮助团队合作的一项突破性功能，精简设计流程，提升你和团队成员的工作效率。支持生成团队素材库，云存储模板实时同步，成员可随时调用。适用多人团队、分公司、分销商等。",
    video:
      "https://eyuankupub-cdn-oss.chuangkit.com/sys/front/video/vipPop_video_teamTemplate.mp4",
  },
  {
    title: "品牌管理",
    desc:
      "帮你一键调用品牌颜色、字体和LOGO，实现高效的品牌管理！极简的操作，支持设置上传你的企业VI视觉系统，设计过程中可便捷选用，便于你随时保持企业形象的规范统一。",
    video:
      "https://eyuankupub-cdn-oss.chuangkit.com/sys/front/video/vipPop_video_brand.mp4",
  },
  {
    title: "智能变换尺寸",
    desc:
      "使用我们的一步式调整大小工具，可以为你节省时间。比如海报、新媒体配图、印刷物料、电商设计等众多场景近百种模板尺寸，可以随心切换，一键生成，便于你灵活的使用设计满足不同场景的使用需要。",
    video:
      "https://eyuankupub-cdn-oss.chuangkit.com/sys/front/video/vipPop_video_size.mp4",
  },
  {
    title: "多人协作",
    desc:
      "新一代多人协作功能，可以实现多个用户对同一项目进行协同编辑及管理。不同时间不同地点的团队协作，释放团队创造力。适配多人团队、分公司、分销商等，快邀请你的团队成员一起使用吧！",
    video:
      "https://eyuankupub-cdn-oss.chuangkit.com/sys/front/video/vipPop_video_cooperate.mp4",
  },
  {
    title: "超大上传空间",
    desc:
      "不用为“上传速度慢”“存储空间已满”再烦恼啦！3G-50G的上传空间，更多资料也不怕，超大文件轻轻松松上传。",
    video:
      "https://eyuankupub-cdn-oss.chuangkit.com/sys/front/video/vipPop_video_upload.mp4",
  },
  {
    title: "成员管理",
    desc:
      "为避免因分工不同给工作带来的麻烦，新功能成员管理支持各个等级设置不同权限，控制每个角色的权限操作。比如，只有“设计师”可以操作设计文件，“成员”仅支持共享资源和随时调用。这些在你的成员管理界面都可以便捷设置。",
    video:
      "https://eyuankupub-cdn-oss.chuangkit.com/sys/front/video/vipPop_video_menber.mp4",
  },
  {
    title: "批量设计",
    desc:
      "帮你实现同样式设计的批量生成，只要选好已编辑好的模板样式，标注出该模板内哪些元素需要替换，就可以批量导入这些替换元素的内容，一键应用模板样式生成设计。",
    video:
      "https://eyuankupub-cdn-oss.chuangkit.com/sys/front/video/vipPop_video_batch.mp4",
  },
];

// 支付弹窗切换tab
export const VIP_TAB = [
  {
    title: "通用版",
    range: "（1人使用）",
  },
  {
    title: "协作版",
    range: "（2-10人使用）",
  },
  // {
  //   title: "旗舰版",
  //   range: "（10人以上使用）",
  // },
];

// 最佳推荐套餐
export const BEST_VIP = {
  title: "团队协作版",
  range: ["1-10人团队协作"],
  advantage: ["·线上+线下全商用", "·团队素材/模板"],
};

// 最佳推荐套餐
export const PAY_WAY = [
  {
    key: 0,
    value: "快捷支付",
  },
  {
    key: 1,
    value: "余额支付",
  },
  {
    key: 2,
    value: "对公转账",
  },
];

export const PREMIUM_TYPE_LIST = [
  {
    type: 0,
    title:'企业商用'
  },
  {
    type: 1,
    title: '大型企业商用'
  }
]

// 个人会员权益简介
export const PERSONAL_FEATURE = [
  [
    "授权给个人商用",
    "50万+模板免费下载",
    "8000万+正版图免费下载",
    "800+正版字体免费用",
    "智能抠图【不限次】使用",
    "图片编辑免费使用",
    "视频剪辑免费使用",
    "正规发票和授权书"
  ],
  [
    "授权范围",
    "个人新媒体用途",
    "个人电商用途",
    "个人线上推广",
    "个人线下印刷",
  ],
];

// 企业会员（新媒体商户版）权益简介
export const BASIC_FEATURE = [
  [
    "授权给企业商用",
    "*适合公司1人线上使用"
  ],
  [
    "授权范围",
    "企业新媒体用途",
    "网站及应用程序"
  ],
];

// 企业会员（全用途商户版）权益简介
export const COMPANY_FEATURE = [
  [
    "授权给企业商用",
    "*适合公司1人线上线下全用途使用"
  ],
  [
    "授权范围",
    "企业新媒体用途",
    "网站及应用程序",
    "企业电商用途",
    "企业线上推广",
    "企业线下印刷"
  ],
];

// 团队协作版5-10人、团队协作版10人
export const COMPANY_MORE = [
  [
    "授权给企业商用",
    "*适合公司多人线上线下全用途使用",
    "*支持多人同时登录",
    "*支持上传团队协作模版，素材，在线存储重复使用",
    "*让设计师和用图部门时间成本降10倍，效率升10倍"
  ],
  [
    "授权范围",
    "企业新媒体用途",
    "网站及应用程序",
    "企业电商用途",
    "企业线上推广",
    "企业线下印刷"
  ]
]

// 埋点需要 - 下单来源页面
export const ORDER_SOURCE = {
  "/price/personal": 1, // 个人价格页
  "/price/company": 2, // 企业价格页
  "/price/vip": 3, // 新版会员页
  "/accountcenter": 4, // 账户中心
  "/design": 0, // 编辑页面
};


// 新版个人和企业套餐展示
export const PERSON_RIGHT = [
  "个人商用授权 * 1个",
  "个人线上+线下商用授权",
  "海量VIP专享模板素材",
  "高清无水印下载",
  "智能尺寸调整",
  () => `不限张智能抠图<span style="color: #fa2323">(限免)</span>`,
  () => `AI创作220贴贴/月<span style="color: #fa2323">(限赠)</span>`,
  "60G云存储空间",
  "云设计服务专享折扣券",
]
export const COMPANY_RIGHT = [
  "个人/企业商用授权*1个",
  // () => `不限次高清<span style="color: #6B3D1E">无水印</span>下载`,
  // () => `<span style="color: #6B3D1E">不限张</span>智能抠图`,
  "线上+线下商用授权",
  "海量VIP专享模板素材",
  "高清无水印下载",
  "智能尺寸调整",
  () => `不限张智能抠图<span style="color: #fa2323">(限免)</span>`,
  () => `AI创作220贴贴/月<span style="color: #fa2323">(限赠)</span>`,
  "60G云存储空间",
  "云设计服务专享折扣券",
]
export const BIG_COMPANY_RIGHT = [
  "大型企业商用授权*1个",
  // () => `不限次高清<span style="color: #6B3D1E">无水印</span>下载`,
  // () => `<span style="color: #6B3D1E">不限张</span>智能抠图`,
  "线上+线下商用授权",
  "海量VIP专享模板素材",
  "高清无水印下载",
  "智能尺寸调整",
  () => `不限张智能抠图<span style="color: #fa2323">(限免)</span>`,
  () => `AI创作220贴贴/月<span style="color: #fa2323">(限赠)</span>`,
  "60G云存储空间",
  "云设计服务专享折扣券",
]
export const COMPANY_RIGHT_MORE = [
  "企业商用授权*1个（可加购） ",
  // () => `不限次高清<span style="color: #6B3D1E">无水印</span>下载`,
  // () => `<span style="color: #6B3D1E">不限张</span>智能抠图`,
  "线上+线下商用授权",
  "团队协作共享/批注/评论",
  "品牌LOGO/颜色等管理",
  "智能设计/批量设计",
  () => `不限张智能抠图<span style="color: #fa2323">(限免)</span>`,
  () => `AI创作450贴贴/人/月<span style="color: #fa2323">(限赠)</span>`,
  "600G/团队 云存储空间",
  "云设计专享折扣",
]
export const BIG_COMPANY_RIGHT_MORE = [
  "大型企业商用授权*1个（可加购） ",
  // () => `不限次高清<span style="color: #6B3D1E">无水印</span>下载`,
  // () => `<span style="color: #6B3D1E">不限张</span>智能抠图`,
  "线上+线下商用授权",
  "团队协作共享/批注/评论",
  "品牌LOGO/颜色等管理",
  "智能设计/批量设计",
  () => `不限张智能抠图<span style="color: #fa2323">(限免)</span>`,
  () => `AI创作450贴贴/人/月<span style="color: #fa2323">(限赠)</span>`,
  "600G/团队 云存储空间",
  "云设计专享折扣",
]
export const FLAGSHIP_RIGHT = [
  "大型企业商用授权 (可定制)",
  // () => `不限次高清<span style="color: #6B3D1E">无水印</span>下载`,
  // () => `<span style="color: #6B3D1E">不限张</span>智能抠图`,
  "团队协作账号数（可定制）",
  "内容合规审批管理",
  "自定义企业LOGO等",
  "敏感词检测更安全",
  "多品牌管理灵活配置",
  "1V1专属服务团队",
  "AI创作体验贴贴数定制",
  "100G云存储空间（可加购）",
  "云设计专享折扣"
]

// 选择套餐人数
export const PERSON_NUM = [
  {
    title: "1人",
    type: 1
  },
  {
    title: '5人',
    type: 5
  },
  {
    title: "10人",
    type: 10
  },
  {
    title: "20人",
    type: 20
  },
]

export const COMPONY_PERSON_NUM = [
  // {
  //   title: "2人",
  //   type: 2
  // },
  {
    name: '2人',
    title:  () => `2人`,
    type: 2
  },
  {
    name: '5人',
    title:  () => `5人`,
    type: 5
  },
  {
    name: "10人",
    title:  () => `10人`,
    type: 10
  },
]
// 按年还是按月套餐
export const TIME_TYPE = [
  {
    title: "按年买",
    type: 0
  },
  {
    title: '按月买',
    type: 1
  }
]