<template>
<div>
  <div class="vip-pop-pay-content-packages">
    <!-- 全套餐 -->
    <ul class="package-list">
      <li class="package-cell">
      </li>
            <li class="package-cell">
      </li>
            <li class="package-cell">
      </li>
            <li class="package-cell">
      </li>
    </ul>
  </div>
  
</div>
</template>

<script>
export default {
  name: "PackageHolder",
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style lang="less" scoped>
@import url("../common/common.less");
@count: var(--count);
.vip-pop-pay-content-packages {
  padding: 12px 24px 0;
  .package-list {
    .flex(flex-start);
    height: 166px;
    li {
      .flex(space-between, center, column);
      width: 151px;
      height: 168px;
      background: #f8f8fb;
      border-radius: 4px;
      color: @fontColor;
      cursor: pointer;
      &:not(:last-child) {
        margin-right: 15px;
      }
    }
  }
}
</style>
