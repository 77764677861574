<template>
  <div
    class="headWrap"
    :class="{
      index: isIndex && scrollTop === 0,
      'index-fixed': isIndex && scrollTop > 0,
    }"
  >
    <!-- 新年活动入口 -->
    <div v-show="activityEntryShow && !isSvip" class="activityEntryBox">
      <img :src="activityPicUrl" alt="" class="activityImg" @click="toActivityPage" @load="loadImg" />
      <img class="closeBtn" @click="closeActivityEntry" src="./img/close.svg" alt="" />
    </div>
    <!-- 试用版到期提示条 -->
    <!-- <ExpireTips
      :vipTrailExpireDate="vipTrailExpireDate"
      :teamId="teamId"
      :noticeList="noticeList"
      :teamName="decodeURIComponent(teamInfo.teamName)"
      @logReg="logReg"
      @change="(val)=> {$emit('trial-expired-tips-change', val)}"
    /> -->
    
    <header id="header">
      <div class="logo-wrap">
        <!-- SEO 需求 -->
        <div class="collectionWrap">
          <a class="logo-img" href="/designtools/designindex" :class="{ show: isShowLogo }" :style="customLogoStyle">
            <!-- SEO 需求 -->
            <span class="undis">创客贴</span>
          </a>
          <template v-if="isWebPage">
            <span class="collect-dot"></span>
            <div class="collectionImg">拖动 LOGO 到书签栏，立即收藏创客贴</div>
          </template>
        </div>
      </div>
      <!-- 分隔线 -->
      <span class="divide-line" :style="{display:isLogin?'inline-block':'none'}"></span>
      <div v-if="isLogin" :class="isLogin ? 'changeTeam_warp' : 'changeTeam_warp_nologin'" @mouseenter="showChange(1)" @mouseleave="showChange(2)">
        <div class="team-header" v-if="isLogin">
          <div class="team-header_left">
            <span class="team_name-title" v-if="!canCreateFreeTeam || teamInfo.teamRoleEnum != 'Administrator'">{{getTeamName}}</span>
            <span v-else class="team_name-title">个人版</span>
          </div>
          <div class="team-header_right"></div>
        </div>
        <ChangeTeamModal
          :userInfo="userInfo"
          :teamInfo="teamInfo"
          :isLogin="isLogin"
          :isShowHeader="true"
          :canCreateFreeTeam="canCreateFreeTeam"
          @getUserInfo="getUserInfoFun"
          @createModal="createModal"
          ref="changeTeamModal"
        />
      </div>
      <ul class="nav-list" v-if="dataLoaded" :style="{ marginLeft: isLogin ? '25px' : '105px' }">
        <li
          v-for="value in navData(isShowSearchBar)"
          :key="value.href"
          :id="value.key"
          :class="navLiCls(value)"
          class="nav-list-item"
          @click="buriedPoint(value)"
        >
          <a v-if="value.href" :class="{ tempkey: value.des == '模板中心' }" :style="{color:getFontColorByRoute}">{{ value.des }}</a>
          <!-- vip hover菜单 -->
          <!-- <div v-if="value.des == 'VIP'" class="vip-wrap">
            <hoverVip/>
          </div> -->
          <!-- 定制设计角标 -->
          <div v-if="value.des === '定制设计'" class="design-mark"></div>
          <!-- 模板中心hover菜单 -->
          <div v-if="value.des === '模板中心'" class="temp-key">
            <tempKey :selected-block-list="selectedBlockList" :is-index="isIndex" />
          </div>
          <a v-if="value.des === '功能介绍'">{{ value.des }}</a>
          <div v-if="value.des == '功能介绍'" class="seo-wrap">
            <Seo />
          </div>
          <span v-if="value.des === '更多'" class="show-more">
            <LtIcon type="more" class="arrow-dropdown" size="32" :style="{color:getFontColorByRoute}"/>
          </span>
          <!-- 活动角标 -->
          <!-- <span v-if="value.des === '个人VIP'" class="activity-personal-icon">8元/月</span>
          <span v-if="value.des === '企业VIP'" class="activity-business-icon">低至4.5折</span> -->
          <!-- 更多下拉菜单 -->
          <ul v-if="value.des === '更多'" class="show-more-list">
            <li v-for="item in moreDropList(isDamState)" :key="item.des" @click="burePoint(item)">
              <a :href="jumpCheck(item.href)" target="_blank">{{ item.des }}</a>
            </li>
          </ul>
          <!-- 客户端二维码 -->
          <div v-if="value.des == '下载APP'" class="client-dropdown">
            <div class="app-download-code"></div>
            <div class="wel-img"></div>
            <div class="des des-one">同一账号，设计多端保存</div>
            <div class="des des-two">随时随地，开启高效设计</div>
          </div>
          <!-- vip下列列表 -->
          <div v-if="value.des.includes('VIP') && value.des !== 'VIP'" class="vip-pop" :class="{ company: value.des === '企业VIP' }">
            <div v-if="value.des === '个人VIP'">
              <div class="title">加入个人VIP，即可享受</div>
              <!-- <div class="activity-personal-vip-hover-icon"></div> -->
              <ul>
                <li v-for="item in PERSONAL_HOVER_LIST" :key="item.title">
                  <span class="img" :style="{ backgroundPosition: `${item.img.left}px ${item.img.top}px` }"></span>
                  <div>
                    <div class="text">{{ item.title }}</div>
                    <div class="subtext">{{ item.subtitle }}</div>
                  </div>
                </li>
              </ul>
              <!-- 企业VIP不能降级个人VIP -->
              <span
                v-if="!userInfo.userId || userInfo.vipLevel === 0 || userInfo.vipLevel === 4"
                class="btn-buy-now"
                :class="{ personal: true }"
                @click.stop="buyNow(35, 4)"
              >{{ userInfo.vipLevel > 0 ? "立即续费" : "开通会员" }}</span
              >
            </div>
            <div v-else>
              <div class="title">加入企业VIP，即可享受</div>
              <!-- <div class="activity-business-vip-hover-icon"></div> -->
              <ul>
                <li v-for="item in BUSINESS_HOVER_LIST" :key="item.title">
                  <span class="img" :style="{ backgroundPosition: `${item.img.left}px ${item.img.top}px` }"></span>
                  <div>
                    <div class="text">{{ item.title }}</div>
                    <div class="subtext">{{ item.subtitle }}</div>
                  </div>
                </li>
              </ul>
              <span class="btn-buy-now" @click.stop="buyNow(36, 100)">{{ superVipLevelArr.includes(userInfo.vipLevel) ? "立即续费" : "开通会员" }}</span>
            </div>
          </div>
        </li>
      </ul>
      
      <!-- search -->
      <slot></slot>
      
      <!-- 企业微信团队授权信息填写入口 -->
      <slot name="authorization"></slot>
      <!-- 老带新入口 -->
      <!-- <a :href="jumpCheck('/invitation')" class="active_entrance"></a> -->
      <template v-if="!isSvip">
        <!-- 开通会员按钮 -->
        <div class="open-vip_warp"  @mouseenter="showVipModal(1)" @mouseleave="showVipModal(2)">
          <div class="open-vip-button" @click="handleOpenVipClick" :style="{ width: showExtraInfo ? '104px' : '40px' ,padding:showExtraInfo?'0 12px':'0 10px'}">
            <span class="vip-icon"></span>
            <span class="vip-icon-test" v-show="showExtraInfo">VIP会员</span>
            <img src="./img/free_icon.svg" v-if="showVipFree" />
            <!-- 5折角标 -->
            <span class="red-tips" v-if="(modalData.switchStatus===2||modalData.switchStatus===3) && !halfPriceTimeOut">{{modalData.subscriptInfo}}</span>
          </div>
          <VipLevelModal :showActiveIcon="showActiveIcon" :teamInfo="teamInfo"  :vipLevelModalState="vipLevelModalState"  @rights-buy-pop="(e)=>{this.goBuyVip(e)}" :modalData="modalData" :halfPriceTimeOut="halfPriceTimeOut"/>
        </div>
        <!-- 企业服务入口 -->
        <div v-if="!isLenovo" class="enterprise-service-warp" @mouseenter="showEnterpriceService(1)" @mouseleave="showEnterpriceService(2)">
          <div class="enterprise-service-btn" :style="{ width: showExtraInfo ? '108px' : '40px'}">
            <span class="enterprise-service-icon"></span>
            <span class="enterprise-service-title" v-show="showExtraInfo">企业服务</span>
          </div>
          <EnterpriceServiceComponent @enetrExtraInfo="enterPrise" :enterpriceServiceState="enterpriceServiceState"/>
        </div>
      </template>
      <!-- 企业版入口 -->
      <!-- <div class="enterprise-entrance" @click="enterPrise" v-show="showExtraInfo">企业版</div> -->
      <div v-if="!isLogin" style="margin: 0 -20px 0 20px">
        <QuickDesign :userInfo="userInfo" :teamInfo="teamInfo" :teamId="teamId" :isLogin="isLogin">
          <div id="quick-create-design-btn" class="header-start-design">创建设计</div>
        </QuickDesign>
      </div>
      
      <!-- user-bar -->
      <div v-if="isLogin" class="user-bar">
        <UserBar
          :route="route"
          :userInfo="userInfo"
          :isIndex="isIndex"
          :isFuseNewTeam="isFuseNewTeam"
          :teams="teams"
          :teamInfo="teamInfo"
          :normalNotice="normalNotice"
          :scrollTop="scrollTop"
          :isWorkWeixinUser="isWorkWeixinUser"
          :wwAuthUser="wwAuthUser"
          :isLogin = "isLogin"
          :modalData="modalData"
          :halfPriceTimeOut="halfPriceTimeOut"
          :messageCountInfo = "messageCountInfo"
          @logout="$emit('logout')"
          @getUserInfo="$emit('getUserInfo')"
          @getVipDetail="$emit('getVipDetail')"
          @messageShow="$emit('message-show')"
          @setwwAuthUser="setwwAuthUser"
          @createModal="createModal"
          @clearCreateDesignTime ="setClearCreateDesignTime"
        >
          <!-- <QuickDesign :userInfo="userInfo" :teamInfo="teamInfo" :teamId="teamId"  :isLogin="isLogin" ref="clearCreateDesign">
            <div id="quick-create-design-btn" class="header-start-design">创建设计</div>
          </QuickDesign> -->
        </UserBar>
        <div v-show="showTaskEntry" class="gift-pack" @click="logReg(1)"></div>
      </div>
      <div v-if="!isLogin && showLoginEntry" class="login">
        <!-- <span class="avatar-icon" @click="logReg(1,'页顶_右侧未登录头像')"></span> -->
        <span class="avatar-text">
          <a rel="nofollow" @click="logReg(1, '页顶_右侧登录注册文字')" :style="{backgroundColor:getBackColorByRoute}">登录注册</a>
          <!-- <i>|</i>
          <a class="reg" rel="nofollow" @click="logReg(5, '页顶_右侧注册文字')">注册</a> -->
          <div v-if="showTaskEntry" class="gift-pack" @click="logReg(1, '页顶_免费领礼包引导')"></div>
        </span>
      </div>
      <!-- 手动创建免费团队 -->
      <CreateTeamModal
        ref="CreateTeamModal"
        :teamInfo="teamInfo"
        :teamId="teamId"
        @getUserInfo="$emit('getUserInfo')"
        @getCanCreateFreeTeam="$emit('getCanCreateFreeTeam')"
        @rights-buy-pop="
          (e) => {
            $emit('rights-buy-pop', e);
          }
        "
      >
      </CreateTeamModal>
    <!--免费试用引导-->
    <FreeUseLeadModal :tariffPackageParasm="tariffPackageParasm" :userInfo="userInfo" :teamInfo="teamInfo" @goVipPackage="goVipPackage"/>
    <!-- 旗舰版续费 -->
    <FlagshipModal :showFlagTips.sync="showFlagTips" pageName="顶部" moduleName="user_bar"/>
    <!-- 会员引导弹窗 -->
    <VipGuideModal :showVipGuideModal="showVipGuideModal" @onVipGuideClose="handleVipGuideClose" :modalData="modalData" :timeText="timeText" @onOpenVip="onOpenVip" :vipModalStyleType="vipModalStyleType" :trackerBaseParams="trackerBaseParams"/>
    </header>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import UserBar from "./comp/userbar.vue";
import Seo from "./comp/headerSeo.vue";
import hoverVip from "./comp/hoverVip.vue";
import tempKey from "./comp/tempKey.vue";
import ExpireTips from "./comp/TrialExpiredTips.vue";
import QuickDesign from "./comp/quick-design/index.vue";
import CreateTeamModal from "./comp/createTeam/createTeamModal.vue";
import FreeUseLeadModal from './comp/freeUseLead/freeUseLeadModal.vue'
import FlagshipModal from "./comp/flagshipModal.vue";
import VipGuideModal from "./comp/vipGuideModal/vipGuideModal.vue";
import ChangeTeamModal from "./comp/changeTeam/changeTeam.vue";
import EnterpriceServiceComponent from "./comp/enterpriceService/index.vue";
import VipLevelModal from "./comp/vipLevelModal/index.vue";
const webLogo = require("./img/header_logo_news_01.svg");
const webLogo02 = require("./img/header_logo_news_02.svg");
const newYearLogo = require("./img/header_doubleDan_year.svg");
const newwebLogo = require("./img/new-web-logo.svg");
const freeVipIcon = require("./img/free-icon.svg");
const personVipIcon = require("./img/person-vip-icon.svg");
const enterpriseVipIcon = require("./img/enterprise-vip-icon.svg");
const perfessionVipIcon = require("./img/perfession-vip-icon.svg");
import pageNameEnum from "./pageNameEnum";
import {onNeedLogin, sendTeamInfo} from "./comp/electron";
// import tracker  from "@/utils/tracker";


const designRoute = {
  20: "sjhaibao",
  1: "wxtu",
  2: "yinshua",
  3: "work",
  11: "social",
  7: "dstb",
  12: "chahua",
  6: "mad",
  4: "life",
  29: "dingzhi",
};
const PERSONAL_HOVER_LIST = [
  {
    title: "20万+精品设计模板",
    subtitle: "海量海报、封面图、视频、GIF模板免费使用",
    img: {
      top: 0,
      left: 0,
    },
  },
  {
    title: "8000万+版权素材",
    subtitle: "高清图片、字体、音乐一站式使用",
    img: {
      top: 0,
      left: -24,
    },
  },
  {
    title: "个人商用授权",
    subtitle: "个人自媒体、个人网店等个人为授权主体的使用",
    img: {
      top: 0,
      left: -48,
    },
  },
  {
    title: "会员高级功能特权",
    subtitle: "畅享智能尺寸变换、超大存储空间、文件夹管理等",
    img: {
      top: 0,
      left: -72,
    },
  },
];
const BUSINESS_HOVER_LIST = [
  {
    title: "企业商用版权",
    subtitle: "20万+正版模板、8000万+高清素材企业正版商用",
    img: {
      top: 0,
      left: -48,
    },
  },
  {
    title: "品牌管理",
    subtitle: "创建团队模板、素材，轻松让团队成员的营销素材符合品牌规范",
    img: {
      top: 0,
      left: -96,
    },
  },
  {
    title: " 团队协作",
    subtitle: "支持设计协作，素材共享，赋予团队每个成员设计能力",
    img: {
      top: 0,
      left: -120,
    },
  },
  {
    title: "企业发票",
    subtitle: "正规企业发票，线上申请，报销无忧",
    img: {
      top: 0,
      left: -144,
    },
  },
];
const CON_LIST = [
  {
    title: "海量素材",
    img: {
      top: 0,
      left: 0,
    },
  },
  {
    title: "商用授权",
    img: {
      top: 0,
      left: -24,
    },
  },
  {
    title: "资产管理",
    img: {
      top: 0,
      left: -48,
    },
  },
  {
    title: "团队协作",
    img: {
      top: 0,
      left: -72,
    },
  },
  {
    title: "数据统计",
    img: {
      top: 0,
      left: -96,
    },
  },
  {
    title: "品牌控制",
    img: {
      top: 0,
      left: -120,
    },
  },
  {
    title: "合规检测",
    img: {
      top: 0,
      left: -144,
    },
  },
  {
    title: "在线审批",
    img: {
      top: 0,
      left: -168,
    },
  },
  {
    title: "屏幕营销",
    img: {
      top: 0,
      left: -192,
    },
  },
  {
    title: "内容分发",
    img: {
      top: 0,
      left: -216,
    },
  },
  {
    title: "任务中心",
    img: {
      top: 0,
      left: -240,
    },
  },
  {
    title: "全员营销",
    img: {
      top: 0,
      left: -264,
    },
  },
];
const moreList = (isIndex) => [
  // {
  //   des: '下载APP',
  //   href: '/clientpage',
  // },
  // {
  //   des: '活动中心',
  //   href: '/activecenter',
  // },
  // {
  //   des: '定制设计',
  //   href: '/print/designservice',
  // },
  {
    des: "创客贴AI",
    href: {
      local: "https://local-aiart.chuangkit.com/matrix",
      moni: "https://moni-aiart.chuangkit.com/matrix",
      prod: "https://aiart.chuangkit.com/matrix",
      beta: "https://beta-aiart.chuangkit.com/matrix",
    }[process.env.VUE_APP_WORK_ENV || "prod"],
    key: "aiart",
    new: true,
  },
  {
    des: "在线印刷",
    key: "printcenter",
    href: "/print/printcenter",
  },
  {
    des: "屏幕营销",
    href: "/screen-marketing",
  },
  {
    des: "视频协作",
    href: "/video-cooperation",
  },
  {
    des: "公益版",
    href: "/enterpriseSaasLandPage",
  },
  {
    des: "渠道合作",
    href: "/activity/channelAgentPage",
  },
  {
    des: "企业微信",
    href: "/promotion",
    hidden: !isIndex,
  },
];
const CLICK_CONTENT_MAP = {
  degisntools: "首页",
  personal_vip: "个人vip",
  enterprice_vip: "企业vip",
  vip: "新价格页按钮（vip按钮）",
};
const headerNav = (isIndex, isLogin, iswxWork) => [
  {
    href: "/designtools/",
    des: "首页",
    key: "degisntools",
  },
  {
    href: "/dam-page/professional",
    des: "资产管理",
    key: "dam",
    hidden: !isLogin,
  },
  // {
  //   href: '/price/personal',
  //   des: '个人VIP',
  //   key: 'personal_vip',
  //   hidden: iswxWork,
  // },
  // {
  //   href: '/price/company',
  //   des: '企业VIP',
  //   key: 'enterprice_vip',
  //   hidden: iswxWork
  // },
  // {
  //   href: '/price/vip?fmodule=topbar_vip&fpage=topbar',
  //   des: 'VIP',
  //   key: 'vip',
  //   hidden: iswxWork
  // },
  {
    href: '/design-service',
    des: '定制设计',
    key: 'designservice',
    hidden: iswxWork,
  },
  {
    des: "创客贴AI",
    href: {
      local: "https://local-aiart.chuangkit.com/matrix",
      moni: "https://moni-aiart.chuangkit.com/matrix",
      prod: "https://aiart.chuangkit.com/matrix",
      beta: "https://beta-aiart.chuangkit.com/matrix",
    }[process.env.VUE_APP_WORK_ENV || "prod"],
    key: "aiart",
    new: true,
    hidden: iswxWork,
  },
  { href: "/print/printcenter", des: "在线印刷", key: "printcenter", hidden: iswxWork },
  {
    href: "/clientpage",
    des: "下载APP",
  },
  { des: "更多", width: 28, hidden: iswxWork },
];

const VIP_VERSION = {
  1: "基础版VIP",
  2: "协作版VIP",
  3: "旗舰版",
  4: "个人版VIP",
  5: "标准版团队VIP",
  6: "企业专业版VIP",
  7: "加强版VIP",
  8: "基础团队版VIP",
  9: "全用途版VIP",
  100: "基础版VIP",
  105: "全用途版VIP",
  110: "标准团队版VIP",
  115: "高级团队版VIP",
  120: "企业专业版",
  125: "新媒体商户版",
  130: "全用途商户版",
  135: "团队协作版",
  201: "个人高级版",
  202: "高级版",
  205: '协作版',
  211: "通用版（个人）",
  212: "通用版（企业）",
  205: '协作版',
  "135_10": "团队协作10人版",
};

export default {
  name: "ChuangkitHeader",
  inject: ["app"],
  provide() {
    return {
      header: this,
    };
  },
  components: {
    UserBar,
    Seo,
    hoverVip,
    tempKey,
    ExpireTips,
    QuickDesign,
    CreateTeamModal,
    EnterpriceServiceComponent,
    VipLevelModal,
    ChangeTeamModal,
    FreeUseLeadModal,
    FlagshipModal,
    VipGuideModal,
  },
  props: {
    userInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    isShowSearchBar: {
      type: Boolean,
      default: false,
    },
    route: {
      type: Object,
    },
    isLogin: {
      type: Boolean,
      default: false,
    },
    isIndex: {
      type: Boolean,
      default: false,
    },
    teamInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    getLogoData: {
      type: Object,
      default() {
        return {};
      },
    },
    teamId: {
      type: Number,
      default: 0,
    },
    normalNotice: {
      type: Object,
      default() {
        return {
          subject: "",
          content: "",
          noticeId: 0,
        };
      },
    },
    scrollTop: {
      type: Number,
      default: 0,
    },
    vipTrailExpireDate: {
      type: Number,
    },
    isWorkWeixinUser: {
      type: Boolean,
      default: false,
    },
    showLoginEntry: {
      type: Boolean,
      default: true,
    },
    // 吸顶消息列表
    noticeList: {
      type: Array,
      default() {
        return [];
      },
    },
    teams: {
      type: Array,
      default: () => [],
    },
    isProVip: {
      type: Boolean,
      default: false,
    },
    isResize: {
      type: Boolean,
      required: false,
    },
    // 企业微信团队下团队成员需要展示企业微信名称，此标识用于引入企业微信sdk
    isSpecialWxTeamTag: {
      type: Boolean,
      default: false,
    },
    giftNumber: {
      type: Number,
      default: 0,
    },
    messageCountInfo: {
      type: Object,
      default: () => {},
    },
    closeLeadState: {
      type: Boolean,
      default: false
    },
    trackerBaseParams: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isFuseNewTeam: false,
      // 为避免 logo 闪动，从 localStorage 中取完自定义 logo（若存在）后再显示 logo
      isShowLogo: false,
      // 广告位信息
      adPositionInfo: {},
      superVipLevelArr: [1, 2, 3, 5, 7],
      selectedBlockList: [],
      designType: [],
      customLogo: "", // 为兼容服务端渲染，不在此处取客户端变量
      PERSONAL_HOVER_LIST, // 个人vip悬浮列表
      BUSINESS_HOVER_LIST, // 企业vip悬浮列表
      CON_LIST, //企业入口列表
      CLICK_CONTENT_MAP,
      downloadPcClient: "",
      isWxworkLogin: false,
      wwAuthUser: "",
      isEnterpriseMember: false, // 是否是新企业版成员
      activityEntryShow: false,
      activityPicUrl: "",
      activityJumpUrl: "",
      showGiftEntry: false,
      showEnterprise: false,
      showTaskEntry: false,
      localCanCreateFreeTeam: false,
      isDamState: false,
      dataLoaded: false,
      enterpriceServiceState: false,
      vipLevelModalState: false,
      loginSuccessCb: () => {
        console.log("登陆成功");
      },
      showTeamBtn: false, //切换按钮
      curScreenWidth: null,
      isSetHeader:false,
      setHeaderList:[],
      isWebPage: true, 
      showActiveIcon: false,
      tariffPackageParasm:{
        tariffPackageId: 0,//套餐ID
        guidExplain: '',//引导文案
      },//试用会员套餐id
      isLenovo: false,
      showFlagTips: false,
      showVipGuideModal: false,
      modalData: {},//弹窗信息和角标信息
      timeText: "",
      interval:null,
      halfPriceTimeOut:false,
      vipModalStyleType: 0,//弹窗样式 0默认 1含优惠券【不含倒计时】
    };
  },
  computed: {
    // navData() {
    //   let result = headerNav(this.isIndex, this.isLogin, this.isWorkWeixinUser).filter(item => !item.hidden)
    //   if (!this.isFuseNewTeam||this.teamInfo.adminUserVipLevel!=3||this.teamInfo.adminUserMemberExpire - Date.now() <= 0){
    //     result = result.filter(item => item.href !== '/dam-page/professional')
    //   }
    //   return result
    // },
    customLogoStyle() {
      if (this.customLogo) {
        return {
          background: "none",
          "background-image": `url(${this.customLogo})`,
          "background-repeat": "no-repeat",
          "background-position": "center",
          "background-size": "contain",
        };
      }
      //新年活动logo
      if (Date.now() < 1645027200000 && Date.now() > 1642780800000) {
        return {
          background: `url(${newYearLogo}) 0px 0px / 100% 100% no-repeat`,
        };
      } else {
        const isBlackLogo = this.$route.path === "/" || this.$route.path === '/price/vip'
        if (this.isIndex && !isBlackLogo && this.scrollTop === 0) {
          return {
            background: `url(${webLogo02}) 0px 0px / cover no-repeat`,
          };
        } else {
          return {
            background: `url(${newwebLogo}) 0px 0px / contain no-repeat`,
          };
        }
      }
    },
    
    getFontColorByRoute(){
      if(this.$route.path === "/" || this.$route.path === "/price/vip"){
        return '#1B2337'
      }else{
        return ''
      }
    },
    
    getBackColorByRoute(){
      if(this.$route.path === "/"|| this.$route.path === "/price/vip"){
        return '#1e6fff'
      }else{
        return ''
      }
    },
    
    titleVipIconStyle() {
      let icon = "";
      
      switch (this.getVersionName) {
        case "企业高级版":
          icon = enterpriseVipIcon;
          break;
        case "个人高级版":
          icon = personVipIcon;
          break;
        case "免费版":
          icon = freeVipIcon;
          break;
        case "企业专业版":
          icon = perfessionVipIcon;
          break;
        default:
          icon = freeVipIcon;
          break;
      }
      
      return { background: `url(${icon}) 0px 0px / contain no-repeat` };
    },
    
    getVersionName() {
      let versionName = "";
      if (this.teamInfo) {
        versionName = VIP_VERSION[this.teamInfo.adminUserVipLevel];
        if (this.teamInfo.adminUserMemberExpire - Date.now() <= 0) {
          versionName = "免费版";
        }
        if (this.canCreateFreeTeam) {
          if (this.teamInfo.teamRoleEnum == "Administrator") {
            versionName = "个人版";
          }
        }
      }
      return versionName;
    },
    
    // showTeamButtonStyle() {
    //   return {
    //     background: this.showTeamBtn
    //       ? `url(${require("./img/up-close-icon.svg")}) 0px 0px / contain no-repeat`
    //       : `url(${require("./img/down-open-icon.svg")}) 0px 0px / contain no-repeat`,
    //     transition: "background 0.3s",
    //   };
    // },
    // 判断是否加入过别人的团队
    isJoinedTeam() {
      const { teams } = this;
      return teams.some((team) => team.teamRoleEnum !== "Administrator");
    },
    enterpriseUrl() {
      const env = process.env.VUE_APP_WORK_ENV || workEnv;
      //const domain = env === 'beta' || env === 'prod' ? '' : `${env}-` // 新企业版无beta环境
      const domain = "";
      let enterprise = "enterprise.chuangkit.com";
      if (!this.isEnterpriseMember) {
        // enterprise = "www.chuangkit.com/sem/index.html";
        enterprise = "www.chuangkit.com/contech";
      }
      return `${location.protocol}//${domain}${enterprise}`;
    },
    canCreateFreeTeam() {
      console.log(this.app.canCreateFreeTeam, "是否可创建团队-----");
      return this.app.canCreateFreeTeam;
    },
    showExtraInfo() {
      let flag = true;
      if (this.curScreenWidth < 1450 && this.isShowSearchBar) {
        flag = false;
      } else if (this.curScreenWidth < 1260) {
        flag = false;
      }
      return flag;
    },
    getTeamName() {
      let name=''
      let { teamName, adminUserName, teamRoleEnum } = this.teamInfo || "";
      try {
        if (teamName && teamName.trim()) {
          name = decodeURIComponent(teamName.trim());
        } else if (adminUserName) {
          name = `${decodeURIComponent(adminUserName.trim())}的团队`;
        }
        //用户未创建团队且未加入其他团队
        if (this.canCreateFreeTeam) {
          if (teamRoleEnum == "Administrator") {
            name = decodeURIComponent(adminUserName.trim());
          }
        }
      } catch (error) {
        if (teamName && teamName.trim()) {
          name = decodeURIComponent(teamName.replace(/%/g, "%25").trim());
        } else if (adminUserName) {
          name = `${decodeURIComponent(adminUserName.replace(/%/g, "%25").trim())}的团队`;
        }
        //用户未创建团队且未加入其他团队
        if (this.canCreateFreeTeam) {
          if (teamRoleEnum == "Administrator") {
            name = decodeURIComponent(adminUserName.replace(/%/g, "%25").trim());
          }
        }
      }
      
      return name;
    },
    isSvip() {
      return this.teamInfo.adminUserVipLevel === 220 && this.teamInfo.adminUserMemberExpire > Date.now();
    },

    showVipFree(){
      return this.showActiveIcon && this.teamInfo.teamRoleEnum ==='Administrator' && this.teamInfo.adminUserVipLevel === 0 && !location.pathname.includes('/design-service')
    }
  },
  watch: {
    showTaskEntry(v) {
      if (v) {
        this.gioFn("new_gift_right_guide_show");
      }
    },
    teamId(v) {
      this.getConfigureHead();//获取可配置化头部
      this.getIsFuseNewTeam(v);
      if (v != 0) {
        this.getLogoDataInfo(v);
      }
    },
    teamInfo: {
      handler(v) {
        if (v.teamId>0 && v.teamRoleEnum ==='Administrator'){
          this.getAllVipPackages()
          //获取引导弹窗展示信息
          this.getGuideModalInfo();
        }
        sendTeamInfo(v)
      },
      immediate: true
    },
    getLogoData(v) {
      this.getLogoDataInfo(this.teamId);
    },
    isLogin(v) {
      if (v) {
        import("./client").then((res) => {
          this.clientCheck = res.clientCheck;
          this.joinWxSDK();
        });
        // 判断当前用户是否存在于企业
        this.judgeExistEnterprise();
      } else {
        // 清除企业微信扫码登录记录
        for (let i = 0; i < localStorage.length; i++) {
          let key = localStorage.key(i);
          if (key.includes("isWxworkLogin_")) {
            localStorage.removeItem(key);
            return;
          }
        }
      }
      // this.getTaskAdvert()
    },
    isSpecialWxTeamTag(v) {
      if (v) {
        import("./client").then((res) => {
          this.joinWxSDK();
        });
      }
    },
    isShowSearchBar(v) {
      this.navData(v);
    },
    // giftNumber: {
    //   handler() {
    //     // 是否在活动期间
    //     this.getTaskAdvert();
    //   },
    //   immediate: true
    // },
    isSvip() {
      this.loadImg();
    },
  },
  beforeMount() {
    this.customLogo = localStorage.getItem("custom_logo") || "";
    this.isShowLogo = true;
    this.getDesignType();
    this.judgeExistEnterprise();
    const date = this.getNowFormatDate();
    const closeTime = localStorage.getItem("closeTime") || 0;
    if (date !== closeTime) {
      this.getActiveBanner();
    }
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  mounted() {
    this.curScreenWidth = window.innerWidth;
    import("./client").then((res) => {
      this.clientCheck = res.clientCheck;
      this.joinWxSDK();
    });
    this.getAdPosition(); // 获取广告位信息
    this.getDownloadPcClient();
    // 兼容ssr项目 -- 需dom挂载完毕后获取
    this.showEnterprise = (document && document.location.pathname) != "/";
    this.loadData();
    window.addEventListener("resize", () => {
      this.curScreenWidth = window.innerWidth;
      if (!this.isShowSearchBar) return;
      this.navData(this.isShowSearchBar);
    });
    this.isWebPage = !window.__WUFA_APP__;
    this.isLenovo = !!window.jsbridge;
    onNeedLogin((needLogin) => {
      if (needLogin) {
        this.logReg(1);
      }
    })
  },
  methods: {
    ...mapActions({
      setHeaderActivityShow: "setHeaderActivityShow",
    }),
    onOpenVip(){
      console.log('onOpenVip')
      const params = {
        packageLevel: this.modalData.level,
        packageId: this.modalData.packageId,
        source: {
          pageName: 'fullpop',
          moduleName: this.modalData.sourceIdent
        },
      };
      this.$emit('rights-buy-pop', params)
    },
    showModal(data){
      this.showVipGuideModal = true
      const {name,style,id} = this.modalData
      try {
        window.gio("track", "vip_guide_show", {
          popup_name: name,
          popup_type: style,
          active_id: id,
          forward_module_name: '全屏弹框',
          forward_page_name: '会员引导弹框',
          forward_page_url: window.location.href,
          is_login: '是',
          ...this.trackerBaseParams,
        });
      } catch (error) {
        console.log('埋点异常')
      }
    },
    changeClockNum(duration){
      let hours = parseInt((duration / (1000 * 60 * 60)) % 24);
      let minutes = parseInt((duration / (1000 * 60)) % 60);
      let seconds = parseInt((duration / 1000) % 60);

      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      this.timeText = hours + ":" + minutes + ":" + seconds;
    },
    startCountdown(duration){
      const interval = setInterval(() => {
        if ((duration -= 1000) < 0) {
          clearInterval(interval);
          //倒计时结束 关闭弹窗同时隐藏角标
          this.showVipGuideModal = false
          this.halfPriceTimeOut = true
        }else{
          this.changeClockNum(duration)
        }
      }, 1000);
      this.interval = interval
    },
    checkAndShowModal(data){
      const today = new Date(data.systemTime).toDateString();
      const localModalData = JSON.parse(localStorage.getItem(`localModalData_${data.id}_${this.userInfo.userId}`)) || { lastShown: '', count: 0 };
      if (localModalData.lastShown !== today && localModalData.count < data.frequency) {
        this.showModal(data);
        if(data.style === 0){//带优惠卷的弹窗不需要倒计时
          //设置初始值
          this.changeClockNum(data.deadline)
          //设置倒计时
          this.startCountdown(data.deadline)
        }
        localModalData.lastShown = today;
        localModalData.count += 1;
        localStorage.setItem(`localModalData_${data.id}_${this.userInfo.userId}`, JSON.stringify(localModalData));
      }
    },
    async getGuideModalInfo(){
      const {body:{code,data}} = await this.$http.post("/vip/userPopup/getInfo", { platform:1, gw: true});
      if(code===200){
        const { switchStatus } = data
        this.modalData = data
        if( switchStatus === 1 || switchStatus === 3){//可以展示特价/临期弹框
          this.vipModalStyleType = data.style;
          //判断今日是否展示过和次数是否已达上线
          this.checkAndShowModal(data)
        }
      }else{
        console.log('接口异常')
      }
    },
    handleVipGuideClose(){
      this.showVipGuideModal = false
    },
    showEnterpriceService(number){
      if (number===1){
        window.gio("track", 'popup_show', {
          popup_name: "企业服务下拉框",
          is_login: this.isLogin? '是':'否'
        });
        this.enterpriceServiceState =true
      } else {
        this.enterpriceServiceState =false
      }
    },
    async getAllVipPackages(){
      const {body:{data}}= await this.$http.post("/vip/member/getPackageABTestInfo", { gw:true});
      this.tariffPackageParasm = data
      localStorage.setItem("tariffPackageParams",JSON.stringify(data))
      if (data.tariffPackageId>0){
        this.showActiveIcon =true
        const params ={
          forward_module_name: '全屏弹框',
          forward_page_name: 'fullpop',
          popup_name: "会员试用引导弹框",
          is_login: '是',
        }
        window.gio("track", "popup_show", params);
        const { default: tracker } = await import('@/utils/tracker');
        window.tracker = tracker
        if (window.tracker){
          window.tracker.loginReport({
            event: "popup_show",
            datetime: Date.now(),
            params,
          })
        }
      } else {
        this.showActiveIcon =false
      }
    },
    goVipPackage(t){
      this.tariffPackageParasm.tariffPackageId = t
      const params = {
        level: this.teamInfo.adminUserVipLevel,
        tariffPackageParasm:this.tariffPackageParasm,
        source: {
          pageName: 'fullpop',
          moduleName: 'vip_trail_pop',
        },
      };
      this.$emit('rights-buy-pop', params)
    },
    showVipModal(number){
      if (number===1){
        window.gio("track", 'popup_show', {
          popup_name: "会员下拉框",
          is_login: this.isLogin? '是':'否'
        });
        this.vipLevelModalState =true
      } else {
        this.vipLevelModalState =false
      }
    },
    // 是否新团队
    async getIsFuseNewTeam(id) {
      try {
        const {
          body: { code, data },
        } = await this.$http.post("/team/fuse/team/isFuseNewTeam", { gw: true, teamId: id });
        if (code === 200) {
          this.isFuseNewTeam = data;
          this.navData(this.isShowSearchBar);
          this.$emit("fuseNewTeamStatus", data);
        } else {
          this.$emit("fuseNewTeamStatus", false);
        }
      } catch (error) {
        this.isFuseNewTeam = true;
        this.$emit("fuseNewTeamStatus", this.isFuseNewTeam);
        console.log(error);
      }
    },
    loadData() {
      this.dataLoaded = true;
      this.navData(this.isShowSearchBar);
    },
    navData(v){
      let result =''
      if (this.isSetHeader){
        result= this.setHeaderList
      } else {
        result = headerNav(this.isIndex, this.isLogin, this.isWorkWeixinUser).filter(item => !item.hidden)
      }
      if (!this.isFuseNewTeam||![3, 220].includes(+this.teamInfo.adminUserVipLevel)||this.teamInfo.adminUserMemberExpire - Date.now() <= 0){
        result = result.filter(item => item.href !== '/dam-page/professional')
        this.$emit('setDamIdShow',false)
        if (!v ) {
          return result
        }
        this.isDamState = false;
        if (document.body.clientWidth < 1420 && document.body.clientWidth >= 1332) {
          result = result.filter((item) => item.key !== "apides");
        }
        if ((document.body.clientWidth < 1331 && document.body.clientWidth >= 1243) || document.body.clientWidth < 1242) {
          result = result.filter((item) => item.key !== "apides" && item.key !== "printcenter");
        }
      } else {
        this.$emit('setDamIdShow',true)
        if (!v) {
          return result;
        }
        this.isDamState = true;
        if (document.body.clientWidth < 1490 && document.body.clientWidth >= 1402) {
          result = result.filter((item) => item.key !== "apides");
        }
        if (document.body.clientWidth < 1401 && document.body.clientWidth >= 1313) {
          result = result.filter((item) => item.key !== "apides" && item.key !== "printcenter");
        }
        if ((document.body.clientWidth < 1312 && document.body.clientWidth >= 1260) || document.body.clientWidth < 1259) {
          result = result.filter((item) => item.key !== "apides" && item.key !== "printcenter" && item.key !== "aiart");
        }
      }
      this.moreDropList(this.isDamState);
      return result;
    },
    getConfigureHead(){
      this.$http.post("/team/fuse/function/service/record/getFunctionServiceList", { gw: true, team_id: this.teamId }).then((res) => {
        if (res.body.code == 200 &&res.body.data?.length>0) {
          let newList = res.body.data
          let TopBarList = newList.filter(x => x.functionName=== 'topbar')
          if (TopBarList.length === 0){
            return  this.isSetHeader = false
          }
          newList.map(v=>{
            if (v.functionName === 'topbar'){
              if (v.extendConfig?.entryConfigs?.length>0 && v.status === 1){
                let topBarList = v.extendConfig.entryConfigs
                topBarList.map((v,index)=>{
                  v.href =v.url
                  v.des =v.entryName?.substring(0,6) ||''
                  v.key =`selfTab${index+2}`
                  v.uniqueName ='topBar'
                })
                this.setHeaderList = topBarList?.splice(0,6) || []
                this.isSetHeader =true
                this.navData(this.isShowSearchBar)
              } else {
                this.isSetHeader = v.status === 1 ? true:false
                this.setHeaderList =[]
              }
            }
          })
        } else {
          this.isSetHeader = false
        }
      });
    },
    moreDropList(isBlooen = false) {
      let result = moreList(this.isIndex).filter((item) => !item.hidden && item.key !== "apides" && item.key !== "printcenter" && item.key !== "aiart");
      if (!this.isShowSearchBar) return result;
      if (isBlooen) {
        if (document.body.clientWidth < 1490 && document.body.clientWidth >= 1402) {
          result = moreList(this.isIndex).filter((item) => !item.hidden && item.key !== "printcenter" && item.key !== "aiart");
        }
        if (document.body.clientWidth < 1401 && document.body.clientWidth >= 1313) {
          result = moreList(this.isIndex).filter((item) => !item.hidden && item.key !== "aiart");
        }
        if ((document.body.clientWidth < 1312 && document.body.clientWidth >= 1260) || document.body.clientWidth < 1259) {
          result = moreList(this.isIndex).filter((item) => !item.hidden);
        }
        return result;
      }
      if (document.body.clientWidth < 1420 && document.body.clientWidth >= 1332) {
        result = moreList(this.isIndex).filter((item) => !item.hidden && item.key !== "printcenter" && item.key !== "aiart");
      }
      if ((document.body.clientWidth < 1331 && document.body.clientWidth >= 1243) || document.body.clientWidth < 1242) {
        result = moreList(this.isIndex).filter((item) => !item.hidden && item.key !== "aiart");
      }
      
      return result;
    },
    //获取logo
    async getLogoDataInfo(id) {
      try {
        const {
          body: { code, data, msg },
        } = await this.$http.post("/team/fuse/custom/logo/get", { gw: true, teamId: id });
        this.customLogo = "";
        if (code == 200) {
          if (data.logo.value.length === 1) {
            if (data.logo.value[0].logoType == 0 && !this.route.path.includes("/price/vip")) {
              this.customLogo = data.logo.value[0].imgUrl;
            }
            if (data.logo.value[0].logoType == 1 && this.route.path.includes("/price/vip")) {
              this.customLogo = data.logo.value[0].imgUrl;
            }
          }
          if (data.logo.value.length > 1) {
            if (data.logo.value[0].logoType == 0 && !this.route.path.includes("/price/vip")) {
              this.customLogo = data.logo.value[0].imgUrl;
            }
            if (data.logo.value[1].logoType == 1 && this.route.path.includes("/price/vip")) {
              this.customLogo = data.logo.value[1].imgUrl;
            }
          }
        }
      } catch (error) {
        this.customLogo = "";
      }
    },
    gioFn(eventName) {
      window.gio("track", eventName, {
        page_name: pageNameEnum[this.$route.path] || "",
        is_login: Boolean(this.isLogin),
      });
    },
    setClearCreateDesignTime() {
      // this.$refs.clearCreateDesign.hideQuick(true);
    },
    //展开收起弹窗
    showChange(state) {
      this.showTeamBtn = !this.showTeamBtn;
      if (state === 1) {
        this.$refs.changeTeamModal.showMoadl();
      } else {
        this.$refs.changeTeamModal.hideMoadl();
      }
    },
    getUserInfoFun() {
      this.showTeamBtn = false;
      this.$emit("getUserInfo");
    },
    // 是否在活动期间 有任务未完成
    getTaskAdvert() {
      try {
        this.$http
          .get("/hatchery/activity/task/advert/get", {
            params: {
              _dataClientType: 0,
              platform: 0,
              gw: true,
              alias: "task_for_reward",
            },
            data: { gw: true, requestMethod: "GET", template: true, nottrans: true },
          })
          .then((res) => {
            let code, data;
            // 如果是chuangkit-saas 项目
            if (this.$route.path.includes("/designtools") || this.$route.path === "/") {
              code = res.body && res.body.code;
              data = res.body && res.body.data;
            } else {
              code = res.data && res.data.body && res.data.body.code;
              data = res.data && res.data.body && res.data.body.data;
            }
            if (code === 200) {
              // status 0 未完成 1 待领取 2 已完成
              // adStatus 0 进行中 1 结束
              if (data && !data.adStatus) {
                this.showTaskEntry = true;
              } else {
                this.showTaskEntry = false;
              }
            } else {
              this.showTaskEntry = false;
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
    //创建团队
    createModal() {
      this.$refs.CreateTeamModal.createTeam();
    },
    // 获取活动banner顶图
    async getActiveBanner() {
      try {
        const {
          body: { code, data },
        } = await this.$http.get("/hatchery/adposition", {
          data: { gw: true, requestMethod: "GET", template: true, nottrans: true },
          params: { platform: 0, alias: "web_top_banner", gw: true },
        });
        if (code === 200) {
          this.activityEntryShow = typeof data !== "undefined" && data.length;
          this.setHeaderActivityShow(typeof data !== "undefined" && data.length)
          this.activityPicUrl = data && data[0].picUrl;
          this.activityJumpUrl = data && data[0].jumpUrl;
        }
      } catch (e) {
        console.error("error \n" + e);
      }
    },
    loadImg() {
      this.$emit("the-entrance-status", this.activityEntryShow && !this.isSvip);
      this.setHeaderActivityShow(this.activityEntryShow && !this.isSvip)
    },
    async getPopupStatus() {
      const {
        body: { code, data },
      } = await this.$http.get("/vip/userPopup/getPopupStatus", {
        data: { gw: true, requestMethod: "GET", template: true, nottrans: true },
        params: { visitSiteId: 1, gw: true },
        nottrans: true,
      });
      if (code === 200) {
        this.showGiftEntry = !!data.popupStatus;
      }
    },
    getNowFormatDate() {
      var date = new Date();
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate;
      return currentdate;
    },
    toActivityPage() {
      window.open(this.activityJumpUrl);
      window.gio("track", "ad_content_click", {
        ad_name: "顶部横幅广告",
      });
    },
    closeActivityEntry(event) {
      event.stopPropagation();
      event.cancelBubble = true;
      this.activityEntryShow = false;
      this.setHeaderActivityShow(false)
      const closeTime = this.getNowFormatDate();
      localStorage.setItem("closeTime", closeTime);
      this.$nextTick(() => this.$emit("the-entrance-status", false));
      window.gio("track", "button_click", {
        button_name: "顶部横幅广告关闭按钮",
      });
    },
    enterPrise() {
      try {
        window.gio("track", "navigation_bar_click", {
          click_content: "进入企业版按钮",
          url: window.location.href,
        });
        if (this.enterpriseUrl.includes("/contech")) {
          window.open(this.enterpriseUrl);
        } else {
          window.open(this.enterpriseUrl);
        }
      } catch (e) {
        throw e;
      }
    },
    // 设置li标签的active
    navLiCls(value) {
      // 首页选中 除designtools以外 模板中心原本路由也选中
      if (value.des === "首页") {
        if (this.route.path.includes("/designtools/enterprise/")) {
          return false;
        }
        return {
          active:
            (this.route.path.includes(value.href) && value.href !== "/") ||
            this.route.path.includes("/templatecenter") ||
            this.route.path.includes("/sj-") ||
            this.route.path.includes("/tupian") ||
            this.route.path.includes("/soso/"),
        };
      }
      if (value.uniqueName ==='topBar') {
        return {
          selfItem:true
        }
      }
      if (value.des === "VIP") {
        return {
          active: this.route.path.includes("/vip"),
        };
      }
      return {
        active: this.route.path.includes(value.href) && value.href !== "/",
        templatecenter: value.href === "/templatecenter",
      };
    },
    handleDesignTool(v) {
      if (!this.isLogin || v !== "/designtools/") return v;
      
      const href = localStorage.getItem(`lastLeavePageName_${this.userInfo.userId}`);
      return href ? `${v}${href}` : v;
    },
    // 切换到登录页面
    logReg(v, forwordMoudle) {
      if (!this.isLogin) {
        sessionStorage.setItem(
          "forward",
          JSON.stringify({
            forward_module_name: forwordMoudle,
          })
        );
      }
      if (!forwordMoudle || forwordMoudle === "页顶_免费领礼包引导") {
        this.gioFn("new_gift_right_guide_click");
      }
      this.$emit("giftShow", 2);
    },
    // 获取广告位信息
    getAdPosition() {
      const data = {
        position: 212,
        gw: true,
      };
      this.$http.post("/team/tools/getWebSiteAdByPosition", data).then((res) => {
        // -101没有找到指定的位置标识, -105该位置的广告位没有创建, 200 成功
        if (res.body.code === 200) {
          this.adPositionInfo = res.body.data;
        }
      });
    },
    getDownloadPcClient() {
      const platform = navigator.platform;
      let format = "exe";
      let type = "";
      if (platform === "Win32" || platform === "Windows") {
        type = "windows";
      } else if (platform === "Mac68K" || platform === "MacPPC" || platform === "Macintosh" || platform === "MacIntel") {
        type = "mac";
      }
      if (type === "mac") {
        format = "dmg";
      }
      this.downloadPcClient = `https://eyuankupub-cdn-oss.chuangkit.com/sys/chuangkit_${type}.${format}?v=${Date.now()}`;
    },
    // 获取模板精选版块  模板分发
    // getTemplateOfSelectedBlock() {
    // const data = {
    //   apidomainv2: true,
    //   client_type: 0
    // }
    // this.$http.post('/templateSelectedBlock/getTemplateSelectedBlock', data).then((res) => {
    //   // 操作的错误码(-101没有数据;-102参数错误；200成功返回)
    //   if (res.body.code === 200) {
    //     const obj = {
    //       firstKinds: this.designType,
    //       tagName: '场景',
    //       isScene: true
    //     }
    //     const arr = res.body.data
    //     arr.splice(2, 0, obj)
    //     this.selectedBlockList = arr
    //   }
    // })
    // },
    getDesignType() {
      this.$http.post("/contentcopyright/designkind/getDesignTypeV2", { gw: true }).then((res) => {
        const arr = res.body.data.result;
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].firstKindId === "5") {
            arr.splice(i, 1);
          }
        }
        // 对控制主nav的数组进行初始化
        this.designType = this.setDesignTypeRoute(arr);
        // this.getTemplateOfSelectedBlock()
      });
    },
    // 导航栏埋点
    buriedPoint(item) {
      if (item.key === "vip") {
        sessionStorage.setItem("openVipBurialParam", JSON.stringify({ forward_module_name: "页顶_VIP入口" }));
      }
      try {
        if (item.des !=='更多') {
          window.gio("track", "button_click", {
            page_name:'',
            moudle_name:'顶栏',
            button_name:item.des,
            page_url: item.href,
            is_login: this.isLogin? '是':'否',
          });
        }
      } catch (error) {
        throw error;
      }
      if (item.entryName){
        if (item.redirectType === 0){
          window.open(item.url)
        } else {
          location.href = item.url
        }
        return
      }
      if (item.key==='dam'){
        if (this.closeLeadState){
          this.saveNewGuide()
        }
      }
      const url = this.jumpCheck(this.handleDesignTool(item.href));
      if (url === undefined) return;
      if (url.includes("/design-service")) {
        return this.goCloudDesign(url);
      }
      if (item.key==='dam'||item.key==='degisntools'||item.key==='printcenter'){
        location.href = url
      } else {
        window.open(url)
      }
    },
    //更多埋点
    burePoint(item){
      try {
        window.gio("track", "button_click", {
          page_name:'',
          moudle_name:'顶栏',
          button_name:item.des,
          page_url: item.href,
          is_login: this.isLogin? '是':'否',
        });
      } catch (error) {
        throw error;
      }
    },
    //保存新手引导
    async saveNewGuide(){
      try {
        const {
          body: { data },
        } = await this.$http.get('/team/migration/saveNewVersionGuide', {
          params: { teamId: this.userInfo.curTid, userId: this.userInfo.userId, gw: true },
          data: {
            gw: true,
            requestMethod: 'GET',
            template: true,
            nottrans: true,
          },
        });
      } catch (error) {
        this.$message(error)
      }
    },
    async goCloudDesign(url) {
      localStorage.setItem("header-click", "true");
      location.href = url;
    },
    // 根据id设置一级分类的新路由 （此路由是SEO要求处理的）
    setDesignTypeRoute(arr) {
      const data = [];
      arr.forEach((e) => {
        data.push({
          ...e,
          route: designRoute[e.firstKindId],
          firstKindName: e.pKindName,
        });
      });
      return data;
    },
    
    handleOpenVipClick() {
      window.open(`/price/vip?fmodule=topbar_vip&fpage=topbar`);
      try {
        window.gio("track", "button_click", {
          page_name:'',
          moudle_name:'顶栏',
          button_name:'vip会员',
          is_login: this.isLogin? '是':'否',
        });
      } catch (error) {
        console.log("error");
      }
    },
    
    buyNow(locationFlag, level) {
      if (locationFlag === 35) {
        window.gio("track", "navigation_bar_personal_vip_hover_buy_click");
      } else if (locationFlag === 36) {
        window.gio("track", "navigation_bar_enterprise_vip_hover_buy_click");
      }
      
      if (!this.isLogin) {
        this.logReg(1);
        return;
      }
      // 企业微信团队不支持购买
      if (this.isWorkWeixinUser) {
        this.$message("企业微信团队暂不支持升级，详情请联系客服，400-871-8211");
        return;
      }
      // 企业专业版不支持续费
      if (this.isProVip) {
        //弹窗
        this.showFlagTips = true
        return;
      }
      // 非团队创建者不可购买
      if (this.teamInfo.teamRoleEnum !== "Administrator") {
        this.$message("请联系团队创建者进行购买");
        return;
      }
      
      if (this.route.path.includes("/price/company") || this.route.path.includes("/price/personal")) {
        const params = {
          level,
          locationFlag,
          source: {
            pageName: "topbar",
            moduleName: "txinfo_vip",
          },
        };
        this.$emit("rights-buy-pop", params);
      } else {
        let title = "";
        if (locationFlag === 35) {
          title = this.userInfo.vipLevel > 0 ? "续费" : "开通";
        } else {
          title = this.superVipLevelArr.includes(this.userInfo.vipLevel) ? "续费" : "开通";
        }
        
        sessionStorage.setItem("openVipBurialParam", JSON.stringify({ forward_module_name: `头像下拉框_立即${title}按钮` }));
        const href = level === 4 ? "/price/personal" : "/price/company";
        window.open(`${href}?level=${level}&locationFlag=${locationFlag}&fmodule=txinfo_vip&fpage=topbar`);
      }
    },
    goBuyVip(params){
      if (!this.isLogin) {
        this.$emit("giftShow", 2);
        return;
      }
      this.$emit("rights-buy-pop", params);
    },
    // 当前页面跳转检查
    jumpCheck(link) {
      const url = this.route.path;
      if (link === url) return;
      return link;
    },
    // 引入企业微信sdk
    joinWxSDK() {
      if (this.wwAuthUser) return;
      // 判断当前是否客户端内打开/企业微信扫码登录（需同步企业微信的名称至用户昵称）
      // 判断当前是否带有特殊情况标识（企业微信团队下团队成员需要展示企业微信昵称，仅saas项目-2021-3-5追加）
      this.isWxworkLogin =
        document.referrer.includes("https://open.work.weixin.qq.com/") ||
        !!localStorage.getItem(`isWxworkLogin_${this.userInfo.userId}`) ||
        this.isSpecialWxTeamTag;
      if (this.clientCheck("wxWork") || this.isWxworkLogin) {
        const sp1 = new Promise((resolve, reject) => {
          const script1 = document.createElement("script");
          script1.type = "text/javascript";
          script1.src = "https://res.wx.qq.com/open/js/jweixin-1.2.0.js";
          document.getElementsByTagName("head")[0].appendChild(script1);
          script1.onload = () => resolve();
          script1.onerror = reject;
        });
        const sp2 = new Promise((resolve, reject) => {
          const script2 = document.createElement("script");
          script2.type = "text/javascript";
          script2.src = "https://open.work.weixin.qq.com/wwopen/js/jwxwork-1.0.0.js";
          document.getElementsByTagName("head")[0].appendChild(script2);
          script2.onload = () => resolve();
          script2.onerror = reject;
        });
        
        Promise.all([sp1, sp2]).then(() => {
          this.getWorkWxAgentConfig();
        });
      }
    },
    // 获取企业微信参数
    getWorkWxAgentConfig() {
      if (!this.clientCheck("wxWork") && !this.isWxworkLogin) return;
      
      try {
        const url = location.href.split("#")[0];
        this.$http.post("/workwx/getWorkWxAgentConfig", { url }).then((res) => {
          // -1未登录、-2url为空、-3获取配置为空、200成功
          if (res.body.code === 200) {
            const data = res.body.agentConfig;
            wx.agentConfig({
              corpid: data.corpid, // 企业微信的corpid，必须与当前登录的企业一致
              agentid: data.agentid, // 企业微信的应用id （e.g. 1000247）
              timestamp: data.timestamp, // 生成签名的时间戳
              nonceStr: data.nonceStr, // 生成签名的随机串
              signature: data.signature, // 签名，见附录-JS-SDK使用权限签名算法
              jsApiList: ["selectExternalContact"],
              debug: true,
              success: (res) => {
                this.setwwAuthUser(data.authUser);
                this.$emit("set-connect-wx-success");
              },
            });
          }
        });
        // 保留当前登录状态
        localStorage.setItem(`isWxworkLogin_${this.userInfo.userId}`, true);
      } catch (error) {
        console.log(error);
      }
    },
    setwwAuthUser(val) {
      this.wwAuthUser = val;
      this.$emit("getwwAuthUser", val);
    },
    // 判断当前用户是否存在于企业
    async judgeExistEnterprise() {
      if (!this.isLogin) return;
      // 因企业版暂无beta环境，请求接口会报错导致部分页面显示有问题，beta环境暂不请求该接口
      const env = process.env.VUE_APP_WORK_ENV || workEnv;
      if (env === "beta") return;
      try {
        const {
          body: { code, data },
        } = await this.$http.post("/enterprise/enterprise/member/judgeExistEnterprise", { gw: true });
        if (code == 200) {
          this.isEnterpriseMember = data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 登陆成功后回调
    handleLoginSucceed() {
      try {
        this.loginSuccessCb();
        this.loginSuccessCb = () => {
          console.log("没有要执行的函数");
        };
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "header.less";
</style>
